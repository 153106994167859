import axios from "axios";
import { userRequestHeaders } from "../user/index";


/**
 * Save messages to session storage.
 * @param {*} messages
 */
export function messagecenterSetMessages(messages) {
	sessionStorage.setItem('messages', JSON.stringify(messages));
}

/**
 * Get saved messages from session storage.
 * @param jobId
 * Function returns null, if no messages are saved to session storage.
 */
export async function messagecenterGetMessages(jobId = null) {

	let messages = JSON.parse(sessionStorage.getItem('messages'));
	
	if (!messages) {
        try {
            const allMessages = await messagecenterFetchMessages();
            return allMessages;
        } catch (error) {
            return error;
        }
    }
	if (jobId) {
		let jobMessages = messages.filter((messages) => messages.jobid === jobId);
		return jobMessages;
	} else {
		return messages;
	}
}

/**
 * Remove all messages from sessionStorage.
 */
export function messagecenterRemoveMessages() {
	sessionStorage.removeItem('messages');
}

/**
 * Get single message from session storage based on the message id.
 * If message is not found, return null.
 * 
 * @param {*} id 
 * @returns Message object
 */
export function messagecenterGetSingleMessage(id) {
	let message = null;
	const messages = JSON.parse(sessionStorage.getItem('messages'));

	if (messages !== null) {
		message = messages.filter(message => message.id === id)[0];
	}
	
	return message;
}

/**
 * Fetch all users message center messages and save them to session storage.
 * If user has no messages, empty array is returned in the data.
 * 
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export function messagecenterFetchMessages() {
	// remove old messages
	messagecenterRemoveMessages();

	// fetch headers
	const headers = userRequestHeaders();

	return axios({
		method: "GET",
		url: process.env.REACT_APP_API_URL + '/online/messagecenter/searchticket',
		headers: headers
	}).then(function (res) {
		if (typeof res.data === 'undefined') {
			return Promise.reject();
		} else {
			// Save messages to sessionStorage
			messagecenterSetMessages(res.data.tickets);
			// Return Promise on success
			return Promise.resolve(res.data.tickets);
		}
	}).catch(function (error) {
		return Promise.reject(error);
	});
}

/**
 * Count unread messages
 * and return unread message count.
 */
export function getUnreadMessageCount(messages = null) {
    let unread = 0;
    
	if (messages.length > 0) {
        messages.forEach((message) => {
            if (message.current_status.unread > 0) {
				unread++;
			}
         });
    }

	return unread;
};



/**
 * Update message center message status to read and save the updated messages to sessionStorage.
 * @param {*} updateUnreadMessagesNotification 
 * @param {*} messageId 
 * @returns {Promise<any>}
 */
export function messagecenterSetMessageUnread(updateUnreadMessagesNotification, messageId = null) {
	// fetch headers
	const headers = userRequestHeaders();
	
	return axios({
		method: "PUT",
		url: `${process.env.REACT_APP_API_URL}/online/messagecenter/read?issue_id_or_key=${messageId}&read=1`,
		headers: headers,
	}).then(function (res) {
		if (res.status === 204) {

			// Retrieve the current messages from sessionStorage
			let messages = sessionStorage.getItem('messages');

			//  Parse the retrieved data
			messages = messages ? JSON.parse(messages) : [];

			// Find the message with the specified messageId and update its unread status
			const updatedMessages = messages.map(message => {
				if (message.id === messageId) {
					return {
						...message,
						current_status: {
							...message.current_status,
							unread: 0
						}
					};
				} 
				
				return message;
			});

			// Save the updated messages to sessionStorage
			messagecenterSetMessages(updatedMessages);

			// Update the unread messages count
            const unreadCount = getUnreadMessageCount(updatedMessages);
            updateUnreadMessagesNotification(unreadCount);

			return Promise.resolve(null);
		} else if (typeof res.data === 'undefined') {
			return Promise.reject();
		} else {
			return Promise.resolve(res.data);
		}
	}).catch(function (error) {
		return Promise.reject(error);
	});
}

/**
 * Check if maintenance is ongoing.
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export function checkMessageCenterMaintenance() {
    return axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/online/messagecenter/maintenance',
        headers: userRequestHeaders()
    }).then(function (res) {
        if (typeof res.data === 'undefined') {
            return Promise.reject();
        } else {
            return Promise.resolve(res.data.maintenance_ongoing);
        }
    }).catch(function (error) {
        return Promise.reject(error);
    })
}

export function getAttachmentData(attachmentId, messageId) {
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/online/messagecenter/attachment?attachment_id=${attachmentId}&issue_id_or_key=${messageId}`,
        headers: userRequestHeaders()
    }).then(function (res) {
        if (typeof res.data === 'undefined') {
            return Promise.reject();
        } else {
            return Promise.resolve(res.data);
        }
    }).catch(function (error) {
        return Promise.reject(error);
    })
}

/**
 * Create a new comment in the message center.
 * @param {string} issueIdOrKey - The issue ID or key.
 * @param {Object} formData - The form data containing the comment and attachments.
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export function createComment(issueIdOrKey, formData) {

	const headers = userRequestHeaders();

    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/online/messagecenter/createcomment?issue_id_or_key=${issueIdOrKey}`,
        headers: headers,
		data: JSON.stringify(formData)
    }).then(function (res) {
        if (typeof res.data === 'undefined') {
            return Promise.reject();
        } else {
            return Promise.resolve(res.data);
        }
    }).catch(function (error) {
        return Promise.reject(error);
    });
}