import axios from 'axios';
import isEmpty from 'lodash.isempty';

import {jwtDecode} from "jwt-decode";
const roleSolver = require('../helpers/role').roleSolver;

/**
 * Available customertypes
 * @type {{PERSON: string, COMPANY: string}}
 */
export const CUSTOMERTYPE = {
    PERSON: 'person',
    COMPANY: 'company'
};

/**
 * Initialize user data
 * @param token
 */
export function userInitialize(token) {
	let decoded = null;

	//JWT decode wrapped to try catch to get predicted behavior if jwtdecode throws an error.
	try {
		decoded = jwtDecode(token);
	}
	catch(err) {
		// nothing to do here anymore so lets stop wasting a time and get out of here.
		return;
	}

	// if decoded not null set things up.
	if (decoded) {
		userSetToken(token);

		const name = typeof decoded.name != 'undefined' ? decoded.name : '';

		userSetRoles(roleSolver(decoded.roles));
		userSetName(name);

		const company = typeof decoded.company != 'undefined' ? decoded.company : '';
		const businessId = typeof decoded.businessid != 'undefined' ? decoded.businessid : '';
		const customertype = typeof decoded.customertype != 'undefined' ? decoded.customertype : '';

		// When customertype is company, company name and businessId is also available
		if (customertype === CUSTOMERTYPE.COMPANY) {
			userSetCustomerType(CUSTOMERTYPE.COMPANY);
			userSetCompany(company);
			userSetBusinessId(businessId);
		} else {
			userSetCustomerType(CUSTOMERTYPE.PERSON);
		}
	}
}

/**
 * Get locale
 */
export function userGetLocale() {
	let locale = localStorage.getItem('locale');

	if (locale === null) {
		locale = 'fi';
	}

	return locale;
}

/**
 * Retrieves the user's country
 */
export function userGetCountry() {
	let location = localStorage.getItem('country');

	if (location === null) {
		// fallback country
		location = 'fi';
	}

	return location;
}

/**
 * Saves role to sesion storage
 * @param {string} roles
 */
export function userSetRoles(roles) {
	sessionStorage.setItem('roles', JSON.stringify(roles));
}

/**
 * Retrieves name from session storage
 * @returns {string} role
 */
export function userGetRoles() {
	return JSON.parse(sessionStorage.getItem('roles'));
}

/**
 * Function to check user is authorized by role.
 * @param {string} role
 * @returns {boolean}
 */
export function userIsAuthorized(role) {
    let isAuthorized = false;

    // Get the roles
    const roles = sessionStorage.getItem('roles');

    // Check if user is authorized
    if (!isEmpty(roles)) {
        isAuthorized = (roles.indexOf(role) > -1);
    }

    return isAuthorized;
}

/**
 * Saves businessId to session storage
 * @param businessId
 */
export function userSetBusinessId(businessId) {
	sessionStorage.setItem('businessId', businessId);
}

/**
 * Retrieves businessId from session storage
 * @returns {string}
 */
export function userGetBusinessId() {
	return sessionStorage.getItem('businessId');
}

/**
 * Saves company name to session storage
 * @param company
 */
export function userSetCompany(company) {
	sessionStorage.setItem('company', company);
}

/**
 * Retrieves company name from session storage
 * @returns {string}
 */
export function userGetCompany() {
	return sessionStorage.getItem('company');
}

/**
 * Saves customerType to session storage
 * @param customerType
 */
export function userSetCustomerType(customerType) {
	sessionStorage.setItem('customerType', customerType);
}

/**
 * Retrieves customerType from session storage
 * @returns {string}
 */
export function userGetCustomerType() {
	return sessionStorage.getItem('customerType');
}

/**
 * Saves name to session storage
 * @param name
 */
export function userSetName(name) {
	sessionStorage.setItem('name', name);
}

/**
 * Retrieves name from session storage
 * @returns {string}
 */
export function userGetName() {
	return sessionStorage.getItem('name');
}

/**
 * Removes name from session storage
 */
export function userRemoveName() {
	sessionStorage.removeItem('name');
}

/**
 * Saves given session to sessionStorage
 * @param token
 */
export function userSetToken(token) {
	sessionStorage.setItem('token', token);
}

/**
 * Returns users token from sessionStorage
 * @returns {string}
 */
export function userGetToken() {
	return sessionStorage.getItem('token');
}

/**
 * Removes users token from sessionStorage
 */
export function userRemoveToken() {
	sessionStorage.removeItem('token');
}

/**
 * Clear sessionStorage
 */
export function userClearStorage() {
	sessionStorage.clear();
}

/**
 * Login function.
 * Function takes UUID code wich are going to save in token
 * @param uuid
 * @returns {*}
 */
export function userLogin(uuid) {
	const headers = userRequestHeaders();

	const endpoint = process.env.REACT_APP_API_URL;

	return axios({
		method: 'GET',
		url: endpoint + '/onlinetoken/' + uuid,
		headers: headers
	}).then(function(res) {
		if(res.data.token) {
			userInitialize(res.data.token);
			return Promise.resolve(res);
		} else {
			return Promise.reject();
		}
	}).catch(function(error) {
		return Promise.reject(error);
	});
}

/**
 * Remove whole storage when login out
 */
export function userLogout() {
	userClearStorage();
}

/**
 * Is user logged in?
 * @returns {boolean}
 */
export function userLoggedin() {
	return sessionStorage.getItem('token') !== null && sessionStorage.getItem('token') !== '';
}

/**
 * Helper function of login, saves header info, if user is logged in
 * return info from cache
 * @param contentType
 * @returns Object
 */
export function userRequestHeaders(contentType) {
	if (typeof contentType === 'undefined') {
		contentType = "application/json";
	}

	const headers = {
		'Accept': 'application/json',
		'Content-Type': contentType
	};

	if (userLoggedin()) {
        headers["Authorization"] = "Bearer " + userGetToken();
	}

	return headers;
}