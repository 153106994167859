import React, { useEffect, useState } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { defineMessages } from "react-intl";
import PropTypes from 'prop-types';
import { getCookieConsentValue } from "react-cookie-consent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@fortawesome/pro-regular-svg-icons";

// Services
import { addDynatrace, updateCookies } from "../../services/helpers/index";

// Sentences to be translated
export const messages = defineMessages({
    supportChatUsesCookies: {
        id: "Chat käyttää evästeitä",
        defaultMessage: "Chat käyttää evästeitä"
    },
    acceptCookiesToUseTheChat: {
        id: "Hyväksy evästeet käyttääksesi Chattia",
        defaultMessage: "Hyväksy evästeet käyttääksesi Chattia"
    }
});


const CookieConsent = (props) => {
    const lng = props.intl.formatMessage;
    const [cookieConsentChecked, setCookieConsentChecked] = useState(getCookieConsentValue());
    const [showPopover, setShowPopover] = useState(false);
    const [privacySettingsActive, setPrivacySettingsActive] = useState(false);
    const [buttonClasses, setButtonClasses] = useState(props.buttonStyling);

    let dynatrace = false;

    if (typeof process.env.REACT_APP_DYNATRACE !== 'undefined' && process.env.REACT_APP_DYNATRACE.toString() === '1') {
        dynatrace = true;
    }

    useEffect(() => {

        // if the environment allows the use of Dynatrace and cookies have already been accepted, add Dynatrace script
        if (dynatrace && cookieConsentChecked) {
            addDynatrace();
        }

    }, []);


    useEffect(() => {
        const styles = props.buttonStyling;
        setButtonClasses(styles);

        if (props.openedFrom === 'footer' && privacySettingsActive) {
            setButtonClasses(styles + ' active');
        }
    });


    /**
     * Hide / show the cookie consent popover, and handle style changes.
     * Also relay the information to the parent component for additional 
     * actions (e.g. open chat).
     * 
     * @param {*} e Popover visible or not
     */
    function handlePopoverToggle(e) {
        if (props.openedFrom !== 'footer' && getCookieConsentValue()) {
            // because cookies have already been accepted, open chat instead of the popover 
            setShowPopover(false);
            props.cookiesUpdated();
        } else {
            // style footer button differently if popover is open
            if (props.openedFrom === 'footer' && e) {
                setPrivacySettingsActive(true);
            } else if (props.openedFrom === 'footer' && !e) {
                setPrivacySettingsActive(false);
            }

            // hide / show popover
            setShowPopover(e);
        }
    }

    /**
     * Handle changes in the cookie consent.
     */
    const handleCookieConsentChange = () => {
        // update cookies
        if (document.querySelector('#popOver').checked === true) {
            updateCookies(true, dynatrace);
        } else {
            updateCookies(false, dynatrace);
        }
        setCookieConsentChecked(!cookieConsentChecked);
        // call parent function (e.g. to open chat or to remove disabled states from buttons), and toggle popover visibility
        props.cookiesUpdated(!cookieConsentChecked);
        handlePopoverToggle();
    };

    /**
     * Cookie consent popover content
     */
    const cookieConsentPopover = (
        <Popover id="cookies" className={props.popoverStyling}>
            <Popover.Header as="h3">{lng(messages.supportChatUsesCookies)}</Popover.Header>
            <Popover.Body>
                <label>
                    <input
                        id='popOver'
                        className='me-2'
                        type="checkbox"
                        checked={getCookieConsentValue() ? true : false}
                        onChange={handleCookieConsentChange}
                        title={lng(messages.acceptCookiesToUseTheChat)}
                        value={cookieConsentChecked}
                    />
                    {lng(messages.acceptCookiesToUseTheChat)}
                </label>
            </Popover.Body>
        </Popover>
    );


    return (
        <OverlayTrigger
            key={'cookieconsent'}
            trigger="click"
            placement="top"
            rootClose={true}
            show={showPopover}
            onToggle={handlePopoverToggle}
            overlay={cookieConsentPopover}
        >
            <button id={ props.buttonId } title={ props.buttonTitle } className={ buttonClasses }>
                { props.openedFrom === 'footer' ? <FontAwesomeIcon icon={ far.faGear } /> : <FontAwesomeIcon icon={ far.faComment } /> } { props.buttonText }
                { props.openedFrom === 'invoice' && (<span className='float-end'> <FontAwesomeIcon icon={ far.faChevronRight } /></span>) }
            </button>
        </OverlayTrigger>
    );
};

CookieConsent.propTypes = {
    'intl': PropTypes.object.isRequired,
    'openedFrom': PropTypes.string.isRequired,
    'buttonId': PropTypes.string,
    'buttonTitle': PropTypes.string,
    'buttonStyling': PropTypes.string,
    'buttonText': PropTypes.string,
    'popoverStyling': PropTypes.string,
    'cookiesUpdated': PropTypes.func
};

export default CookieConsent;
