import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { formatDate } from "../../services/helpers";
import { defineMessages } from "react-intl";

/**
 * Statuses, where due date of the job should not be shown.
 */
export const doNotShowDueDate = [62, 63, 65, 112, 151, 152, 153, 154, 155, 156];

export const messages = defineMessages({
    due: {
        id: "Erääntynyt",
        defaultMessage: "Erääntynyt"
    }
});

const PaydateText = (props) => {
    
    const lng = props.intl.formatMessage;
    const [paydateText, setPaydateText] = useState('-');
    
    useEffect(() => {
        
        if (props.statuscode !== null && doNotShowDueDate.includes(props.statuscode)) {
            // Show only "due" text
            setPaydateText(lng(messages.due));
        } else if (props.paydate !== null) {
            // Format the due date
            setPaydateText(formatDate(props.paydate));
        }
        
    }, [props.statuscode, props.paydate, lng]);
    
    return (
        <span>{paydateText}</span>
    );
};

PaydateText.propTypes = {
    'intl': PropTypes.object.isRequired,
    'statuscode': PropTypes.any.isRequired,
    'paydate': PropTypes.any.isRequired
};

export default PaydateText;