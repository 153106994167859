import { useEffect } from 'react';
import { injectIntl } from "react-intl";
import PropTypes from 'prop-types';

// Services
import { userLogout } from "../../../services/user/index";

const LogOut = (props) => {
    /**
     * Logout user and redirect to frontpage
     */
    useEffect(() => {
        let alert = "";
        if (props.location?.alert) {
            alert = props.location.alert;
        }
        userLogout();
        props.history.replace({
            pathname: '/',
            alert
        });
    
    }, []);

    return '';
};

LogOut.propTypes = {
    intl: PropTypes.object.isRequired
};

export default injectIntl(LogOut);