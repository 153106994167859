import React, { useEffect, useState } from 'react';
import { injectIntl, defineMessages } from "react-intl";
import { Row, Col, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Services
import { userGetName, userGetCountry } from '../../../services/user';
import { toggleLoading, parseLinkVariable } from '../../../services/helpers';

// strings for translation in this view
const messages = defineMessages({
    loading: {
        id: "Haetaan...",
        defaultMessage: "Haetaan..."
    },
    name: {
        id: "Nimi",
        defaultMessage: "Nimi"
    },
    invoicer: {
        id: "Laskuttaja",
        defaultMessage: "Laskuttaja"
    },
    subject: {
        id: "Aihe",
        defaultMessage: "Aihe"
    },
    additionalInformation: {
        id: "Lisätiedot",
        defaultMessage: "Lisätiedot"
    },
    serviceProvider: {
        id: "Palvelun tarjoaa Ropo. Weblaskujen lähettäjänä näkyy ropocapital yhteen kirjoitettuna.",
        defaultMessage: "Palvelun tarjoaa Ropo. Weblaskujen lähettäjänä näkyy ropocapital yhteen kirjoitettuna."
    },
    cancellationMessage: {
        id: "Jos haluat tehdä muutoksia tilaukseesi, ota yhteyttä asiakaspalveluumme.",
        defaultMessage: "Jos haluat tehdä muutoksia tilaukseesi, ota yhteyttä asiakaspalveluumme."
    },
    cancellationMessageInfo: {
        id: "Jos haluat tehdä muutoksia tilaukseesi",
        defaultMessage: "Jos haluat tehdä muutoksia tilaukseesi"
    },
    cancellationMessageContact: {
        id: "ota yhteyttä asiakaspalveluumme.",
        defaultMessage: "ota yhteyttä asiakaspalveluumme."
    },
    invoice: {
        id: "Lasku",
        defaultMessage: "Lasku"
    }
});

export { messages };


const CreditorInfoCard = (props) => {
    // translations function
    const lng = props.intl.formatMessage;

    const [creditor, setCreditor] = useState("");
    const [topic, setTopic] = useState("");
    const [name, setName] = useState("");

    const country = userGetCountry();
    const customerServiceLink = getCustomerServiceLinkByLocale(country);

    // the customer service link is formed by the user's country (fi by default, unless another country is specified)
    function getCustomerServiceLinkByLocale(country) {
        return parseLinkVariable(country, process.env.REACT_APP_LINK_CUSTOMER_SERVICE);
    }


    useEffect(() => {
        // loading animation off
        toggleLoading(false);

        if (props.showCreditorInfo) {
            // set details about the job
            setCreditor(props.job.payee.name);
            setTopic(lng(messages.invoice)); // use 'invoice' as subject
            setName(userGetName());
        }

    }, [props.showCreditorInfo]);

    // creditor information of the web invoice subscription
    return (
        <>
            {props.showCreditorInfo ? (
                <div id="creditor-information">
                    <Row>
                        <Col>
                            <Card className='card' id="creditor-info-card" >
                                <Card.Body>
                                    <div className='creditor-info-padding d-none' />
                                    <ul className="list-unstyled">
                                        <li id="creditor-name" >
                                            <span className="label">
                                                {lng(messages.invoicer)}
                                            </span>
                                            <span id="creditor">{creditor}</span>
                                        </li>
                                        <li id="subscription-topic">
                                            <span className="label">
                                                {lng(messages.subject)}
                                            </span>
                                            <span id="topic">{topic}</span>
                                        </li>
                                        <li id="user-name">
                                            <span className="label">
                                                {lng(messages.name)}
                                            </span>
                                            <span id="name">{name}</span>
                                        </li>
                                        <li id="additional-info">
                                            <span className="label">
                                                {lng(messages.additionalInformation)}
                                            </span>
                                            {/* if a web invoice subcscription already exists, show cancellation details as additional information  */}
                                            <span id="additional-info">{props.subscriptionStatus ?
                                                <span>{lng(messages.cancellationMessageInfo)}, <a title={customerServiceLink} rel="noopener noreferrer" href={customerServiceLink} target="_blank">{lng(messages.cancellationMessageContact)}</a></span>
                                                : lng(messages.serviceProvider)
                                            }</span>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            ) : (
                <Row>
                    <Col>
                        <Card className='card-borderless'>
                            <Card.Body>
                                <span>{lng(messages.loading)}</span>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    );
};

CreditorInfoCard.propTypes = {
    intl: PropTypes.object.isRequired,
    showCreditorInfo: PropTypes.bool,
    job: PropTypes.object,
    subscriptionStatus: PropTypes.bool
};


export default injectIntl(CreditorInfoCard);
