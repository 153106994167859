import React from "react";
import { Col, Row } from "react-bootstrap";
import { messages } from "../../../../index";
import PropTypes from "prop-types";
import get from "lodash.get";

// Services
import { jobGetJob } from "../../../../../../services/job";
import { formatMoney } from "../../../../../../services/helpers";
import { userGetLocale } from "../../../../../../services/user";

const ReturnPaymentStep2 = (props) => {
    
    const job = jobGetJob();
    const lng = props.intl.formatMessage;

    let currency = get(job, 'currency', false);
    currency = (currency && currency !== '') ? currency : process.env.REACT_APP_DEFAULT_CURRENCY;
    
    const userLocale = userGetLocale();
    
    return (
        <>
            <Row>
                <p className='text-dark'>{ lng(messages.paymentReturnDetailsDescription) }</p>
            </Row>
            <Row>
                <h4 className='mt-4 mb-4'>{ lng(messages.paymentReturnDetailsTitle) }</h4>
            </Row>
            <Row>
                <ul className='list-unstyled vertically-centered-items'>
                    <li>
                        <Row>
                            <Col xs={6}><p className='fw-bold'>{ lng(messages.returnAmount) }</p></Col>
                            <Col xs={6}><p className='float-end text-end text-dark'>{ formatMoney(job.modoverpaidamount, userLocale, currency) }</p></Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={6}><p className='fw-bold'>{ lng(messages.refundProcessingFee) }</p></Col>
                            <Col xs={6}><p className='float-end text-end text-dark'>{ formatMoney(job.modoverpaidfee, userLocale, currency) }</p></Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={6}><p className='fw-bold'>{ lng(messages.reference) }</p></Col>
                            <Col xs={6}><p className='float-end text-end text-dark'>{ job.reference }</p></Col>
                        </Row>
                    </li>
                    <li>
                        <Row>
                            <Col xs={6}><p className='fw-bold'>{ lng(messages.caseNumber) }</p></Col>
                            <Col xs={6}><p className='float-end text-end text-dark'>{ job.jobid }</p></Col>
                        </Row>
                    </li>
                </ul>
            </Row>
        </>
    );
};

ReturnPaymentStep2.propTypes = {
    'intl': PropTypes.object.isRequired,
};

export default ReturnPaymentStep2;