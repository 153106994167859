import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Button } from 'react-bootstrap';
import moment from 'moment';

import { messages as jobmessages, debtorStatusMessages, debtorStatusDescs } from '../../messages';
import StatusBadge from "../../../../components/StatusBadge";
import isEmpty from "lodash.isempty";
import { JOB_ENERGYTYPE } from "../../../../services/job";

const StatusInfo = (props) => {

    const lng = props.intl.formatMessage;
    const [cardType, setCardType] = useState('');
    const dateNow = moment(new Date()).format('YYYY-MM-DD');
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const maxCharacterCount = 235;
    let showAllInMobile = false;

    let debtorStatusDesc = null;

    // sets statusdescriptions, if any are related to the job
    if (typeof debtorStatusDescs['statusdesc' + props.job.debtor_statuscode] !== "undefined" && debtorStatusDescs['statusdesc' + props.job.debtor_statuscode] !== "") {
        
        // Check if job status is cutoff warning and energytype is other than electricity or by default empty
        const nonElectricityCutOffWarning = (props.job.statuscode === 156 && !isEmpty(props.job.energytype) && props.job.energytype !== JOB_ENERGYTYPE.ELECTRICITY);

        // As long as job is other than non electricity cutoff warning, display the status description
        if (!nonElectricityCutOffWarning) {
            debtorStatusDesc = lng(debtorStatusDescs['statusdesc' + props.job.debtor_statuscode]);
            
            if (debtorStatusDesc.length >= maxCharacterCount) {
                showAllInMobile = true;
            }
        }
    }

    useEffect(() => {

        if (props.job.job_main_statuscode !== undefined && props.job.job_main_statuscode !== null) {
            switch (props.job.job_main_statuscode) {
                case 0:
                    // If duedate is due show status 'late'
                    if (props.job.paydate !== undefined && props.job.paydate < dateNow) {
                        setCardType('late');
                    } else {
                        setCardType('invoicing');
                    }
                    break;
                case 1:
                    setCardType('reminder');
                    break;
                case 2:
                    setCardType('collection');
                    break;
                case 101:
                    setCardType('paid');
                    break;
            }
        }

    }, [props.job]);

    // status information and messages
    return (
        <Card className={'invoice-details no-rounding-bottom ' + cardType}>
            <Card.Body>
                <Row>
                    <Col md={9} xs={9}>
                        <h5 className='mb-1'>{ lng(jobmessages.statusInformation) }</h5>
                        <p className='sub-heading'>
                            {typeof debtorStatusMessages['statuscode' + props.job.debtor_statuscode] !== "undefined" && debtorStatusMessages['statuscode' + props.job.debtor_statuscode] !== "" ? lng(debtorStatusMessages['statuscode' + props.job.debtor_statuscode]) : ''}
                        </p>
                    </Col>
                    <Col md={3} xs={3}>
                        <span className='float-end text-end'>
                            <StatusBadge statusCode={props.job.job_main_statuscode} dueDate={props.job.paydate} lng={lng} />
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col id='job-statusdesc-warning' className={'statusdesc-col read-more ' + (!showAllInMobile || showMoreInfo ? 'open' : 'closed')}>
                        {debtorStatusDesc !== null && (
                            <>
                                <p className='fs-6'>{debtorStatusDesc}</p>
                                {showAllInMobile && (
                                    <Row >
                                        <Col className='d-grid read-more bottom-click-area'>
                                            <Button className='fs-6 text-decoration-none' variant='tertiary' onClick={() => setShowMoreInfo(!showMoreInfo)}>
                                                {showMoreInfo ? lng(jobmessages.hideDetails) : lng(jobmessages.showDetails)}
                                            </Button>
                                        </Col>
                                        <div className={showMoreInfo ? "read-more bottom-gradient off" : "read-more bottom-gradient on"} />
                                    </Row>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

StatusInfo.propTypes = {
    'intl': PropTypes.object.isRequired,
    'job': PropTypes.object,
};

export default StatusInfo;