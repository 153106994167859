import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { defineMessages } from "react-intl";
import { formatDate, formatMoney } from "../../../../services/helpers";
import { userRequestHeaders, userGetLocale } from '../../../../services/user/index'
import { jobGetCode, JOB_ENERGYTYPE } from '../../../../services/job/index';
import axios from "axios";
import PropTypes from 'prop-types';
import AlertDialog from "../../../../components/AlertDialog";

// This view's strings for translation
const messages = defineMessages({
    actionSave: {
        id: "Tallenna",
        defaultMessage: "Tallenna"
    },
    failedToLoadUsagePlaces: {
        id: "Jotain meni vikaan, ole hyvä ja yritä uudelleen.",
        defaultMessage: "Jotain meni vikaan, ole hyvä ja yritä uudelleen."
    },
    signServing: {
        id: "Allekirjoita tiedoksianto",
        defaultMessage: "Allekirjoita tiedoksianto",
    },
    servingHeader: {
        id: "Ilmoitus kuluttajalle sähkö- tai verkkomaksun maksamatta jättämisestä",
        defaultMessage: "Ilmoitus kuluttajalle sähkö- tai verkkomaksun maksamatta jättämisestä"
    },
    servingParagraph1: {
        id: "Koska et ole maksanut toimitettua sähköä ajoissa, sähkön siirto voi keskeytyä. Ruotsin sähkölain säännösten mukaan voit estää keskeytyksen maksamalla velan kolmen viikon kuluessa tämän ilmoituksen vastaanottamisesta. Kuntasi sosiaalilautakunta saa ilmoituksen maksun myöhästymisestä.",
        defaultMessage: "Koska et ole maksanut toimitettua sähköä ajoissa, sähkön siirto voi keskeytyä. Ruotsin sähkölain säännösten mukaan voit estää keskeytyksen maksamalla velan kolmen viikon kuluessa tämän ilmoituksen vastaanottamisesta. Kuntasi sosiaalilautakunta saa ilmoituksen maksun myöhästymisestä."
    },
    servingHeaderHeating: {
        id: "Ilmoitus kuluttajalle kaukolämmön maksamatta jättämisestä",
        defaultMessage: "Ilmoitus kuluttajalle kaukolämmön maksamatta jättämisestä"
    },
    servingParagraphHeating1: {
        id: "Koska et ole maksanut kaukolämmön maksua ajallaan, kaukolämmön jakelu voidaan keskeyttää. Ruotsin kaukolämpölain säännösten mukaan voit estää keskeyttämisen maksamalla velan kolmen viikon kuluessa tämän ilmoituksen vastaanottamisesta. Sen kunnan sosiaalihuolto, jossa kaukolämpö jaetaan, on saanut ilmoituksen maksamatta jääneestä maksusta.",
        defaultMessage: "Koska et ole maksanut kaukolämmön maksua ajallaan, kaukolämmön jakelu voidaan keskeyttää. Ruotsin kaukolämpölain säännösten mukaan voit estää keskeyttämisen maksamalla velan kolmen viikon kuluessa tämän ilmoituksen vastaanottamisesta. Sen kunnan sosiaalihuolto, jossa kaukolämpö jaetaan, on saanut ilmoituksen maksamatta jääneestä maksusta."
    },
    payee: {
        id: "Velkoja",
        defaultMessage: "Velkoja"
    },
    payer: {
        id: "Velallinen",
        defaultMessage: "Velallinen"
    },
    usageplaceID: {
        id: "Käyttöpaikan tunnus",
        defaultMessage: "Käyttöpaikan tunnus"
    },
    usageplaceAddress: {
        id: "Käyttöpaikan osoite",
        defaultMessage: "Käyttöpaikan osoite"
    },
    debtSpecification: {
        id: "Velan erittely",
        defaultMessage: "Velan erittely"
    },
    invoiceNumber: {
        id: "Laskun numero",
        defaultMessage: "Laskun numero"
    },
    dueDate: {
        id: "Eräpäivä",
        defaultMessage: "Eräpäivä"
    },
    capital: {
        id: "Pääoma",
        defaultMessage: "Pääoma"
    },
    interest: {
        id: "Viivästyskorko",
        defaultMessage: "Viivästyskorko"
    },
    costs: {
        id: "Kustannukset",
        defaultMessage: "Kustannukset"
    },
    total: {
        id: "Kaikki yhteensä",
        defaultMessage: "Kaikki yhteensä"
    },
    confirmSignature: {
        id: "Tällä vahvistuksella ja allekirjoituksella vakuutan, että olen lukenut Ropo Sweden AB:n minulle antamat tiedot.",
        defaultMessage: "Tällä vahvistuksella ja allekirjoituksella vakuutan, että olen lukenut Ropo Sweden AB:n minulle antamat tiedot."
    },
    backToInvoice: {
        id: "Palaa laskun tietoihin",
        defaultMessage: "Palaa laskun tietoihin"
    },
    unknown: {
        id: "Ei tiedossa",
        defaultMessage: "Ei tiedossa"
    },
    authError: {
        id: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen."
    }
});

export { messages };


const ServingModal = (props) => {
    let lng = props.intl.formatMessage;
    const jobPayeeName = props.job.payee.name;
    const jobPayerSsn = props.job.payer.ssn;
    const jobPayerName = props.job.payer.name;
    const jobPayerAddress = props.job.payer.address[0];
    const energytype = props.job.energytype;
    const [payeeAddressInformation, setPayeeAddressInformation] = useState(lng(messages.unknown));
    const [show, setShow] = useState(false);
    const [usageplaceId, setUsageplaceId] = useState('');
    const [usageplaceAddressInformation, setUsageplaceAddressInformation] = useState(lng(messages.unknown));
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState('');
    const [alertDialogType, setAlertDialogType] = useState('');
    const [isChecked, setIsChecked] = useState(false);


    /**
     * Toggle modal visibility with props.show.
     * Job must also be eligible for serving, for modal to be shown.
     */
    useEffect(() => {
        if (props.show && props.isServingEligible) {
            setShow(true);
        } else {
            setShow(false);
        }

    }, [props.show]);

    /**
     * Trigger, when there are changes in props.isServingEligible.
     * Get usageplace information only if serving is eligible and is not yet signed
     */
    useEffect(() => {
        if (props.isServingEligible && !props.servingSigned && !props.disableServingButton) {
            getUsagePlace();
            // format payee (creditor) address
            const formattedPayeeAddress = formatAddresses(props.job.payee.address[0]);
            setPayeeAddressInformation(formattedPayeeAddress);
        }

    }, [props.isServingEligible]);



    /**
     * Format the given address object into a string where values are separated with a comma (,),
     * except for the last values (postcode and city).
     * @param addressArray 
     */
    function formatAddresses(addressArray) {
        let givenaddress = [];

        givenaddress.push(addressArray.address);
        givenaddress.push(addressArray.addressaddline1);
        givenaddress.push(addressArray.addressaddline2);
        givenaddress.push(addressArray.addressaddline3);
        givenaddress.push(addressArray.postcode + " " + addressArray.city);

        // Remove nulls, undefined and empty address lines from full address
        const formattedAddress = givenaddress.map(item => item).filter((item) => item !== null && item !== undefined && item.length > 0).join(", ");

        return formattedAddress;
    }

    /**
     * Get usageplace information
     */
    function getUsagePlace() {
        const headers = userRequestHeaders();
        const dpid = jobGetCode();

        axios({
            method: 'GET',
            url: process.env.REACT_APP_API_URL + '/online/usageplace/' + dpid,
            headers: headers,
        })
        .then(function (res) {
            if (res.status === 200) {
                // set usageplace id
                setUsageplaceId(res.data.data[0].usageplace_id);
                // format and set usageplace address information
                const address = formatAddresses(res.data.data[0].address[0]);
                setUsageplaceAddressInformation(address);
            }
        })
        .catch(function (error) {
            if (error.response?.status === 404 && error.response?.data.title === "Usage place not found") {
                // leave usageplace information empty if not found
                setUsageplaceId("");
                setUsageplaceAddressInformation("");
            } else if (error.response?.status === 401) {
                const alert = {message: lng(messages.authError), type: 'danger'};
                props.history.push({
                    pathname: "/logout",
                    alert
                });
            } else {
                // request fails completely, show message in alertdialog
                handleAlertDialog(lng(messages.failedToLoadUsagePlaces), 'warning');
            }
        });
    }

    /**
     * Handle error messages to show in the alertDialog
     * @param message 
     * @param type 
     */
    function handleAlertDialog(message, type) {
        setAlertDialogMessage(message);
        setAlertDialogType(type);

        setShowAlertDialog(true);
    }

    /**
     * Check for checkbox changes and toggle style classes accordingly
     * @param e
     */
    function handleCheckbox(e) {
        if (e.target.form.checkValidity()) {
            e.target.form.classList.remove("error");
        } else {
            e.target.form.classList.add("error");
        }

        setIsChecked(!isChecked);
    }

    /**
     * Handle saving of the signature for the serving.
     * Do not allow saving if checkbox is not checked.
     *
     * @param e
     */
    function signServing(e) {
        if (!isChecked) {
            // add error class to inform user
            e.target.form.classList.add('error');
        } else {
            // checkbox is checked, remove error class
            e.target.form.classList.remove('error');
            props.signServing(e);
        }
    }

    /**
     * Close the modal and set the checkbox unchecked
     */
    function handleHide() {
        setIsChecked(false);

        if (props.show) {
            props.hide();
        }
    }


    return (
        <>
            {show && (
                <Modal show={show} onHide={handleHide}>
                    <Modal.Header closeButton>
                        <Button className="modal-back" onClick={handleHide}>
                            <FontAwesomeIcon icon={far.faArrowLeft} /> {lng(messages.backToInvoice)}
                        </Button>
                        <Modal.Title>{lng(messages.signServing)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="text-primary"> <FontAwesomeIcon icon={far.faInfoCircle} /> &nbsp;
                            {energytype === JOB_ENERGYTYPE.HEATING ?
                                lng(messages.servingHeaderHeating).toUpperCase()
                                : lng(messages.servingHeader).toUpperCase()
                            }
                        </p>
                        <p>
                            {energytype === JOB_ENERGYTYPE.HEATING ?
                                lng(messages.servingParagraphHeating1)
                                : lng(messages.servingParagraph1)
                            }
                        </p>
                        {showAlertDialog && (
                            <AlertDialog
                                message={alertDialogMessage}
                                type={alertDialogType}
                            />
                        )}
                        <ul id='payee-info-list' className='serving-modal-unstyled'>
                            <li key='payee-info' id='payee-info'>{lng(messages.payee)}: <br />  {jobPayeeName}, {payeeAddressInformation}</li>
                        </ul>
                        <ul id='payer-info-list' className='serving-modal-unstyled'>
                            <li key='payer-ssn' id='payer-ssn'> {lng(messages.payer)}: {jobPayerSsn} </li>
                            <li key='payer-name' id='payer-name'> {jobPayerName} </li>
                            <li key='payer-address' id='payer-address'> {jobPayerAddress.address} {jobPayerAddress.addressaddline1} {jobPayerAddress.addressaddline2} {jobPayerAddress.addressaddline3} </li>
                            <li key='payer-postcode-city' id='payer-postcode-city'> {jobPayerAddress.postcode} {jobPayerAddress.city} </li>
                        </ul>
                        <ul id='usageplace-info-list' className='serving-modal-unstyled'>
                            <li key='usageplace-id' id='usageplace-id'>{lng(messages.usageplaceID)}: {usageplaceId} </li>
                            <li key='usageplace-address' id='usageplace-address'> {lng(messages.usageplaceAddress)}: {usageplaceAddressInformation}
                            </li>
                        </ul>
                        <h6 className='mt-4 mb-1'>{lng(messages.debtSpecification)} </h6>
                        <ul id='debt-details-list' className='serving-modal-unstyled'>
                            <li key='debt-details-jobid' id='debt-details-jobid'>{lng(messages.invoiceNumber)}: {props.job.jobid} </li>
                            <li key='debt-details-paydate' id='debt-details-paydate'>{lng(messages.dueDate)}: {formatDate(props.job.paydate)} </li>
                            <li key='debt-details-opencapital' id='debt-details-opencapital'>{lng(messages.capital)}: {formatMoney(props.job.opencapital, userGetLocale(), props.job.currency)} </li>
                            <li key='debt-details-openinterest' id='debt-details-openinterest'>{lng(messages.interest)}: {formatMoney(props.job.openinterest, userGetLocale(), props.job.currency)} </li>
                            <li key='debt-details-openexpenses' id='debt-details-openexpenses'>{lng(messages.costs)}: {formatMoney(props.job.openexpenses, userGetLocale(), props.job.currency)} </li>
                            <li key='debt-details-openamount' id='debt-details-openamount'>{lng(messages.total)}: {formatMoney(props.job.openamount, userGetLocale(), props.job.currency)} </li>

                            {/* also list debt details of sub jobs if they exist */}
                            {props.job.members !== null ? (
                                props.job.members.map((subjob, i) => (
                                    <ul key={'subjob-' + i} id={'subjob-debt-details-list-' + i} className='serving-modal-unstyled'>
                                        <li key={'subjob-' + i + '-jobid'} id={'subjob-debt-details-jobid-' + i}>{lng(messages.invoiceNumber)}: {subjob.jobid} </li>
                                        <li key={'subjob-' + i + '-paydate'} id={'subjob-debt-details-paydate-' + i}>{lng(messages.dueDate)}: {formatDate(subjob.paydate)} </li>
                                        <li key={'subjob-' + i + '-opencapital'} id={'subjob-debt-details-opencapital-' + i}>{lng(messages.capital)}: {formatMoney(subjob.opencapital, userGetLocale(), subjob.currency)} </li>
                                        <li key={'subjob-' + i + '-openinterest'} id={'subjob-debt-details-openinterest-' + i}>{lng(messages.interest)}: {formatMoney(subjob.openinterest, userGetLocale(), subjob.currency)} </li>
                                        <li key={'subjob-' + i + '-openexpenses'} id={'subjob-debt-details-openexpenses-' + i}>{lng(messages.costs)}: {formatMoney(subjob.openexpenses, userGetLocale(), subjob.currency)} </li>
                                        <li key={'subjob-' + i + '-openamount'} id={'subjob-debt-details-openamount-' + i}>{lng(messages.total)}: {formatMoney(subjob.openamount, userGetLocale(), subjob.currency)} </li>
                                    </ul>
                                ))
                            ) : ('')}
                        </ul>
                        <Form id='serving-form' className='pt-4 pt-sm-2' noValidate>
                            <Form.Group controlId='sign-serving'>
                                <Form.Check
                                    id="check-serving"
                                    type="checkbox"
                                    label={lng(messages.confirmSignature)}
                                    onChange={(e) => handleCheckbox(e)}
                                    value={isChecked}
                                    required
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button form='serving-form' variant="primary" onClick={(e) => signServing(e)}>
                            {lng(messages.actionSave)}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

ServingModal.propTypes = {
    'intl': PropTypes.object.isRequired,
    'job': PropTypes.object,
    'show': PropTypes.bool,
    'isServingEligible': PropTypes.bool,
    'servingSigned': PropTypes.bool,
    'disableServingButton': PropTypes.bool,
    'signServing': PropTypes.func,
    'hide': PropTypes.func,
    'history': PropTypes.object
};

export default ServingModal;