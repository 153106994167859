import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

import { messages as jobmessages } from '../../messages';
import { userGetCountry } from '../../../../services/user';

const BankInformation = (props) => {

    const lng = props.intl.formatMessage;
    const country = userGetCountry();

    /**
     * Show account details based on the account type.
     * 
     * @param {*} accountType 
     * @param {*} account 
     * @returns 
     */
    const showAccountDetails = (accountType, account) => {
        switch (accountType) {
            case 'bankgirot':
                return <p><span className='label'>{lng(jobmessages.bankgirot)}</span> {account.bankgirot}</p>;
            case 'bban':
                return <p><span className='label'>{lng(jobmessages.bban)}</span> {account.bban}</p>;
            default:
                return (
                    <>
                        <p><span className='label'>{ lng(jobmessages.iban) }</span> { account.iban }</p>
                        <p><span className='label'>{ lng(jobmessages.bic) }</span> { account.bic }</p>
                        { typeof account.virtualbarcode !== 'undefined' && account.virtualbarcode !== null && account.virtualbarcode !== '' && (
                            <p><span className='label'>{ lng(jobmessages.virtualbarcode) }</span> { account.virtualbarcode }</p>
                        )}
                    </>
                );
        }
    };

    // bank information 
    return (
        <>
            {props.job.accounts !== null && (
                <Card className='fullwidth-mobile invoice-details bank-information grey-bottom-border-mobile pt-0 pb-0 pt-sm-4 pb-sm-3'>
                    <Card.Body>
                        <h3>{lng(jobmessages.accountInformation)}</h3>
                        <ul className='list-unstyled'>
                            {props.job.accounts.map((account, i) => {
                                let accountType = 'iban';

                                // check agency and if bankgirot or bban is available (iban and bic as default)
                                if (country === 'se' && account?.bankgirot) {
                                    accountType = 'bankgirot';
                                } else if (country === 'no' && account?.bban) {
                                    accountType = 'bban';
                                }

                                return (
                                    <>
                                        <li key={"account-" + accountType + "-" + i}>
                                            {showAccountDetails(accountType, account)}
                                        </li>
                                    </>
                                );
                            })}
                        </ul>
                    </Card.Body>
                </Card>
            )}
        </>
    );
};

BankInformation.propTypes = {
    'intl': PropTypes.object.isRequired,
    'job': PropTypes.object,
};

export default BankInformation;