import React, { useEffect, useState } from "react";
import { Modal, Card, Container, Col, Row, Button } from "react-bootstrap";
import { defineMessages } from "react-intl";
import PropTypes from "prop-types";
import { netaxeptPreselectBanks } from "../../paymentProviders";
import { paymentService } from "../../../../services/payment";
import { userRequestHeaders, userGetLocale } from "../../../../services/user";
import { mapLocaleToHeaders } from "../../../../services/helpers";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';

const messages = defineMessages({
    choosePaymentMethod: {
        id: "Valitse maksutapa",
        defaultMessage: "Valitse maksutapa"
    },
    actionClose: {
        id: "Sulje",
        defaultMessage: "Sulje"
    },
    actionCancel: {
        id: "Peruuta",
        defaultMessage: "Peruuta"
    },
    errorOccurred: {
        id: "Tapahtui virhe",
        defaultMessage: "Tapahtui virhe",
    },
    somethingWentWrongTryAgain: {
        id: "Jotain meni vikaan, ole hyvä ja yritä uudelleen.",
        defaultMessage: "Jotain meni vikaan, ole hyvä ja yritä uudelleen.",
    },
    backToInvoice: {
        id: "Palaa laskun tietoihin",
        defaultMessage: "Palaa laskun tietoihin",
    },
    authError: {
        id: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen."
    },
    onlineBanks: {
        id: "Verkkopankit",
        defaultMessage: "Verkkopankit"
    }
});

export { messages };


const BankSelectionModal = (props) => {
    // translations
    const lng = props.intl.formatMessage;
    const [banks, setBanks] = useState([]);
    const [tinkBanks, setTinkBanks] = useState([]);
    const [error, setError] = useState(false);

    const onlineBanks = {
        name: lng(messages.onlineBanks),
        bank_id: '',
        logo: "/img/building-columns-light.svg",
        rank: 1,
        style: 'tinkstyle'
    };

    // get Tink payment methods when initialized
    useEffect(() => {
        // no need to get Tink banks if user is redirected to Tink for payment method selection
        if (!props.redirectToTinkForPayment) {
            getTinkPaymentMethods();
        }
    }, [props.redirectToTinkForPayment]);


    useEffect(() => {
        if (props.show) {
            let bankOptions = [];

            if (error && props.serviceProvider === paymentService.PAYMENTMETHODS.TINK) {
                // in error cases close modal, but if other service providers are available in the environment, allow paying with them
                props.handleBankSelectionModal();
                // show error message to user
                props.handleAlertDialog(lng(messages.somethingWentWrongTryAgain), 'danger');
            } else if (props.serviceProvider === paymentService.PAYMENTMETHODS.NETAXEPT) {
                // add banks from Netaxept passed in props to bank selection modal
                if (props.includedNetsBanks?.length > 0) {
                    bankOptions = addNetaxeptPaymentMethods(props.includedNetsBanks);
                }
                setBanks(bankOptions);
            } else if (props.serviceProvider === paymentService.PAYMENTMETHODS.TINK && props.redirectToTinkForPayment) {
                // add defined banks from Netaxept to bank selection modal if any
                if (props.includedNetsBanks?.length > 0) {
                    bankOptions = addNetaxeptPaymentMethods(props.includedNetsBanks);
                }

                // add 'Online banks' option that redirects to Tink for bank selection
                bankOptions.push(onlineBanks);
                setBanks(bankOptions);
            } else if (props.serviceProvider === paymentService.PAYMENTMETHODS.TINK) {
                // show payment methods from Tink (additional banks from Netaxept can be added with bankids via env. variable REACT_APP_NETAXEPT_PRESELECT_BANKS, Tink banks can be excluded from Tink console)
                setBanks(tinkBanks);
            } else {
                // bank selection not supported, skip in MyRopo and redirect straight to payment provider to choose the bank
                props.handleChoosePaymentMethod();
            }
        }

    }, [props.show]);


    /**
     * Get payment methods from Tink.
     */
    function getTinkPaymentMethods() {
        const headers = userRequestHeaders("application/pdf");
        // Add language
        headers['Accept-Language'] = mapLocaleToHeaders(userGetLocale());

        // HTTP Request to get available payment methods from Tink
        axios({
            method: 'GET',
            url: process.env.REACT_APP_API_URL + '/online/paymentproviders',
            headers: headers
        }).then(function (res) {
            let tinkModalBanks = [...res.data.providers];
            // add 'Online banks' option for redirection to Tink banks if banks are not returned for some reason, but payment provider is Tink
            if (res.data.providers.length === 0) {
                tinkModalBanks.push(onlineBanks);
            }

            // add different styling for logos from CDN
            tinkModalBanks.map((bank) => {
                bank.style = 'tinkstyle';
                return bank;
            });

            // add Netaxept payment methods if there are any
            if (props.includedNetsBanks?.length > 0) {
                let netsBanks = addNetaxeptPaymentMethods(props.includedNetsBanks);
                // add banks from Netaxept to be displayed with Tink banks
                netsBanks.forEach(function (_bank, i) {
                    tinkModalBanks.push(netsBanks[i]);
                });
            }

            // sort banks alphabetically and set banks
            tinkModalBanks.sort(function (a, b) { return a.name.localeCompare(b.name) });

            // if Swish is included in the payment methods, always make it the first option if it isn't already 
            tinkModalBanks.forEach(function (bank, i) {
                if (bank.bank_id === 'SwishE' && i > 0) {
                    tinkModalBanks.splice(i);
                    tinkModalBanks.unshift(bank);
                }
            });

            setTinkBanks(tinkModalBanks);
        }).catch(function (error) {
            if (error.response?.status === 401) {
                const alert = { message: lng(messages.authError), type: 'danger' };
                props.history.push({
                    pathname: "/logout",
                    alert
                });
            } else {
                // set error
                setError(true);
            }
        });
    }


    /**
     * Add defined Netaxept banks to bank selection.
     * If bank id does not match to the allowed netaxeptPreselectBanks, it is not added to the modal.
     * 
     * @param {*} includedNetsBanks
     * @returns array of Netaxept payment methods, that will be listed in the modal
     */
    function addNetaxeptPaymentMethods(includedNetsBanks) {
        let banks = [];

        // loop though the passed Netaxept banks and check whether or not it is in the allowed Netaxept banks list
        includedNetsBanks.forEach(netsbank => {
            netaxeptPreselectBanks.providers.map((bank) => {
                // add payment method option, if it is ok 
                if (bank.bank_id === netsbank) {
                    banks.push(bank);
                }
                return bank;
            });
        });

        return banks;
    }

    return (
        <Modal show={props.show} onHide={props.handleBankSelectionModal} size="lg">
            <Modal.Header className="white-header mb-0 pb-0" closeButton={true} closeLabel={props['aria-label'] || lng(messages.actionClose)}>
                <Button className="modal-back" onClick={() => props.handleBankSelectionModal()}>
                    <FontAwesomeIcon icon={far.faArrowLeft} /> {lng(messages.backToInvoice)}
                </Button>
                <h4 className="modal-title">{lng(messages.choosePaymentMethod)}</h4>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {/* list payment methods */}
                    <Row>
                        {banks.length > 0 ?
                            (banks.map((row) => {
                                return (
                                    <Col md={{ span: 3, order: row.rank }} sm={{ span: 4, order: row.rank }} xs={{ span: 6, order: row.rank }} key={row.bank_id} className="mb-4">
                                        <Card onClick={() => props.handleChoosePaymentMethod(row.bank_id)} className="payment-method h-100" id={row.name}>
                                            <Card.Img alt={row.name} id={row.name} variant="top" src={row.logo} className={row.style} />
                                            <Card.Header className="align-items-stretch h-100 p-0">
                                                <Card.Text aria-label={row.name} className="text-center payment-methods">
                                                    {row.name}
                                                </Card.Text>
                                            </Card.Header>
                                        </Card>
                                    </Col>
                                )
                            })
                            ) : ('')
                        }
                    </Row>
                    {/* Cancel button mobile only  */}
                    <div className="d-block d-md-none d-grid pt-2 pb-2">
                        <Button size="lg" variant="secondary" onClick={() => props.handleBankSelectionModal()}>{lng(messages.actionCancel)}</Button>
                    </div>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

BankSelectionModal.propTypes = {
    'aria-label': PropTypes.string,
    'intl': PropTypes.object.isRequired,
    'show': PropTypes.bool,
    'serviceProvider': PropTypes.string,
    'handleBankSelectionModal': PropTypes.func,
    'handleChoosePaymentMethod': PropTypes.func,
    'handleAlertDialog': PropTypes.func,
    'history': PropTypes.object,
    'includedNetsBanks': PropTypes.array,
    'redirectToTinkForPayment': PropTypes.bool
};

export default BankSelectionModal;