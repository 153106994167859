import React from "react";
import { defineMessages } from "react-intl";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

export const messages = defineMessages({
	fin: {
		id: "Suomi",
		defaultMessage: "Suomi"
	},
	swe: {
		id: "Svenska",
		defaultMessage: "Svenska"
	},
	eng: {
		id: "English",
		defaultMessage: "English"
	},
	nor: {
		id: "Norsk",
		defaultMessage: "Norsk"
	}
});

const LocaleSelector = (props) => {
	const lng = props.intl.formatMessage;
	// language button texts
	const fin = "FIN";
	const swe = "SWE";
	const eng = "ENG";
	const nor = "NOR";

	/**
	 * Switch the language and reload the page.
	 * @param {*} locale 
	 */
	function switchLang(locale) {
		localStorage.setItem('locale', locale);
		window.location.reload();
	};

	return (
		<Col className={props.colClassnames}>
			<Row >
				<Col xs={12}>
					<button className={props.optionClassnames + (props.selected === 'fi' ? " selected" : "")} onClick={() => switchLang('fi')} title={fin}>
						<span className="option-desc">{lng(messages.fin)}</span>
						<span className="option-icon">{fin}</span>
					</button>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<button className={props.optionClassnames + (props.selected === 'se' ? " selected" : "")} onClick={() => switchLang('se')} title={swe}>
						<span className="option-desc">{lng(messages.swe)}</span>
						<span className="option-icon">{swe}</span>
					</button>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<button className={props.optionClassnames + (props.selected === 'en' ? " selected" : "")} onClick={() => switchLang('en')} title={eng}>
						<span className="option-desc">{lng(messages.eng)}</span>
						<span className="option-icon">{eng}</span>
					</button>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<button className={props.optionClassnames + (props.selected === 'no' ? " selected" : "")} onClick={() => switchLang('no')} title={nor}>
						<span className="option-desc">{lng(messages.nor)}</span>
						<span className="option-icon">{nor}</span>
					</button>
				</Col>
			</Row>
		</Col>
	)
};

LocaleSelector.propTypes = {
	'intl': PropTypes.object.isRequired,
	'colClassnames': PropTypes.string,
	'optionClassnames': PropTypes.string,
	'selected': PropTypes.string
};


export default LocaleSelector;