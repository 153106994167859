import isEmpty from "lodash.isempty";

export default class PaymentService {
    /**
     * Available paymentmethods
     * @type {{NETAXEPT: string, TINK: string}}
     */
    PAYMENTMETHODS = {
        NETAXEPT: 'netaxept',
        TINK: 'tink'
    };

    constructor() {
        this.paymentService = this.PAYMENTMETHODS.NETAXEPT;

        if (Object.values(this.PAYMENTMETHODS).indexOf(process.env.REACT_APP_PAYMENT_SERVICE) > -1) {
            this.paymentService = process.env.REACT_APP_PAYMENT_SERVICE;
        }

        this.secondaryPaymentService = undefined;
        // secondary method must also be listed in the available payment methods
        if (Object.values(this.PAYMENTMETHODS).indexOf(process.env.REACT_APP_SECONDARY_PAYMENT_SERVICE?.toLowerCase()) > -1) {
            this.secondaryPaymentService = process.env.REACT_APP_SECONDARY_PAYMENT_SERVICE.toLowerCase();
        }

        this.randomizePercentage = undefined;
        // the percent for randomizing must be between 0 and 100 to be valid
        if (typeof process.env.REACT_APP_SECONDARY_PAYMENT_SERVICE_PERCENTAGE !== 'undefined' && process.env.REACT_APP_SECONDARY_PAYMENT_SERVICE_PERCENTAGE > -1 && process.env.REACT_APP_SECONDARY_PAYMENT_SERVICE_PERCENTAGE < 101) {
            this.randomizePercentage = process.env.REACT_APP_SECONDARY_PAYMENT_SERVICE_PERCENTAGE;
        }
    }

    /**
     * get paymentservice
     * @returns {string}
     */
    getPaymentService() {
        // Forced payment service exists?
        const forcedService = window.sessionStorage.getItem('paymentService');

        // randomize the payment method only if a secondary payment service and the percentage to use it are set and valid
        if (forcedService === null && this.secondaryPaymentService !== undefined && this.randomizePercentage !== undefined) {
            this.paymentService = this.randomizePaymentMethod();
        } else if (forcedService !== null) {
            this.paymentService = forcedService;
        }

        return this.paymentService;
    }

    /**
     * Set paymentservice
     *
     * @param paymentservice
     * @returns {string}
     */
    setPaymentService(paymentservice) {
        this.paymentService = paymentservice;
        return this.paymentService;
    }

    /**
     * Get transactionid from callback url
     * @param args query params from callbackurl
     * @returns {string}
     */
    getCallbackTransactionId(args) {
        let transactionId = "";
        switch (this.getPaymentService()) {
            case this.PAYMENTMETHODS.NETAXEPT:
                if (isEmpty(args['transactionId']) || typeof args['transactionId'] === "undefined") {
                    throw new Error('transactionId undefined');
                }
                transactionId = args['transactionId'];
                break;

            case this.PAYMENTMETHODS.TINK:
                if (isEmpty(args['payment_request_id']) || typeof args['payment_request_id'] === "undefined") {
                    throw new Error('payment_request_id undefined');
                }
                transactionId = args['payment_request_id'];
                break;

            default:
                throw new Error('Unsupported payment service');
        }
        return transactionId;
    }

    /**
     * Resolve if transaction were successfull
     * @param args
     * @returns {boolean}
     */
    getCallbackSuccess(args) {
        let success = false;
        switch (this.getPaymentService()) {
            case this.PAYMENTMETHODS.NETAXEPT:
                if (args['responseCode'] === "OK") {
                    success = true;
                }
                break;
            case this.PAYMENTMETHODS.TINK:
                if (isEmpty(args['error'])){
                    success = true;
                }
                break;
            default:
        }
        return success;
    }

    /**
     * Used payment method can be randomized, if both of the following env.variables are set:
     * - REACT_APP_SECONDARY_PAYMENT_SERVICE
     * - REACT_APP_SECONDARY_PAYMENT_SERVICE_PERCENTAGE
     *
     * Secondary payment service must be listed in the available payment methods (netaxept or tink).
     * Percentage must be between 0 and 100 (0 = never use secondary, 100 = will always use secondary).
     *
     * @returns {string}
     */
    randomizePaymentMethod() {
        let randomizedPaymentMethod = Math.floor(Math.random() * 100) > this.randomizePercentage ? this.PAYMENTMETHODS.NETAXEPT : this.secondaryPaymentService;
        return randomizedPaymentMethod;
    }

    /**
     * Reset payment service back to environment defaults, and also
     * remove paymentService item from sessionStorage.
     *
     * @returns {string}
     */
    resetPaymentService() {
        // default to netaxept or use value from enviroment (if set)
        this.paymentService = this.PAYMENTMETHODS.NETAXEPT;

        if (Object.values(this.PAYMENTMETHODS).indexOf(process.env.REACT_APP_PAYMENT_SERVICE) > -1) {
            this.paymentService = process.env.REACT_APP_PAYMENT_SERVICE;
        }

        window.sessionStorage.removeItem('paymentService');

        return this.paymentService;
    }
}

/**
 * Global instance of PaymentService
 */
export let paymentService = new PaymentService();