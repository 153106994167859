import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, FormCheck, Button, Card, FormSelect } from 'react-bootstrap';
import { injectIntl, defineMessages } from "react-intl";
import axios from 'axios';
import PropTypes from 'prop-types';

// Services
import { userRequestHeaders, userGetCountry, userLogout } from '../../../services/user';
import { toggleLoading } from '../../../services/helpers';
import AlertDialog from "../../../components/AlertDialog";


// strings for translation in this view
const messages = defineMessages({
    phoneNumber: {
        id: "Puhelinnumero",
        defaultMessage: "Puhelinnumero"
    },
    send: {
        id: "Lähetä",
        defaultMessage: "Lähetä"
    },
    pleaseInputNumber: {
        id: "Ole hyvä ja syötä kenttään numero.",
        defaultMessage: "Ole hyvä ja syötä kenttään numero."
    },
    titleSavingFailed: {
        id: 'Tallennus epäonnistui',
        defaultMessage: 'Tallennus epäonnistui'
    },
    somethingWentWrong: {
        id: "Jotain meni vikaan, ole hyvä ja yritä uudelleen.",
        defaultMessage: "Jotain meni vikaan, ole hyvä ja yritä uudelleen."
    },
    enterPhoneNumber: {
        id: "Anna puhelinnumero",
        defaultMessage: "Anna puhelinnumero"
    },
    enterPhoneNumberParagraph1: {
        id: "Anna puhelinnumero, johon haluat vastaanottaa valitsemasi laskuttajan laskut jatkossa tekstiviestinä.",
        defaultMessage: "Anna puhelinnumero, johon haluat vastaanottaa valitsemasi laskuttajan laskut jatkossa tekstiviestinä."
    },
    enterPhoneNumberParagraph2: {
        id: "Saat samaan numeroon myös vahvistuskoodin, jolla voit vahvistaa tilauksesi.",
        defaultMessage: "Saat samaan numeroon myös vahvistuskoodin, jolla voit vahvistaa tilauksesi."
    },
    deliveryMethod: {
        id: "Toimitustapa",
        defaultMessage: "Toimitustapa"
    },
    sms: {
        id: "SMS",
        defaultMessage: "SMS"
    },
    countryCode: {
        id: "Maakoodi",
        defaultMessage: "Maakoodi"
    },
    editPhoneNumber: {
        id: "Muokkaa puhelinnumeroa",
        defaultMessage: "Muokkaa puhelinnumeroa"
    },
    sendConfrimationCode: {
        id: "Lähetä vahvistuskoodi",
        defaultMessage: "Lähetä vahvistuskoodi"
    },
    invalidPhoneNumber: {
        id: "Puhelinnumero on virheellinen",
        defaultMessage: "Puhelinnumero on virheellinen"
    },
    pleaseTryAgain: {
        id: "Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Ole hyvä ja yritä uudelleen."
    },
    saving: {
        id: "Tallennetaan",
        defaultMessage: "Tallennetaan"
    },
    authError: {
        id: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen."
    }
});
export { messages };

const DeliveryMethod = (props) => {
    // translation function
    const lng = props.intl.formatMessage;
    // form classes
    let classNames = ["form-horizontal"];

    const [dpid, setDpid] = useState();
    const [show, setShow] = useState(false);
    const [countryCodes, setCountryCodes] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [disabled, setDisabled] = useState(false);

    const [deliveryMethod, setDeliveryMethod] = useState();
    const [countryCode, setCountryCode] = useState();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isValidated, setIsValidated] = useState(false);

    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    const country = userGetCountry();

    useEffect(() => {
        if (isValidated) {
            classNames.push("valid");
        }
    }, [isValidated]);


    useEffect(() => {
        // set possible countries and their opertive code
        setCountryCodes([
            { value: '+45', country: 'dk' },
            { value: '+46', country: 'se' },
            { value: '+47', country: 'no' },
            { value: '+354', country: 'is' },
            { value: '+358', country: 'fi' },
        ]);

        // set operative country as initial value of country code
        switch (country) {
            case 'fi':
                setCountryCode('+358');
                break;
            case 'no':
                setCountryCode('+47');
                break;
            case 'se':
            default:
                setCountryCode('+46');
                break;
        }
    }, []);


    useEffect(() => {
        setShow(false);

        // show step 1 and set initial values
        if (props.showDeliveryMethod) {
            setShow(true);
            setDpid(props.job.dpid);
            // set delivery method automatically to sms in the piloting phase
            setDeliveryMethod('sms');
            // phone number exists if the user edits their phone number from step 2
            if (phoneNumber.length > 0) {
                // find input field to add not-empty to its classlist
                findInput();
            }
        }
        else if (!props.showDeliveryMethod && props.showError) {
            setShow(true);
            setDisabled(true);
        }

    }, [props.showDeliveryMethod, props.showError]);


    async function findInput() {
        // find input field
        const input = await waitForInput("phone-number-input");
        // add class to input field
        input.classList.add("not-empty");
    }

    async function waitForInput(element) {
        // use mutationObserver to check if input field is added to DOM tree yet
        return new Promise(resolve => {
            if (document.querySelector(element)) {
                return resolve(document.querySelector(element));
            }
            const observer = new MutationObserver(mutations => {
                if (document.querySelector(element)) {
                    resolve(document.querySelector(element));
                    observer.disconnect();
                }
            });
            // observe changes in the document
            observer.observe(document.body, {
                childList: true,
                subtree: true
            })
        })
    }


    // check phone number of the input field
    function handlePhoneNumber(e) {
        let inputValue = e.target.value;
        const phoneInput = e.target.parentNode.querySelector("#phone-number-input");

        // check that input field is not empty
        if (inputValue) {
            // input field is not empty
            e.target.classList.add("not-empty");

            // check if the last value in input field is not a number
            if (isNaN(inputValue[inputValue.length - 1])) {
                // add error class, show error message and disable button
                e.target.classList.add("error");
                phoneInput.classList.add("is-invalid");
                setShowAlertDialog(true);
                setAlertMessage(lng(messages.pleaseInputNumber));
                setAlertType('danger');
                setIsValidated(false);
                setButtonDisabled(true);
            }
            else {
                // remove errors and enable button
                e.target.classList.remove("error");
                phoneInput.classList.remove("is-invalid");
                setShowAlertDialog(false);
                setIsValidated(true);
                setButtonDisabled(false);
            }
        }
        else {
            // input field is empty
            e.target.classList.remove("not-empty");
            // remove errors, disable button
            e.target.classList.remove("error");
            phoneInput.classList.remove("is-invalid");
            setShowAlertDialog(false);
            setIsValidated(false);
            setButtonDisabled(true);
        }

        // set phone number
        setPhoneNumber(inputValue);
    }


    // submit phone number
    function handleSubmit(e) {
        e.preventDefault();

        // find input field to turn red
        const phoneInput = e.target.parentNode.querySelector("#phone-number-input");

        let inputIsOk = true;
        // check that input field contains only numbers before submitting
        for (let i = 0; phoneNumber.length > i; i++) {
            // check if number or not, show error under input field
            if (isNaN(phoneNumber[i])) {
                inputIsOk = false;
                phoneInput.classList.add("is-invalid");
                setShowAlertDialog(true);
                setAlertType('danger');
                setAlertMessage(lng(messages.pleaseInputNumber));
            }
        }

        if (inputIsOk) {
            // loading animation on
            toggleLoading(true, lng(messages.saving) + "...");
            // parse phone number from country code and input field content
            let phone = countryCode + phoneNumber;
            // clear error message
            phoneInput.classList.remove("is-invalid");
            setShowAlertDialog(false);

            // send phone number
            const headers = userRequestHeaders();
            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/online/subscribe',
                headers: headers,
                data: JSON.stringify({
                    type: deliveryMethod,
                    value: phone,
                    id: dpid
                })
            })
            .then(function (response) {
                // save delivery details and verification uuid to parent component, too
                props.handleDeliveryChoices(deliveryMethod, phone, response.data.verification_uuid);
                // A successful request, hide component and update details
                toggleLoading(false);
                // hide step 1 and show step 2
                props.changeStep();
            })
            .catch(function (error) {
                // loading animation off
                toggleLoading(false);

                // authentication failed
                if (error.response?.status === 401) {
                    // log out from current session and redirect back to subscription login for authentication
                    userLogout();
                    // show error message to user
                    const alert = { message: lng(messages.authError), type: 'danger' };
                    props.history.replace({
                        pathname: '/subs/' + dpid,
                        alert
                    });
                } else if (typeof error.response !== 'undefined') {
                    if (error.response.status === 400 && error.response.data.Message === "Invalid phone number") {
                        // phone number is invalid show alertdialog
                        phoneInput.classList.add("is-invalid");
                        setShowAlertDialog(true);
                        setAlertMessage(lng(messages.invalidPhoneNumber) + ". " + lng(messages.pleaseTryAgain));
                        setAlertType('danger');
                    } else {
                        // request failed, general info
                        setShowAlertDialog(true);
                        setAlertMessage(lng(messages.somethingWentWrong));
                        setAlertType('danger');
                    }
                } else {
                    // request failed, general info
                    setShowAlertDialog(true);
                    setAlertMessage(lng(messages.somethingWentWrong));
                    setAlertType('danger');
                }
            });
        }
    }


    // choosing the delivery method
    return (
        <>
            {show && (
                <div id="subscription-step1">
                    <Row>
                        <Card className='card'>
                            <Card.Body>
                                <h2>{lng(messages.enterPhoneNumber)} </h2>
                                <p>{lng(messages.enterPhoneNumberParagraph1)}</p>
                                <p>{lng(messages.enterPhoneNumberParagraph2)}</p>
                                {showAlertDialog && (
                                    <AlertDialog message={alertMessage} type={alertType} className="d-none"/>
                                    )}
                                {/*<AlertDialog message={lng(messages.phoneNumber)} type={"danger"} className="d-none"/>*/}
                                <form id="subscription-form-step-1" onSubmit={handleSubmit} className={classNames} noValidate>
                                    <FormGroup className="form-group" id='delivery-method-input'>
                                        <Row>
                                            <Col md={3} sm={3} xs={5}>
                                                <label className='radio-inline'>{lng(messages.deliveryMethod)}:</label>
                                            </Col>
                                            <Col md={9} sm={9} xs={7} id="delivery-method-col">
                                                <FormCheck type='radio' id="delivery-method-radio" inline key="sms" value={deliveryMethod} checked={true} disabled={disabled} readOnly label={lng(messages.sms)} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <Row>
                                        <Col md={3} xs={4} className='pe-0'>
                                            <FormGroup id="area-code-input" className="form-group has-label-select">
                                                <FormSelect id="area-code" value={countryCode} onChange={(e) => { setCountryCode(e.target.value) }} selected={countryCode} disabled={disabled}>
                                                    {countryCodes.length > 0 ? countryCodes.map((option) => {
                                                        return (
                                                            <option key={option.value} value={option.value} required> {option.value} </option>
                                                        )
                                                    }) : (<></>)}
                                                </FormSelect>
                                                <label>{lng(messages.countryCode)}</label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={9} xs={8} className='ps-0'>
                                            <FormGroup id="phone-number" className="form-floating">
                                                <input className="form-control" id="phone-number-input" type="text" placeholder={lng(messages.phoneNumber)} value={phoneNumber} onChange={handlePhoneNumber} minLength={1} disabled={disabled} required />
                                                <label id="phone-number-label"> {lng(messages.phoneNumber)} </label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} sm={12} className='d-grid'>
                                            <Button disabled={buttonDisabled} type="submit" id="subs-button" >
                                                {lng(messages.sendConfrimationCode)}
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                            </Card.Body>
                        </Card>
                    </Row>
                </div>
            )}
        </>
    );
};


DeliveryMethod.propTypes = {
    'intl': PropTypes.object.isRequired,
    'showDeliveryMethod': PropTypes.bool,
    'job': PropTypes.object,
    'showError': PropTypes.bool,
    'handleDeliveryChoices': PropTypes.func,
    'changeStep': PropTypes.func,
    'history': PropTypes.any,
};


export default injectIntl(DeliveryMethod);