import React, { useEffect } from "react";
import axios from "axios";
import isEmpty from 'lodash.isempty';

// Services
import { userRequestHeaders } from "../../services/user/index";
import { toggleLoading } from "../../services/helpers/index";
import { jobGetCode, jobFetchJob } from "../../services/job";
import { paymentService } from '../../services/payment/index';
import { injectIntl, defineMessages } from "react-intl";
import PropTypes from 'prop-types';

// Strings for translation in this view
const messages = defineMessages({
	paymentVerification: {
		id: "Vahvistetaan maksua",
		defaultMessage: "Vahvistetaan maksua"
	},
	paymentCancelled: {
		id: "Maksaminen keskeytetty",
		defaultMessage: "Maksaminen keskeytetty"
	},
	paymentException: {
		id: "Tapahtui virhe",
		defaultMessage: "Tapahtui virhe"
	},
	paymentSuccess: {
		id: "Kiitos! Maksusi on nyt kunnossa.",
		defaultMessage: "Kiitos! Maksusi on nyt kunnossa."
	},
	paymentSuccess151Or155: {
		id: "Kiitos maksustasi! Pyydämme energiayhtiötäsi peruuttamaan jakelun keskeytyksen. Jos energiayhtiösi on kuitenkin jo ehtinyt aloittaa katkaisuun johtavat toimet ja jakelusi keskeytyy, tulee kaikki avoimet erääntyneet laskut, mahdollinen vakuuslasku sekä katkaisu- ja kytkentälasku olla maksettuna ennen uudelleenkytkentää. Huomioithan, että Ropo ei tee energianjakelun keskeytyksiä, joten yhteydenottosi asiakaspalveluumme ei vaikuta mahdollisiin katkaisutoimiin.",
		defaultMessage: "Kiitos maksustasi! Pyydämme energiayhtiötäsi peruuttamaan jakelun keskeytyksen. Jos energiayhtiösi on kuitenkin jo ehtinyt aloittaa katkaisuun johtavat toimet ja jakelusi keskeytyy, tulee kaikki avoimet erääntyneet laskut, mahdollinen vakuuslasku sekä katkaisu- ja kytkentälasku olla maksettuna ennen uudelleenkytkentää. Huomioithan, että Ropo ei tee energianjakelun keskeytyksiä, joten yhteydenottosi asiakaspalveluumme ei vaikuta mahdollisiin katkaisutoimiin."
	},
	paymentSuccess156: {
		id: "Olemme vastaanottaneet maksuilmoituksesi ja käsittelemme sitä parhaillaan. Tämän jälkeen uudelleenkytkentä siirtyy energiayhtiösi käsittelyyn. Jos takaisinkytkennälle ilmenee esteitä, otamme sinuun välittömästi yhteyttä. Huomioithan, että yhteydenottosi asiakaspalveluumme ei nopeuta asiasi käsittelyä.",
		defaultMessage: "Olemme vastaanottaneet maksuilmoituksesi ja käsittelemme sitä parhaillaan. Tämän jälkeen uudelleenkytkentä siirtyy energiayhtiösi käsittelyyn. Jos takaisinkytkennälle ilmenee esteitä, otamme sinuun välittömästi yhteyttä. Huomioithan, että yhteydenottosi asiakaspalveluumme ei nopeuta asiasi käsittelyä."
	},
    paymentSuccess41: {
        id: "Kiitos! Maksusi on nyt kunnossa. Huomioithan, että erä näkyy MyRopossa maksettuna vasta, kun maksu on kirjautunut saajan pankkitilille.",
        defaultMessage: "Kiitos! Maksusi on nyt kunnossa. Huomioithan, että erä näkyy MyRopossa maksettuna vasta, kun maksu on kirjautunut saajan pankkitilille."
    },
    authError: {
        id: 'Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.',
        defaultMessage: 'Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.'
    }
});

export { messages };

const Payment = (props) => {

	// translations
	const lng = props.intl.formatMessage;

	/**
	 *
	 * @param {*} props
	 * @returns Array
	 */
	function parseQueryStringFromProps(props) {
		// Throw error location search undefined or null
		if (isEmpty(props.location.search) || typeof props.location.search === "undefined") {
			throw new Error('Props location search undefined or null');
		}

		let queryArgs = props.location.search.substring(1);
		queryArgs = queryArgs.split('&');

		let args = [];
		for(let i = 0; i < queryArgs.length; i++) {
			let split = queryArgs[i].split('=');
			args[split[0]] = split[1];
		}

		return args;
	}


	useEffect(() => {

		// Alert content
		let alert = {};

		try {
			// parse args from props query string
			const args = parseQueryStringFromProps(props);
			const transactionId = paymentService.getCallbackTransactionId(args);

			if (paymentService.getCallbackSuccess(args)) {
				// Message to user that transaction is verifying
				toggleLoading(true, lng(messages.paymentVerification) + " (" + transactionId + ")...");
			} else {
				// Message to user that transaction is cancelled (or failed for some reason)
				toggleLoading(true, lng(messages.paymentCancelled) + "...");
			}

			const storageItem = window.sessionStorage.getItem('paymentService');
			let serviceParam = '';

			// check if service should be added to query parameters
			if (Object.values(paymentService.PAYMENTMETHODS).indexOf(storageItem) > -1) {
				serviceParam = '?service=' + storageItem;
			}

			const headers = userRequestHeaders();

			axios({
				method: 'GET',
				url: process.env.REACT_APP_API_URL + '/online/paymentstatus/' + transactionId + serviceParam,
				headers: headers,
				responseType: 'json',
			})
				.then(function (res) {
					const authorized = res.data.authorized;
					if (authorized !== true) {
						throw new Error('Payment response unauthorized!');
					}

					// get job's status before it was paid
					const prevStatus = window.sessionStorage.getItem('previousJobStatus');

					// show success message based on previous status
					if (prevStatus.toString() === '151' || prevStatus.toString() === '155') {
						alert = { message: lng(messages.paymentSuccess151Or155), type: 'success' };
					} else if (prevStatus.toString() === '156') {
						alert = { message: lng(messages.paymentSuccess156), type: 'success' };
					} else if (prevStatus.toString() === '41') {
                        alert = { message: lng(messages.paymentSuccess41), type: 'success' };
                    } else {
						alert = { message: lng(messages.paymentSuccess), type: 'success' };
					}

					paymentService.resetPaymentService();
					redirectToJob(alert);
				})
				.catch(function (error) {
					// notify user with generic exception alert
					alert = { message: lng(messages.paymentException) + '. ' + lng(messages.paymentCancelled), type: 'danger' };
					paymentService.resetPaymentService();
					redirectToJob(alert);
				});
		} catch (error) {
			// Show generic error message
			alert = { message: lng(messages.paymentException) + '. ' + lng(messages.paymentCancelled), type: 'danger' };
			paymentService.resetPaymentService();
			redirectToJob(alert);
		}
	}, []);


	/**
	 * Reload job and redirect
	 */
	function redirectToJob(alert) {
		const dpid = jobGetCode();

		jobFetchJob(dpid)
			.then(function () {
				toggleLoading(false);

				props.history.push({
					pathname: '/job',
					alert
				});
			})
			.catch(function (error) {
				toggleLoading(false);
                
                if (error.response?.status === 401) {
                    const alert = {message: lng(messages.authError), type: 'danger'};
                    props.history.push({
                        pathname: "/logout",
                        alert
                    });
                } else {
                    // If fetchjob fails -> cannot refresh job
                    // go still back, there is job with status before payment
                    alert = { message: lng(messages.paymentException) + '. ' + lng(messages.paymentCancelled), type: 'danger' };
    
                    props.history.push({
                        pathname: '/job',
                        alert
                    });
                }
			});
	}

	return (<div></div>);
};

Payment.propTypes = {
	intl: PropTypes.object.isRequired,
	history: PropTypes.any.isRequired
};

export default injectIntl(Payment);