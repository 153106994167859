import React, { useEffect, useState } from 'react';
import { injectIntl, defineMessages } from "react-intl";
import { Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

// Services
import { formatDate, toggleLoading } from '../../../services/helpers';

// strings for translation in this view
const messages = defineMessages({
    actionLogout: {
        id: "Kirjaudu ulos",
        defaultMessage: "Kirjaudu ulos"
    },
    orderCompleted: {
        id: "Tilaus tehty",
        defaultMessage: "Tilaus tehty"
    },
    dateOfOrder: {
        id: "Tilauspäivämäärä",
        defaultMessage: "Tilauspäivämäärä"
    },
    deliveryMethod: {
        id: "Toimitustapa",
        defaultMessage: "Toimitustapa"
    },
    phoneNumber: {
        id: "Puhelinnumero",
        defaultMessage: "Puhelinnumero"
    },
    sms: {
        id: "SMS",
        defaultMessage: "SMS"
    }
});
export { messages };


const SubscriptionSummaryCard = (props) => {
    // translations function
    const lng = props.intl.formatMessage;

    const [show, setShow] = useState(false);
    const [subscriptionDate, setSubscriptionDate] = useState("");
    const [deliveryMethod, setDeliveryMethod] = useState("");
    const [deliveryMethodValue, setDeliveryMethodValue] = useState("");
    const [subscriptionTime, setSubscriptionTime] = useState("");

    useEffect(() => {
        // loading animation off
        toggleLoading(false);

        if (props.showSubscriptionSuccess) {
            setShow(true);
            setSubscriptionDate(props.subscriptionSummary.subscriptionDate);
            setDeliveryMethod(props.subscriptionSummary.subscriptionDeliveryMethod);
            setDeliveryMethodValue(props.subscriptionSummary.subscriptionDeliveryMethodValue);

            // save subscription time from props.subscriptionSummary (hh:mm)
            let time = props.subscriptionSummary.subscriptionDate.split(' ');
            time = time[1].slice(0,5);
            setSubscriptionTime(time);
        }

    }, [props.showSubscriptionSuccess]);


    return (
        <>
            {show && (
                <div id="subscription-step3">
                    <div className='creditor-info-padding d-none d-sm-block' />
                    <Card className='card-borderless' id="subscription-summary-card">
                        <Row>
                            <Col md={4} sm={12} xs={12} className="success-summary-icon-column">
                                <h2>{lng(messages.orderCompleted)}</h2>
                                <FontAwesomeIcon icon="check-circle" className="icon fa-8x" id="subscription-success" />
                            </Col>
                            <Col md={8} sm={12} xs={12} className='success-summary-column'>
                                <Card.Body>
                                    <ul className="list-unstyled">
                                        <div className='creditor-info-padding d-md-none' />
                                        <li id="item-subscription-date">
                                            <span className="label">
                                                {lng(messages.dateOfOrder)}
                                            </span>
                                            <span id="subscription-date">{formatDate(subscriptionDate)}</span> <span id="subscription-time">{subscriptionTime}</span>
                                        </li>
                                        <li id="item-delivery-method">
                                            <span className="label">
                                                {lng(messages.deliveryMethod)}
                                            </span>
                                            <span id="delivery-method">{deliveryMethod === 'sms' ? lng(messages.sms) : deliveryMethod}</span>
                                        </li>
                                        <li id="item-delivery-method-value">
                                            <span className="label">
                                                {lng(messages.phoneNumber)}
                                            </span>
                                            <span id="delivery-method-value">{deliveryMethodValue}</span>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                    <Row>
                        <Col md={4} sm={12} xs={12} className='d-grid'>
                            <Card.Body className='d-grid'>
                                <div className='creditor-info-padding d-none d-sm-block' />
                                <button className="btn btn-primary" id="logout-subscriptions" onClick={props.handleLogOut}>
                                    {lng(messages.actionLogout)}
                                </button>
                            </Card.Body>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

SubscriptionSummaryCard.propTypes = {
    intl: PropTypes.object.isRequired,
    handleLogOut: PropTypes.func,
    showSubscriptionSuccess: PropTypes.bool,
    subscriptionSummary: PropTypes.object,
};


export default injectIntl(SubscriptionSummaryCard);
