import React, { useEffect, useState } from "react";
import { Form, FormGroup, FormSelect, Row } from "react-bootstrap";
import get from "lodash.get";
import axios from "axios";
import { messages } from "../../../../index";
import PropTypes from 'prop-types';

// Services
import { jobGetCode, jobGetJob } from "../../../../../../services/job";
import { userGetCountry, userGetLocale, userRequestHeaders } from "../../../../../../services/user";
import { formatMoney } from "../../../../../../services/helpers";

// Components
import AlertDialog from "../../../../../../components/AlertDialog";

const ReturnPaymentStep3 = (props) => {
    
    const [accountTypes, setAccountTypes] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [showForm, setShowForm] = useState(true);
    const [showBicField, setShowBicField] = useState(false);
    
    // Input fields
    const [type, setType] = useState('');
    
    const lng = props.intl.formatMessage;
    const country = userGetCountry();
    const job = jobGetJob();
    const userLocale = userGetLocale();
    
    const dpid = jobGetCode();
    
    let currency = get(job, 'currency', false);
    currency = (currency && currency !== '') ? currency : process.env.REACT_APP_DEFAULT_CURRENCY;
    
    useEffect(() => {
        getSupportedAccountTypes();
    }, []);
    
    useEffect(() => {
        if (type !== '') {
            setShowBicField(false);
            
            // Shows input field for BIC, because BIC is resolved from IBAN only in Finland
            if (type === 'iban' && country !== 'fi') {
                setShowBicField(true);
            }
        }
    }, [type]);
    
    /**
     * Handle errors
     */
    useEffect(() => {
        // Set error to alertDialog if one is received
        if (props.error?.message !== '') {
            setErrorMessage(props.error.message);
        }
        
    }, [props.error]);
    
    /**
     * Handle changes in input fields and remove errors.
     *
     * @param e
     */
    function handleInputUpdate(e) {
        // Remove validation errors
        if (e.target.checkValidity()) {
            e.target.classList.remove("is-invalid");
        }
        // Clear the error message
        setErrorMessage('');
    }
    
    /**
     * Get supported account types for the form
     */
    function getSupportedAccountTypes() {
        const headers = userRequestHeaders();
        let accountTypeArray = [];
        
        // HTTP Request
        axios({
            method: 'GET',
            url: process.env.REACT_APP_API_URL + '/online/bankaccounttypes',
            headers: headers,
        })
        .then(function(response) {
            if (typeof response.data?.overpaidbankaccount !== 'undefined') {
                // Collect the account types
                response.data.overpaidbankaccount?.forEach(function (item) {
                    accountTypeArray.push({
                        value: item.type,
                        text: lng(messages[item.type])
                    });
                });
                
                // Set the collected account types
                setAccountTypes(accountTypeArray);
                // Set the first dropdown option as inital type
                setType(accountTypeArray[0].value);
            } else {
                // Error: Invalid response
                setErrorMessage(lng(messages.unknownError));
                setShowForm(false);
            }
        })
        .catch(function(error) {
            // Session expires
                if (error.response?.status === 401) {
                    const alert = {message: lng(messages.authError), type: 'danger'};
                    props.history.push({
                        pathname: "/logout",
                        alert
                    });
                } else {
                // Error: Request failed
                setErrorMessage(lng(messages.unknownError));
                setShowForm(false);
            }
        });
    }
    
    return (
        <>
            <Row>
                <p className='text-dark'>{ lng(messages.bankAccountDetailsDescription) }</p>
                <p className='fw-bold'>
                    { lng(messages.returnAmount) }: { formatMoney(job.modoverpaidamount, userLocale, currency) }<br/>
                    <span className='text-danger'>{ lng(messages.refundProcessingFee) }: { formatMoney(job.modoverpaidfee, userLocale, currency) }</span>
                </p>
            </Row>
            <Row>
                <h4 className='mt-2 mb-3'>{ lng(messages.bankAccountDetailsTitle) }</h4>
            </Row>
            {errorMessage !== '' && (
                <AlertDialog
                    message={errorMessage}
                    type='danger'
                />
            )}
            {showForm && (
                <Form id='returnpayment-accountdata-form'>
                    {/* Account type */}
                    <FormGroup controlId="accounttype" className="form-floating mb-3">
                        <FormSelect
                            id='returnpayment-account-type' role="select" className="form-control"
                            onChange={(e) => {
                                setType(e.target.value);
                                setErrorMessage('');
                            }}
                            value={ type }
                        >
                            { accountTypes.map((option) => {
                                return (
                                    <option key={option.value} value={option.value}>{option.text}</option>
                                )
                            }) }
                        </FormSelect>
                        <label htmlFor='returnpayment-account-type'>{ lng(messages.accountType) }</label>
                    </FormGroup>
                    
                    {/* BIC field */}
                    { showBicField && (
                        <Form.Floating className='mb-3'>
                            <Form.Control
                                id='returnpayment-account-bic' type='text' placeholder={ lng(messages.bic) } autoComplete='off'
                                onChange={(e) => handleInputUpdate(e)}
                                required
                            />
                            <Form.Label htmlFor='returnpayment-account-bic'>{ lng(messages.bic) }</Form.Label>
                            <Form.Control.Feedback type="invalid" />
                        </Form.Floating>
                    )}
                    
                    {/* Bank account number */}
                    <Form.Floating className='mb-3'>
                        <Form.Control
                            id='returnpayment-account-number' type='text' placeholder={ type === 'bban' ? lng(messages.accountNumberInclClearing) : lng(messages.accountNumber) } autoComplete='off'
                            onChange={(e) => handleInputUpdate(e)}
                            required
                        />
                        <Form.Label htmlFor='returnpayment-account-number'>{ type === 'bban' ? lng(messages.accountNumberInclClearing) : lng(messages.accountNumber) }</Form.Label>
                        <Form.Control.Feedback type="invalid" />
                    </Form.Floating>
        
                    {/* Bank account holder name */}
                    <Form.Floating className='mb-3'>
                        <Form.Control
                            id='returnpayment-account-holder-name' type='text' placeholder={ lng(messages.bankAccountHolderName) } autoComplete='off'
                            onChange={(e) => handleInputUpdate(e)}
                            required
                        />
                        <Form.Label htmlFor='returnpayment-account-holder-name'>{ lng(messages.bankAccountHolderName) }</Form.Label>
                        <Form.Control.Feedback type="invalid" />
                    </Form.Floating>
    
                    {/* DPID */}
                    <Form.Control id='returnpayment-dpid' type='hidden' value={dpid}/>
                </Form>
            )}
        </>
    );
};

ReturnPaymentStep3.propTypes = {
    'intl': PropTypes.object.isRequired,
    'error': PropTypes.object,
    'history': PropTypes.object
};

export default ReturnPaymentStep3;