import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { messages } from "../../../../index";
import PropTypes from "prop-types";

// Components
import AlertDialog from "../../../../../../components/AlertDialog";

const ReturnPaymentStep1 = (props) => {
    
    const [errorMessage, setErrorMessage] = useState(false);
    const [errorType, setErrorType] = useState('light');
    
    const lng = props.intl.formatMessage;
    
    /**
     * Handle invoice retrieving errors 
     */
    useEffect(() => {
        // Set error to alertDialog if one is received
        if (props.error?.message !== '') {
            setErrorMessage(props.error.message);
            setErrorType(props.error.type);
        }
        
    }, [props.error]);
    
    /**
     * Handle changes in input fields and remove errors.
     *
     * @param e
     */
    function handleInputUpdate(e) {
        // remove validation errors
        if (e.target.checkValidity()) {
            e.target.classList.remove("is-invalid");
        }
    }
    
    return (
        <Row>
            <Col>
                <p className='text-dark'>{ lng(messages.returnPaymentDescription) }</p>
                {errorMessage && errorMessage !== '' && (
                    <AlertDialog
                        message={errorMessage}
                        type={errorType}
                    />
                )}
                <Form id='returnpayment-dpid-form'>
                    {/* Case number */}
                    <Form.Floating className='mb-3'>
                        <Form.Control
                            id='returnpayment-case-number' type='number' placeholder={ lng(messages.caseNumber) } autoComplete='off'
                            min='0' max={Number.MAX_SAFE_INTEGER}
                            onChange={(e) => handleInputUpdate(e)}
                            required
                        />
                        <Form.Label htmlFor='returnpayment-case-number'>{ lng(messages.caseNumber) }</Form.Label>
                        <Form.Control.Feedback type="invalid" />
                    </Form.Floating>
                    {/* Online ID */}
                    <Form.Floating className='mb-3'>
                        <Form.Control
                            id='returnpayment-online-id' type='text' placeholder={ lng(messages.onlineId) } autoComplete='off'
                            onChange={(e) => handleInputUpdate(e)}
                            required
                        />
                        <Form.Label htmlFor="returnpayment-online-id">{ lng(messages.onlineId) }</Form.Label>
                        <Form.Control.Feedback type="invalid" />
                    </Form.Floating>
                </Form>
            </Col>
        </Row>
    );
};

ReturnPaymentStep1.propTypes = {
    'intl': PropTypes.object.isRequired,
    'error': PropTypes.object
};

export default ReturnPaymentStep1;