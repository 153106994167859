import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { defineMessages, injectIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { Container, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

// Components
import LocaleSelector from '../../../components/LocaleSelector';
import AlertDialog from "../../../components/AlertDialog";

// Services
import { userGetLocale } from '../../../services/user';
import { toggleLoading, mapLocaleToBCP47 } from '../../../services/helpers';

// Paragraphs to be translated
const messages = defineMessages({
    login: {
        id: "Kirjaudu MyRopo-palveluun",
        defaultMessage: "Kirjaudu MyRopo-palveluun"
    },
    orderWebInvoice: {
        id: "Weblaskun tilaaminen",
        defaultMessage: "Weblaskun tilaaminen"
    },
    creditorParagraph: {
        id: "Olet tilaamassa Weblaskun laskuttajalta",
        defaultMessage: "Olet tilaamassa Weblaskun laskuttajalta"
    },
    paragraph1: {
        id: "Maksaminen on helppoa ja turvallista Weblaskussa olevan linkin kautta. Tilaus edellyttää sähköisen tunnistautumisen.",
        defaultMessage: "Maksaminen on helppoa ja turvallista Weblaskussa olevan linkin kautta. Tilaus edellyttää sähköisen tunnistautumisen."
    },
    serviceProvider: {
        id: "Palvelun tarjoaa Ropo, joka näkyy myös tekstiviestin lähettäjänä tulevilla laskuillasi.",
        defaultMessage: "Palvelun tarjoaa Ropo, joka näkyy myös tekstiviestin lähettäjänä tulevilla laskuillasi."
    },
    logInAndSubscribe: {
        id: "Tunnistaudu palveluun ja tee tilaus",
        defaultMessage: "Tunnistaudu palveluun ja tee tilaus"
    }
});

// landing page for subscribing a web invoice
const LandingPage = (props) => {
    // translations
    const lng = props.intl.formatMessage;
    const [queryParameters, setQueryParameters] = useState(null);
    const [creditor, setCreditor] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('light');
    const [alertMessge, setAlertMessage] = useState('');

    useEffect(() => {
    
        // check if alert is passed in location props
        if (props.location?.alert) {
            setAlertMessage(props.location.alert.message);
            setAlertType(props.location.alert.type);
            setShowAlert(true);
        }
        
        document.body.classList.add('web-invoice-subscription-landing', 'has-background-image-subs-landing');
        // Hide loading animation
        toggleLoading(false);

        // check if url contains dpid
        if (props.match.params.dpid) {
            // save dpid to session storage
            sessionStorage.setItem('dpid', props.match.params.dpid);
            // save subscribeType to session storage to help determine the correct page to view
            sessionStorage.setItem('subscriptionType', 'web-invoice');

            // save other parameters from url if there are any
            if (props.location.search !== "") {
                setQueryParameters(new URLSearchParams(props.location.search))
            }
        }

        // remove classList items on unmount
        return (() => {
            document.body.classList.remove('web-invoice-subscription-landing', 'has-background-image-subs-landing')
        })

    }, []);


    useEffect(() => {
        if (queryParameters !== null) {
            // check if url contains creditor name and set it
            if (queryParameters.has('creditor') && queryParameters.get('creditor') !== "") {
                setCreditor(queryParameters.get('creditor'));
            }
        }

    }, [queryParameters]);

    return (
        <div id="web-invoice-subscription-landing">
            <Helmet>
                <title>{lng(messages.login)} | MyRopo</title>
            </Helmet>
            <div className="content">
                <div className="viewport">
                    <Container>
                        <Row>
                            <Col md={8} xs={12} className="subscription-landing-col">
                                <h1>{lng(messages.orderWebInvoice)}</h1>
                                <div id="web-invoice-subscription-info">
                                    {creditor !== "" &&
                                        <div id="landing-creditor-name-div">
                                            <p>
                                                {lng(messages.creditorParagraph)} <span id="landing-creditor-name"><strong>{creditor}</strong></span>.
                                            </p>
                                        </div>
                                    }
                                    <p>{lng(messages.paragraph1)}</p>
                                    <p>{lng(messages.serviceProvider)}</p>
                                </div>
                            </Col>
                        </Row>
                        {showAlert && (
                            <AlertDialog
                                message={alertMessge}
                                type={alertType}
                            />
                        )}
                        <Row>
                            <Col className="subscription-landing-login-col" md={5}>
                                {/* no company support in the piloting phase */}
                                <a id="logindefault-subscriptions" className="btn btn-primary btn-block" href={process.env.REACT_APP_AUTH_URL + '?language=' + mapLocaleToBCP47(userGetLocale())}>
                                    {lng(messages.logInAndSubscribe)}
                                </a>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <LocaleSelector {...props} colClassnames={"language"} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
};

LandingPage.propTypes = {
    intl: PropTypes.object.isRequired,
    match: PropTypes.object,
    location: PropTypes.object
};

export { messages };
export default withRouter(injectIntl(LandingPage));
