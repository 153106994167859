import React from 'react';
import { Row, Col, Card, Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';


const MessageCardSkeleton = (props) => {

    return (
        <>
            {Array(props.count).fill(null).map((value, index) => (
                <Card animation="glow" className="mb-3" style={{ opacity: 1 / (index + 1) }} key={"skeleton-messagecard-" + index} role="skeletonCard">
                    <Card.Body>
                        <Row>
                            <Col xs={12}>
                                <Col xs={12} className="mb-3">
                                    <Placeholder as='p' animation="glow" >
                                        <Placeholder xs={5} size={'lg'} />
                                    </Placeholder>
                                </Col>
                                <Col xs={12} className="mb-2 mb-md-1">
                                    <Placeholder as="h5" animation="glow">
                                        <Placeholder xs={12} />
                                    </Placeholder>
                                </Col>
                                <Col xs={12} className="mb-2 mb-md-1">
                                    <Placeholder as="p" animation="glow" className="fs-6">
                                        <Placeholder xs={8} />
                                    </Placeholder>
                                </Col>
                                <Col xs={12} className="mb-2 mb-md-1">
                                    <Placeholder as="p" animation="glow" className="fs-6">
                                        <Placeholder xs={9} />
                                    </Placeholder>
                                </Col>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )
            )}
        </>
    );
}


MessageCardSkeleton.propTypes = {
    'count': PropTypes.number.isRequired
};

export default MessageCardSkeleton;