import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import StatusBadge from "../../../components/StatusBadge";
import MessageStatus from "../../../components/MessageStatus";
import { jobFetchJob, jobGetGovidJob } from "../../../services/job";

// translations
export const messages = defineMessages({
    authError: {
        id: 'Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.',
        defaultMessage: 'Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.'
    },
    pleaseTryAgain: {
        id: "Tapahtui virhe. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tapahtui virhe. Ole hyvä ja yritä uudelleen."
    },
    jobNotFound: {
        id: "Virheelliset hakutiedot. Haku lukitaan viiden virheellisen haun jälkeen.",
        defaultMessage: "Virheelliset hakutiedot. Haku lukitaan viiden virheellisen haun jälkeen."
    },
    forbiddenError: {
        id: "Haku estetty.",
        defaultMessage: "Haku estetty."
    },
    searchLocked: {
        id: "Liian monta virheellistä hakua. Turvallisuussyistä haku on lukittu 30 minuutiksi.",
        defaultMessage: "Liian monta virheellistä hakua. Turvallisuussyistä haku on lukittu 30 minuutiksi."
    },
    notAllowedToSign: {
        id: "Ei nimenkirjoitusoikeutta.",
        defaultMessage: "Ei nimenkirjoitusoikeutta."
    }
});

const MessageCard = (props) => {

    const lng = props.intl.formatMessage;
    const [summary, setSummary] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [creditor, setCreditor] = useState('');
    

    useEffect(() => {

        // check if props are defined and set them to state
        if (props.summary !== undefined && props.summary !== '') {
            setSummary(props.summary);
        }
        if (props.invoiceNumber !== undefined && props.invoiceNumber !== '') {
            setInvoiceNumber(props.invoiceNumber);
        }
        if (props.creditor !== undefined && props.creditor !== '') {
            setCreditor(props.creditor);
        }

    });

    const getDpidByInvoiceNumber = (invoiceNumber, data) => {
        const job = data.find(item => item.jobid === parseInt(invoiceNumber));
        return job ? job.dpid : null;
    };

    const handleInvoiceClick = async (e) => {
        e.preventDefault();
        try {
            const data = jobGetGovidJob();
            const dpid = getDpidByInvoiceNumber(props.invoiceNumber, data);

            jobFetchJob(dpid)
            .then(function () {
                props.history.push('/job');
            })
            .catch(function (error) {
                // response exists
                if (typeof error?.response !== 'undefined') {
                    if (error.response.status === 401) {
                        // authentication failed, redirect back to authentication
                        const alert = { message: lng(messages.authError), type: 'danger' };
                        props.history.push({
                            pathname: "/logout",
                            alert
                        });
                    } else if (error.response.status === 403) {

                        // Forbidden messages
                        let message = lng(messages.forbiddenError);
    
                        if (typeof error.response.data.Message !== 'undefined') {
                            if (error.response.data.Message === 'Online access log - user access denied') {
                                message = lng(messages.searchLocked);
                            } else if (error.response.data.Message === 'Online jobsearch - company person is not allowed to sign') {
                                message = lng(messages.notAllowedToSign);
                            }
                        }
    
                        props.showAlerts({
                            message: message,
                            type: 'light',
                        });
                    } else {
                        props.showAlerts({
                            message: lng(messages.jobNotFound),
                            type: 'light',
                        });
                    }
                } else {
                    props.showAlerts({
                        message: lng(messages.pleaseTryAgain),
                        type: 'light',
                    });
                }
            });
        } catch (error) {
            props.showAlerts({
                message: lng(messages.pleaseTryAgain),
                type: 'danger',
            });
        }
    };

    return (
        <Card key={'messagecard-' + props.id} className={'fullwidth-mobile messagecard grey-bottom-border-mobile' + (props.cardIndex === props.activeIndex ? ' chosen ' : '')} role="messagecard">
            <Card.Body className="p-4">
                <Row>
                    <Col xs={12} className="mb-2">
                        <StatusBadge
                            statusCode={props.statusCode}
                            dueDate={props.dueDate}
                            lng={props.intl.formatMessage}
                        />
                        {props.statusCode !== undefined && <span className="me-1" />}
                        <MessageStatus
                            originalStatus={props.status}
                            lng={props.intl.lng}
                            {...props}
                        />

                        {props.isUnread ? (
                            <FontAwesomeIcon className="message-center-new-messages text-danger" icon={fas.faCircle} />
                        ) : null}
                    </Col>
                    <Col xs={12} className="mb-2">
                        <h5 className="mb-0">{summary}</h5>
                    </Col>
                    <Col xs={12}>
                        <p className="fs-6 mb-0">
                            <a href="#" className="btn-link-style" onClick={handleInvoiceClick}>{invoiceNumber}</a>
                            <span className="text-muted ms-2"> {creditor}</span>
                        </p>
                    </Col>
                </Row>
            </Card.Body>
        </Card >
    );
};


MessageCard.propTypes = {
    'intl': PropTypes.object,
    'id': PropTypes.string,
    'unread': PropTypes.number,
    'status': PropTypes.string,
    'summary': PropTypes.string,
    'invoiceNumber': PropTypes.string,
    'creditor': PropTypes.string,
    'cardIndex': PropTypes.number,
    'activeIndex': PropTypes.number,
    'isUnread': PropTypes.number,
    'statusCode': PropTypes.number,
    'dueDate': PropTypes.string,
    'showAlerts': PropTypes.func,
    'history': PropTypes.object,
};

export default injectIntl(MessageCard);