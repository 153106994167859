import { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { defineMessages, injectIntl } from "react-intl";
import PropTypes from 'prop-types';

// Services
import { userGetLocale } from "../../../services/user/index";
import { changeLoadingText, toggleLoading } from "../../../services/helpers";

const messages = defineMessages({
    redirecting: {
        id: "Sinut ohjataan tunnistuspalveluun",
        defaultMessage: "Sinut ohjataan tunnistuspalveluun"
    },
    inSeconds: {
        id: "sekunnin kuluttua",
        defaultMessage: "sekunnin kuluttua"
    }
});
export { messages };


const Link = (props) => {
    const lng = props.intl.formatMessage;

    /**
     * Log out user from loading and redirect to frontpage
     */
    useEffect(() => {
        let time = 5;

        // Loading animation for redirect
        toggleLoading(true, lng(messages.redirecting) + " " + time + " " + lng(messages.inSeconds) + "...");

        // DPID comes as a parameter from address bar
        if (props.match.params.dpid) {
            // Save to sessionStorage for search
            sessionStorage.setItem('dpid', props.match.params.dpid);

            // 5 sec timer for redirect
            let redirectInterval = setInterval(function () {
                if (time > 1) {
                    time = time - 1;
                    changeLoadingText(lng(messages.redirecting) + " " + time + " " + lng(messages.inSeconds) + "...");

                } else {
                    clearInterval(redirectInterval);
                    // if customertype is 1 (company) and REACT_APP_COMPANY_TOGGLE is ON (1) redirect to company login
                    if (process.env?.REACT_APP_COMPANY_TOGGLE.toString() === "1" && props.match.params.customertype === "1") {
                        window.location.href = process.env.REACT_APP_AUTH_URL + '?language=' + userGetLocale() + '&customertype=1';
                    } else {
                        // redirect to authentication service as private person
                        window.location.href = process.env.REACT_APP_AUTH_URL + '?language=' + userGetLocale();
                    }
                }
            }, 1000);
        }
    }, []);

    return '';

};

Link.propTypes = {
    intl: PropTypes.object.isRequired
};

export default withRouter(injectIntl(Link));