import React, { useEffect, useState } from "react";
import { defineMessages } from "react-intl";
import PropTypes from "prop-types";

const messages = defineMessages({
    inProgress: {
        id: "Käsittelyssä",
        defaultMessage: "Käsittelyssä"
    },
    actionNeeded: {
        id: "Odottaa vastaustasi",
        defaultMessage: "Odottaa vastaustasi"
    },
    resolved: {
        id: "Ratkaistu",
        defaultMessage: "Ratkaistu"
    }
});

export { messages };

const MessageStatus = (props) => {

    const [badgeType, setBadgeType] = useState('');
    const [statusText, setStatusText] = useState('');
    const lng = props.intl.formatMessage;

    useEffect(() => {
        if (props.originalStatus !== undefined && props.originalStatus !== null) {
            switch (props.originalStatus) {
                // Resolved
                case 'Canceled':
                case 'Closed':
                case 'Resolved':
                    setStatusText(lng(messages.resolved));
                    setBadgeType('bg-success');
                    break;
                    
                // Action Needed
                case 'Waiting for customer':
                    setStatusText(lng(messages.actionNeeded));
                    setBadgeType('bg-danger');
                    break;
                    
                // In Progress
                case 'Waiting for support':
                case 'Waiting for 3rd party':
                case 'In Progress':
                case 'Pending':
                case 'Escalate':
                case 'Escalated':
                default:
                    setStatusText(lng(messages.inProgress));
                    setBadgeType('bg-secondary');
                    break;
            }
        } else {
            setStatusText(lng(messages.inProgress));
            setBadgeType('bg-secondary');
        }
    }, [props.originalStatus, lng]);

    return (
        <div className={'badge ' + badgeType}>
            {statusText}
        </div>
    );
};

MessageStatus.propTypes = {
    originalStatus: PropTypes.string,
    intl: PropTypes.object.isRequired
};

export default MessageStatus;