import React, { useEffect, useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';

// Services
import PropTypes from "prop-types";

const Wizard = (props) => {
    
    // Step data
    const [currentStep, setCurrentStep] = useState(0);
    const [displayStep, setDisplayStep] = useState(1);
    const [stepAmount, setStepAmount] = useState(1);
    const [progress, setProgress] = useState(0);
    
    // Content arrays
    const [titles, setTitles] = useState([]);
    const [returnTexts, setReturnTexts] = useState([]);
    const [bodyContents, setBodyContents] = useState([]);
    const [footerContents, setFooterContents] = useState([]);
    const [closeFunctions, setCloseFunctions] = useState([]);
    
    // Handle steps data
    useEffect(() => {
    
        if (props.steps.length > 0) {
    
            // Set the amount of steps
            setStepAmount(props.steps.length);
            
            let titleArray = [];
            let returnTextArray = [];
            let bodyContentArray = [];
            let footerContentArray = [];
            let closeFunctionArray = [];
    
            // Collect all steps data
            for (const step of props.steps) {
                titleArray.push(step.title);
                returnTextArray.push(step.returnText);
                bodyContentArray.push(step.bodyContent);
                footerContentArray.push(step.footerContent);
                closeFunctionArray.push(step.closeFunction);
            }
    
            // Set all steps data
            setTitles(titleArray);
            setReturnTexts(returnTextArray);
            setBodyContents(bodyContentArray);
            setFooterContents(footerContentArray);
            setCloseFunctions(closeFunctionArray);
        }
        
    }, [props.steps]);
    
    // Update step
    useEffect(() => {
        
        // Get the current step
        if (typeof props.currentStep !== 'undefined') {
            setCurrentStep(props.currentStep);
        } else {
            setCurrentStep(0);
        }
    
        // Set the displayable step number and progress percentage
        setDisplayStep(currentStep + 1);
        setProgress(Math.ceil((displayStep / stepAmount) * 100));
        
    }, [props.currentStep, currentStep, displayStep, stepAmount]);
    
    return (
        <>
            {/* Display wizard if current step is equal or bigger than zero */}
            { currentStep >= 0 && 
                (<Modal show={ true } onHide={() => closeFunctions[currentStep]()}>
                    {/* Header */}
                    <Modal.Header closeButton>
                        {/* Closing button */}
                        <Button className='modal-back' onClick={() => closeFunctions[currentStep]()}>
                            <FontAwesomeIcon icon={ far.faArrowLeft } /> { returnTexts[currentStep] }
                        </Button>
                        {/* Title */}
                        <Modal.Title>{ titles[currentStep] }</Modal.Title>
                        {/* Step numbers */}
                        <div className='modal-steps'>
                            { displayStep }<span className={ 'text-lighter' }>/{ stepAmount }</span>
                        </div>
                    </Modal.Header>
                    
                    {/* Body */}
                    <Modal.Body>
                        { bodyContents[currentStep] }
                    </Modal.Body>
                    
                    {/* Footer */}
                    <Modal.Footer>
                        { footerContents[currentStep] }
                    </Modal.Footer>
                    
                    {/* Progress bar */}
                    <div className='wizard-progress-container'>
                        <div
                            className={'wizard-progress ' + (progress > 99 ? 'wizard-progress-full' : '')}
                            style={{width: progress + '%'}}
                        />
                    </div>
                </Modal>)
            }
        </>
    );
};

// Property validation
Wizard.propTypes = {
    currentStep: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired
};

export default Wizard;