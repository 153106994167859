import React, { useState, useEffect } from "react";
import { formatMoney } from "../../../../services/helpers";
import { userGetLocale } from "../../../../services/user/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@fortawesome/pro-regular-svg-icons";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import { Card, Col, Row, Badge } from "react-bootstrap";
import StatusBadge from "../../../../components/StatusBadge";
import PaydateText from "../../../../components/PaydateText/PaydateText";

// Strings for translation in this view
const messages = defineMessages({
    creditor: {
        id: 'Laskuttaja',
        defaultMessage: 'Laskuttaja'
    },
    dueDate: {
        id: 'Eräpäivä',
        defaultMessage: 'Eräpäivä'
    },
    openTotal: {
        id: 'Avoinna',
        defaultMessage: 'Avoinna'
    },
    paidTotal: {
        id: 'Maksettu',
        defaultMessage: 'Maksettu'
    },
    status: {
        id: 'Tila',
        defaultMessage: 'Tila'
    },
    signServing: {
        id: "Allekirjoita tiedoksianto",
        defaultMessage: "Allekirjoita tiedoksianto"
    }
});
export { messages };

const InvoiceCard = (props) => {

    // Translations function
    const lng = props.intl.formatMessage;

    const [creditor, setCreditor] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [openTotal, setOpenTotal] = useState('');
    const [paidTotal, setPaidTotal] = useState('');
    const [currency, setCurrency] = useState('');
    const [statusCode, setStatusCode] = useState(null);
    const [signatureNeeded, setSignatureNeeded] = useState(false);

    // Set the property values if given
    useEffect(() => {
        if (props.creditor !== undefined) {
            setCreditor(props.creditor);
        }
        if (props.dueDate !== undefined) {
            setDueDate(props.dueDate);
        }
        if (props.openTotal !== undefined) {
            setOpenTotal(props.openTotal);
        }
        if (props.paidTotal !== undefined) {
            setPaidTotal(props.paidTotal);
        }
        if (props.currency !== undefined) {
            setCurrency(props.currency);
        }
        if (props.statusCode !== undefined) {
            setStatusCode(props.statusCode);
        }
        if (props.needsignature !== undefined) {
            setSignatureNeeded(true);
        }
    }, [props.creditor, props.dueDate, props.openTotal, props.paidTotal, props.currency, props.statusCode]);

    return (
        <Card className={"invoice" + (signatureNeeded ? " warning" : "")} role="invoiceCard">
            <Card.Body>
                <Row>
                    <Col xs={6} lg={3} className="mb-2 mb-md-0">
                        <p className="text-muted fs-6 mb-0">{lng(messages.creditor)}</p>
                        <h5 className="text-break">{creditor}</h5>
                    </Col>
                    <Col xs={6} lg={3}>
                        <p className="text-muted fs-6 mb-0">{lng(messages.dueDate)}</p>
                        <h5>
                            <PaydateText
                                intl={props.intl}
                                statuscode={props.jobStatus ?? null}
                                paydate={props.dueDate ?? null}
                            />
                        </h5>
                    </Col>
                    <Col xs={6} lg={3}>
                        <p className="text-muted fs-6 mb-0">
                            {openTotal > 0 ? lng(messages.openTotal) : ''} 
                            {openTotal > 0 && paidTotal > 0 ? ' / ': ''} 
                            {paidTotal > 0 ? lng(messages.paidTotal) : ''}
                        </p>
                        <h5>
                            {openTotal > 0 ? formatMoney(openTotal, userGetLocale(), currency) : ''} 
                            {openTotal > 0 && paidTotal > 0 ? ' / ': ''} 
                            {paidTotal > 0 ? formatMoney(paidTotal, userGetLocale(), currency) : ''}
                        </h5>
                    </Col>
                    <Col xs={6} lg={3}>
                        <p className="text-muted fs-6 mb-0">{lng(messages.status)}</p>
                        {signatureNeeded ? (
                            <Badge bg="warning" className="text-wrap">{lng(messages.signServing)}</Badge>
                        ) : (
                            <StatusBadge statusCode={statusCode} dueDate={dueDate} lng={lng} />
                        )}
                    </Col>
                </Row>
            </Card.Body>
            <div className={"invoice-card-icon" + (signatureNeeded ? " warning" : "")}>
                <FontAwesomeIcon icon={far.faChevronRight} />
            </div>
        </Card>
    );
};

InvoiceCard.propTypes = {
    'creditor': PropTypes.string,
    'dueDate': PropTypes.string,
    'openTotal': PropTypes.any,
    'paidTotal': PropTypes.any,
    'currency': PropTypes.string,
    'intl': PropTypes.object.isRequired,
    'statusCode': PropTypes.number,
    'jobStatus': PropTypes.number,
    'needsignature': PropTypes.bool
};

export default injectIntl(InvoiceCard);