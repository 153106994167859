import React, { useEffect, useState } from 'react';
import { injectIntl, defineMessages } from "react-intl";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import PropTypes from 'prop-types';

import AlertDialog from '../../../components/AlertDialog';
import FeedbackDialog from '../../../components/FeedbackDialog';

// Services
import { userLoggedin, userLogout, userRequestHeaders } from '../../../services/user';
import { toggleLoading } from '../../../services/helpers';
import { jobFetchJob } from '../../../services/job';

// views
import CreditorInfoCard from '../CreditorInfoCard';
import DeliveryMethod from '../DeliveryMethod';
import CodeVerification from '../CodeVerification';
import SubscriptionSummaryCard from '../SubscriptionSummaryCard';

// strings for translation in this view
const messages = defineMessages({
    authError: {
        id: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen."
    },
    forbiddenError: {
        id: "Haku estetty.",
        defaultMessage: "Haku estetty."
    },
    searchLocked: {
        id: "Liian monta virheellistä hakua. Turvallisuussyistä haku on lukittu 30 minuutiksi.",
        defaultMessage: "Liian monta virheellistä hakua. Turvallisuussyistä haku on lukittu 30 minuutiksi."
    },
    notAllowedToSign: {
        id: "Ei nimenkirjoitusoikeutta.",
        defaultMessage: "Ei nimenkirjoitusoikeutta."
    },
    searchingForJob: {
        id: "Etsitään laskua...",
        defaultMessage: "Etsitään laskua..."
    },
    pleaseTryAgain: {
        id: "Tapahtui virhe. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tapahtui virhe. Ole hyvä ja yritä uudelleen."
    },
    actionLogout: {
        id: "Kirjaudu ulos",
        defaultMessage: "Kirjaudu ulos"
    },
    tryAgain: {
        id: "Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Ole hyvä ja yritä uudelleen."
    },
    logoutMessage: {
        id: "Haluatko varmasti kirjautua ulos?",
        defaultMessage: "Haluatko varmasti kirjautua ulos?"
    },
    orderWebInvoice: {
        id: "Weblaskun tilaaminen",
        defaultMessage: "Weblaskun tilaaminen"
    }
});

export {messages};


const SubscriptionPage = (props) => {
    // translations function
    const lng = props.intl.formatMessage;
    // User service
    const isAuthenticated = userLoggedin();

    const [job, setJob] = useState();
    const [showCreditorInfo, setShowCreditorInfo] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState();
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [deliveryDetails, setDeliveryDetails] = useState({});
    const [subscriptionSummary, setSubscriptionSummary] = useState({});
    const [toggleFeedbackDialog, setToggleFeedbackDialog] = useState(false);

    // step 1
    const [showDeliveryMethod, setShowDeliveryMethod] = useState(false);
    // step 2
    const [showCodeVerification, setShowCodeVerification] = useState(false);
    // step 3 / success summary
    const [showSubscriptionSuccess, setShowSubscriptionSuccess] = useState(false);
    
    let feedBackToggle = false;
    
    // Is customer feedback on or off
    if (typeof process.env.REACT_APP_FEEDBACK_TOGGLE !== 'undefined' && process.env.REACT_APP_FEEDBACK_TOGGLE.toString() === '1') {
        feedBackToggle = true;
    }

    useEffect(() => {
        // loading animation on
        toggleLoading(true, lng(messages.searchingForJob));
        // fetch dpid from sessionStorage
        let dpid = sessionStorage.getItem('dpid');
        // if user is not logged in, redirect back to web invoice subscription landing page for authentication
        if (!isAuthenticated) {
            props.history.push("/subs/" + dpid);
            // loading animation off
            toggleLoading(false);
        } else {
            // fetch job
            jobFetchJob(dpid)
                .then(function (response) {
                    // set job
                    setJob(response);
                    // check if subscription exists or not
                    getSubscription();
                }).catch(function (error) {
                    // authentication failed
                    if (error.response.status === 401) {
                        // log out from current session and redirect back to subscription login for authentication
                        userLogout();
                        // loading animation off
                        toggleLoading(false);

                        // show error message to user
                        const alert = { message: lng(messages.authError), type: 'danger' };
                        props.history.replace({
                            pathname: '/subs/' + dpid,
                            alert
                        });
                    }
                    // Forbidden messages
                    else if (error.response.status === 403) {
                        let message = lng(messages.forbiddenError) + " " + lng(messages.tryAgain);

                        // if user has too many failed search attempts and tries to log in to create a subscription, or is a company with no rights to sign
                        if (error.response.data.Message === 'Online access log - user access denied') {
                            message = lng(messages.searchLocked) + " " + lng(messages.tryAgain);
                        }
                        else if (error.response.data.Message === 'Online jobsearch - company person is not allowed to sign') {
                            message = lng(messages.notAllowedToSign) + " " + lng(messages.tryAgain);
                        }
                        // loading animation off, hide creditorInfoCard
                        toggleLoading(false);
                        setShowCreditorInfo(false);

                        // set error messge and show it
                        setErrorMessage(message);
                        setShowError(true);
                    }
                    // job is not found or request failed completely
                    else {
                        // loading animation off, hide creditorInfoCard
                        toggleLoading(false);
                        setShowCreditorInfo(false);

                        // set error messge and show it
                        setErrorMessage(lng(messages.pleaseTryAgain));
                        setShowError(true);
                    }
                })
        }

    }, []);


    // check if subscription exists
    function getSubscription() {
        const headers = userRequestHeaders();
        let dpid = sessionStorage.getItem('dpid');

        // get subscriptions
        axios({
            method: "GET",
            url: process.env.REACT_APP_API_URL + "/online/subscription/" + dpid,
            headers: headers
        })
        .then(function (response) {
            // a match was found, save subscription values
            setSubscriptionSummary({ subscriptionDate: response.data.subscription_date, subscriptionDeliveryMethod: response.data.delivery_method, subscriptionDeliveryMethodValue: response.data.delivery_method_value });
            // update creditor info and show it
            setSubscriptionStatus(true);
            setShowCreditorInfo(true);
            // hide step 3
            setShowCodeVerification(false);
            // show subscription summary
            setShowSubscriptionSuccess(true);

        })
        .catch(function (error) {
            // loading animation off
            toggleLoading(false);
            
            // authentication failed
            if (error.response?.status === 401) {
                // log out from current session and redirect back to subscription login for authentication
                userLogout();
                // show error message to user
                const alert = { message: lng(messages.authError), type: 'danger' };
                props.history.replace({
                    pathname: '/subs/' + dpid,
                    alert
                });
            } else if (error.response.status === 404) {
                // no match found, subscription process proceeds normally
                setSubscriptionStatus(false);
                // show creditor info and step 1, delivery method
                setShowCreditorInfo(true);
                setShowDeliveryMethod(true);
            } else {
                // request failed, show log out button and disable steps
                setShowCreditorInfo(false);
                setErrorMessage(lng(messages.pleaseTryAgain));
                setShowError(true); // error
                setShowDeliveryMethod(true);
            }
        });
    }

    // toggle visibility of steps
    function changeStep() {
        // hide/show step 1
        setShowDeliveryMethod(showDeliveryMethod ? false : true);
        // hide/show step 2
        setShowCodeVerification(showCodeVerification ? false : true);
    }

    function showSuccess() {
        // fetch subscription details
        getSubscription();
    }

    // save deliverydetails to parent also
    function handleDeliveryChoices(method, value, uuid) {
        // save phone number and delivery details
        setDeliveryDetails({ deliveryMethod: method, deliveryMethodValue: value, verificationUuid: uuid });
    }
    
    /**
     * Log out user and close and hide chat
     */
    function confirmLogout() {
        props.history.push({
            pathname: "/logout",
        });
        // Close and hide chat when user logs out
        if (window.LC_API) {
            window.LC_API.close_chat();
            window.LC_API.hide_chat_window();
        }
    }
    
    /**
     * Open feedback dialog when logging out.
     * If feedback dialogis not enabled call confirmLogout function and log out
     */
    function handleLogOut() {
        if (feedBackToggle) {
            toggleFeedbackDialog ? setToggleFeedbackDialog(false) : setToggleFeedbackDialog(true);
        } else {
            confirmLogout();
        }
    }
    
    return (
        <>
            {isAuthenticated && (
                <div id="subscription-page">
                    <div className="content">
                        <Helmet>
                            <title>{lng(messages.orderWebInvoice)} | MyRopo </title>
                        </Helmet>
                        <Container>
                            <Row >
                                <Col md={12}>
                                    <Card className='card-borderless'>
                                        <Card.Body>
                                            <h1>{lng(messages.orderWebInvoice)}</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row id="subscription-all-details-row" className="show-grid">
                                {/* show creditor information column, or error column */}
                                {!showError ? (<>
                                    <Col className="creditor-information-col" md={6} sm={12} >
                                        <CreditorInfoCard job={job} showCreditorInfo={showCreditorInfo} subscriptionStatus={subscriptionStatus} {...props} />
                                    </Col>
                                </>) : (<>
                                    <Col md={6} id="fetchjob-error-col">
                                        <Card className='card-borderless'>
                                            <Card.Body>
                                                <AlertDialog message={errorMessage} type={'danger'}/>
                                                <button className="btn btn-primary" onClick={handleLogOut}>
                                                    {lng(messages.actionLogout)}
                                                </button>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </>)}
                                <hr className="horizontal-line-mobile d-block d-md-none" />
                                {/* subscription steps */}
                                <Col className="subscription-steps" md={6} sm={12} >

                                    <div id="subscription-steps-div" className={showError ? "section-disabled" : ""} >
                                        <DeliveryMethod showDeliveryMethod={showDeliveryMethod} job={job} showError={showError} changeStep={changeStep} handleDeliveryChoices={handleDeliveryChoices} {...props} />
                                        <CodeVerification showCodeVerification={showCodeVerification} job={job} deliveryDetails={deliveryDetails} changeStep={changeStep} showSuccess={showSuccess} {...props} />
                                        <SubscriptionSummaryCard showSubscriptionSuccess={showSubscriptionSuccess} subscriptionSummary={subscriptionSummary} handleLogOut={handleLogOut} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <FeedbackDialog title={lng(messages.actionLogout)} show={toggleFeedbackDialog} handleConfirmDialog={handleLogOut} {...props} />
                </div>
            )}
        </>
    );
};

SubscriptionPage.propTypes = {
    intl: PropTypes.object.isRequired,
    history: PropTypes.any
};


export default injectIntl(SubscriptionPage);
