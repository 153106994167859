import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { defineMessages, injectIntl } from "react-intl";
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { Container, Col, Row, Button, Dropdown, DropdownMenu, DropdownItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';

// Services
import { userGetLocale } from "../../../services/user/index";
import { mapLocaleToBCP47 } from "../../../services/helpers";

// components
import AlertDialog from "../../../components/AlertDialog";
import LocaleSelector from "../../../components/LocaleSelector";

// Paragraphs to be translated
const messages = defineMessages({
    welcomeMessage: {
        id: "Tervetuloa MyRopoon!",
        defaultMessage: "Tervetuloa MyRopoon!"
    },
    serviceDescription: {
        id: "MyRopossa voit hoitaa laskuihin ja myöhästyneisiin maksuihin liittyviä asioita helposti ja turvallisesti sähköisen tunnistautumisen avulla.",
        defaultMessage: "MyRopossa voit hoitaa laskuihin ja myöhästyneisiin maksuihin liittyviä asioita helposti ja turvallisesti sähköisen tunnistautumisen avulla."
    },
    loginperson: {
        id: "Kirjaudu henkilöasiakkaana",
        defaultMessage: "Kirjaudu henkilöasiakkaana"
    },
    logincompany: {
        id: "Kirjaudu yritysasiakkaana",
        defaultMessage: "Kirjaudu yritysasiakkaana"
    },
    login: {
        id: "Kirjaudu MyRopo-palveluun",
        defaultMessage: "Kirjaudu MyRopo-palveluun"
    },
    fin: {
        id: "Suomi",
        defaultMessage: "Suomi"
    },
    swe: {
        id: "Svenska",
        defaultMessage: "Svenska"
    },
    eng: {
        id: "English",
        defaultMessage: "English"
    },
    nor: {
        id: "Norsk",
        defaultMessage: "Norsk"
    }
});

export { messages };

const Login = (props) => {

    const lng = props.intl.formatMessage;
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessge, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('light');
    const [showDropdown, setShowDropdown] = useState(false);
    const [dropdownLanguage, setDropdownLanguage] = useState('');


    useEffect(() => {
        // make sure that chat is not visible in the login page
        removeLivechat();

        // check if alert is passed in location props 
        if (props.location?.alert) {
            setAlertMessage(props.location.alert.message);
            setAlertType(props.location.alert.type);
            setShowAlert(true);
        }

        // do not show topmenu when user is on login page
        props.toggleTopMenuVisibility(false);

        // set the selected language for dropdown
        switch (userGetLocale()) {
            case 'fi':
                setDropdownLanguage(lng(messages.fin));
                break;

            case 'se':
                setDropdownLanguage(lng(messages.swe));
                break;

            case 'no':
                setDropdownLanguage(lng(messages.nor));
                break;

            case 'en':
            default:
                setDropdownLanguage(lng(messages.eng));
                break;
        }

    }, []);


    /**
     * Remove livechat script and the chat widget container,
     * because chat should not be usable in login page.
     */
    function removeLivechat() {

        // remove chat script
        const scripts = document.getElementsByTagName('script');
        const url = ('https:' === document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';

        if (scripts[0]?.src !== undefined && (scripts[0]?.src).includes(url)) {
            document.head.removeChild(scripts[0]);
        }

        // remove the whole chat widget container
        if (document.getElementById('chat-widget-container')) {
            document.getElementById('chat-widget-container').remove();
        }
    }

    /**
     * Switch the language and reload the page.
     * @param {*} locale 
     */
    function switchLang(locale) {
        localStorage.setItem('locale', locale);
        window.location.reload();
    }

    return (
        <Container id="login" className="login viewport" fluid>
            <Helmet>
                <title>{lng(messages.login)} | MyRopo</title>
            </Helmet>
            <Row className="login-header" >
                <Col xs={12} className="d-none d-lg-block">
                    <LocaleSelector
                        colClassnames={"d-inline-flex"}
                        optionClassnames={"login-language btn btn-login-language"}
                        selected={userGetLocale()}
                        {...props} />
                </Col>
                {/* Language selection as dropdown in mobile */}
                <Col xs={12} className="d-lg-none">
                    <Dropdown show={showDropdown} className="login-language language-dropdown">
                        <Button onClick={() => setShowDropdown(!showDropdown)} className="login-language btn-login-language">
                            {showDropdown ? (<FontAwesomeIcon icon={far.faChevronUp} />) : (<FontAwesomeIcon icon={far.faChevronDown} />)} &nbsp; {dropdownLanguage}
                        </Button>
                        <DropdownMenu className={"login-language"}>
                            {userGetLocale() === 'fi' ? '' : (<DropdownItem className="login-language btn btn-language-dropdown" onClick={() => switchLang('fi')}>{lng(messages.fin)}</DropdownItem>)}
                            {userGetLocale() === 'se' ? '' : (<DropdownItem className="login-language btn btn-language-dropdown" onClick={() => switchLang('se')}>{lng(messages.swe)}</DropdownItem>)}
                            {userGetLocale() === 'en' ? '' : (<DropdownItem className="login-language btn btn-language-dropdown" onClick={() => switchLang('en')}>{lng(messages.eng)}</DropdownItem>)}
                            {userGetLocale() === 'no' ? '' : (<DropdownItem className="login-language btn btn-language-dropdown" onClick={() => switchLang('no')}>{lng(messages.nor)}</DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>
                </Col>
            </Row>
            <Row className="login-content">
                <Col xs={{ span: 12, offset: 0 }} lg={{ span: 4, offset: 8 }} className="">
                    <div className="mb-4">
                        <svg width="251" height="66" viewBox="0 0 251 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M133.982 37.9919C133.982 30.0039 129.27 26.6918 122.266 27.601C122.266 27.601 136.011 25.7826 136.011 14.0927C136.011 2.40291 130.121 0 117.226 0H97.3286V50.9157H103.612V37.8621C103.612 36.823 104.07 35.9138 104.79 35.1994C107.474 32.7965 111.99 30.4585 117.03 29.0947C123.575 27.3412 127.306 29.8091 127.306 37.4724C127.306 45.1357 128.746 50.2663 129.139 50.8508H135.88C135.684 50.3312 135.488 49.6168 135.226 48.7076C134.964 47.7335 133.851 44.1616 133.851 37.927L133.982 37.9919ZM115.132 24.2239C111.008 25.328 106.885 26.6268 103.743 29.1596V5.39031H116.964C119.91 5.39031 129.531 4.28627 129.531 12.7939C129.531 21.3015 126.324 20.4572 122.004 22.0808C119.91 22.8601 117.554 23.5095 115.132 24.159V24.2239Z" fill="white"/>
                            <path d="M195.181 13.7679C185.952 13.7679 182.679 20.7169 182.614 20.8468V14.807H176.265V63.5147H182.614V44.8759C183.203 45.85 186.148 51.9547 195.181 51.9547C204.213 51.9547 211.675 45.0707 211.675 32.9263C211.675 20.7818 203.951 13.8329 195.181 13.8329V13.7679ZM193.479 46.2397C184.119 46.2397 182.221 37.0177 182.221 32.8613C182.221 28.705 184.119 19.418 194.068 19.418C204.017 19.418 204.868 25.9124 204.868 32.8613C204.868 39.8103 200.09 46.2397 193.479 46.2397Z" fill="white"/>
                            <path d="M151.262 14.0278C141.444 16.041 135.16 26.1073 137.189 36.4333C139.218 46.8242 148.905 53.5784 158.723 51.5002C168.541 49.4869 174.825 39.4207 172.796 29.0947C170.767 18.7037 161.08 11.9496 151.262 14.0278ZM157.938 47.5386C151.916 48.7726 145.698 43.1874 144.062 35.0045C142.425 26.8216 146.025 19.2233 152.047 17.9893C158.069 16.7554 164.287 22.3405 165.923 30.5234C167.559 38.7063 163.959 46.3047 157.938 47.5386Z" fill="white"/>
                            <path d="M229.085 14.0927C219.267 16.106 212.984 26.1722 215.013 36.4983C217.042 46.8892 226.729 53.6433 236.547 51.5652C246.365 49.5519 252.648 39.4857 250.619 29.1596C248.59 18.8336 238.903 12.0146 229.085 14.0927ZM235.762 47.6685C229.74 48.9025 223.522 43.3173 221.886 35.1344C220.249 26.9516 223.849 19.3532 229.871 18.1192C235.892 16.8853 242.11 22.4705 243.747 30.6533C245.383 38.8362 241.783 46.4346 235.762 47.6685Z" fill="white"/>
                            <g opacity="0.5">
                            <path d="M26.3121 43.707L10.8652 0.259766H0V50.9806H7.00346L6.61075 8.05299L22.3849 50.9806H30.0429L45.948 8.05299L45.4898 50.9806H52.6242V0.259766H41.6935L26.3121 43.707Z" fill="white"/>
                            <path d="M75.5982 42.4081L63.4894 14.8721H56.1587L71.8019 50.0715L70.2965 53.7083C67.3511 59.9429 62.6385 60.7871 58.2532 59.6831V65.4631C66.7621 67.5413 73.3728 63.5797 77.4963 53.7083L93.8596 14.8721H86.8561L75.6637 42.4081H75.5982Z" fill="white"/>
                            </g>
                        </svg>
                    </div>
                    <div className="login-text mb-4">
                        <p>
                            <b>
                                {lng(messages.welcomeMessage)}
                            </b>
                        </p>
                        <p>
                            {lng(messages.serviceDescription)}
                        </p>
                    </div>
                    {showAlert && (
                        <AlertDialog
                            message={alertMessge}
                            type={alertType}
                        />
                    )}
                    <div className="d-grid gap-3">
                        {typeof process.env.REACT_APP_COMPANY_TOGGLE !== 'undefined' && process.env.REACT_APP_COMPANY_TOGGLE.toString() === '1' ? (
                            <>
                                <Button className="login-button" variant="primary" href={process.env.REACT_APP_AUTH_URL + '?language=' + mapLocaleToBCP47(userGetLocale())}>
                                    <span className="login-button-icon">
                                        <FontAwesomeIcon icon={far.faUser} />
                                    </span>
                                    <span id="loginperson" className=" login-button-desc" >
                                        {lng(messages.loginperson)}
                                    </span>
                                </Button>
                                <Button id="logincompany" className="login-button" variant="primary" href={process.env.REACT_APP_AUTH_URL + '?language=' + mapLocaleToBCP47(userGetLocale()) + '&customertype=1'}>
                                    <span className="login-button-icon">
                                        <FontAwesomeIcon icon={far.faUsers} />
                                    </span>
                                    <span className="login-button-desc">
                                        {lng(messages.logincompany)}
                                    </span>
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button id="logindefault" className="login-button" variant="primary" href={process.env.REACT_APP_AUTH_URL + '?language=' + mapLocaleToBCP47(userGetLocale())}>
                                    <span className="login-button-icon">
                                        <FontAwesomeIcon icon={far.faUser} />
                                    </span>
                                    <span id="loginperson" className="login-button-desc">
                                        {lng(messages.login)}
                                    </span>
                                </Button>
                            </>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

Login.propTypes = {
    'intl': PropTypes.object.isRequired,
    'location': PropTypes.object,
    'toggleTopMenuVisibility': PropTypes.func.isRequired
};

export default withRouter(injectIntl(Login));