import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Nav, Row, Tab } from "react-bootstrap";
import axios from "axios";
import get from "lodash.get";
import { injectIntl, defineMessages } from "react-intl";
import PropTypes from 'prop-types';
import { isEmpty } from "lodash";
import { useDebouncedCallback } from "use-debounce";

// Services
import { CUSTOMERTYPE, userGetCustomerType, userGetLocale, userRequestHeaders, userLogin, userLoggedin } from '../../services/user';
import { toggleLoading, formatMoney, validate } from "../../services/helpers";
import { jobFetchJob, jobGetJob, jobSetGovidJob } from "../../services/job";

// Components
import Notification from '../../components/Notification';
import InvoiceCard from "./components/InvoiceCard";
import InvoiceCardSkeleton from "./components/InvoiceCardSkeleton";
import AlertDialog from "../../components/AlertDialog";
import RetrieveInvoiceModal from "./components/RetrieveInvoice/RetrieveInvoiceModal";
import Wizard from "../../components/Wizard";
import ReturnPaymentStep1 from "./components/Wizards/ReturnPaymentWizard/ReturnPaymentStep1";
import ReturnPaymentStep2 from "./components/Wizards/ReturnPaymentWizard/ReturnPaymentStep2";
import ReturnPaymentStep3 from "./components/Wizards/ReturnPaymentWizard/ReturnPaymentStep3";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

// Strings for translation in this view
const messages = defineMessages({
    welcome: {
        id: 'Tervetuloa!',
        defaultMessage: 'Tervetuloa!'
    },
    retrieveInvoiceTitle: {
        id: 'Hae lasku',
        defaultMessage: 'Hae lasku'
    },
    retrieveInvoiceDescription: {
        id: 'Oletko saanut laskun, joka ei näy listauksessa? Syynä voi olla laskun puuttuvat tunnistetiedot. Voit hakea laskun antamalla sen tiedot tai MyRopo-tunnuksen.',
        defaultMessage: 'Oletko saanut laskun, joka ei näy listauksessa? Syynä voi olla laskun puuttuvat tunnistetiedot. Voit hakea laskun antamalla sen tiedot tai MyRopo-tunnuksen.'
    },
    returnPaymentTitle: {
        id: 'Ilmoita tilinumero',
        defaultMessage: 'Ilmoita tilinumero'
    },
    returnPaymentDescription: {
        id: 'Odotatko maksunpalautusta laskusta tai liikamaksusta? Voit ilmoittaa tilinumerosi MyRopo-tunnuksella, jonka löydät lähettämästämme kirjeestä.',
        defaultMessage: 'Odotatko maksunpalautusta laskusta tai liikamaksusta? Voit ilmoittaa tilinumerosi MyRopo-tunnuksella, jonka löydät lähettämästämme kirjeestä.'
    },
    tabInvoicesTitle: {
        id: 'Laskut',
        defaultMessage: 'Laskut'
    },
    tabArchiveTitle: {
        id: 'Arkisto',
        defaultMessage: 'Arkisto'
    },
    searchFieldPlaceholder: {
        id: 'Etsi laskua listauksesta',
        defaultMessage: 'Etsi laskua listauksesta'
    },
    nextStep: {
        id: 'Seuraava vaihe',
        defaultMessage: 'Seuraava vaihe'
    },
    send: {
        id: 'Lähetä',
        defaultMessage: 'Lähetä'
    },
    goBack: {
        id: 'Palaa takaisin',
        defaultMessage: 'Palaa takaisin'
    },
    paymentReturnDetailsTitle: {
        id: 'Maksunpalautuksen tiedot',
        defaultMessage: 'Maksunpalautuksen tiedot'
    },
    paymentReturnDetailsDescription: {
        id: 'Alla maksunpalautustasi koskevat tiedot.',
        defaultMessage: 'Alla maksunpalautustasi koskevat tiedot.'
    },
    bankAccountDetailsTitle: {
        id: 'Pankkitilin tiedot',
        defaultMessage: 'Pankkitilin tiedot'
    },
    bankAccountDetailsDescription: {
        id: 'Ole hyvä ja anna pankkitilisi tiedot maksunpalautusta varten.',
        defaultMessage: 'Ole hyvä ja anna pankkitilisi tiedot maksunpalautusta varten.'
    },
    bankAccountHolderName: {
        id: 'Tilinumeron haltijan nimi',
        defaultMessage: 'Tilinumeron haltijan nimi'
    },
    accountType: {
        id: 'Tilityyppi',
        defaultMessage: 'Tilityyppi'
    },
    authError: {
        id: 'Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.',
        defaultMessage: 'Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.'
    },
    govidSearchError: {
        id: 'Tapahtui virhe. Haku epäonnistui.',
        defaultMessage: 'Tapahtui virhe. Haku epäonnistui.'
    },
    noOpen: {
        id: 'Henkilötiedoillasi ei löytynyt avoimia laskuja.',
        defaultMessage: 'Henkilötiedoillasi ei löytynyt avoimia laskuja.'
    },
    noOpenCompany: {
        id: 'Y-tunnuksella ei löytynyt avoimia laskuja.',
        defaultMessage: 'Y-tunnuksella ei löytynyt avoimia laskuja.'
    },
    noArchivedInvoices: {
        id: 'Arkistoituja laskuja ei löytynyt',
        defaultMessage: 'Arkistoituja laskuja ei löytynyt'
    },
    searching: {
        id: 'Haetaan...',
        defaultMessage: 'Haetaan...'
    },
    pleaseTryAgain: {
        id: "Tapahtui virhe. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tapahtui virhe. Ole hyvä ja yritä uudelleen."
    },
    jobNotFound: {
        id: "Virheelliset hakutiedot. Haku lukitaan viiden virheellisen haun jälkeen.",
        defaultMessage: "Virheelliset hakutiedot. Haku lukitaan viiden virheellisen haun jälkeen."
    },
    notAllowedToSign: {
        id: "Ei nimenkirjoitusoikeutta.",
        defaultMessage: "Ei nimenkirjoitusoikeutta."
    },
    searchLocked: {
        id: "Liian monta virheellistä hakua. Turvallisuussyistä haku on lukittu 30 minuutiksi.",
        defaultMessage: "Liian monta virheellistä hakua. Turvallisuussyistä haku on lukittu 30 minuutiksi."
    },
    forbiddenError: {
        id: "Haku estetty.",
        defaultMessage: "Haku estetty."
    },
	searchTitle: {
		id: "Hae laskua tai ilmoita tilinumero",
		defaultMessage: "Hae laskua tai ilmoita tilinumero"
	},
    unknownError: {
        id: "Jotain meni vikaan, ole hyvä ja yritä uudelleen.",
        defaultMessage: "Jotain meni vikaan, ole hyvä ja yritä uudelleen."
    },
    caseNumber: {
        id: "Asianumero",
        defaultMessage: "Asianumero"
    },
    onlineId: {
        id: "MyRopo-tunnus",
        defaultMessage: "MyRopo-tunnus"
    },
    reference: {
        id: "Maksuviite",
        defaultMessage: "Maksuviite"
    },
    creditor: {
        id: "Velkoja",
        defaultMessage: "Velkoja"
    },
    returnAmount: {
        id: "Palautettava määrä",
        defaultMessage: "Palautettava määrä"
    },
    refundProcessingFee: {
        id: "Maksun palautuksen käsittelykulu",
        defaultMessage: "Maksun palautuksen käsittelykulu"
    },
    accountNumber: {
        id: "Tilinumero",
        defaultMessage: "Tilinumero"
    },
    accountNumberInclClearing: {
        id: "Tilinumero sisältäen clearing-numeron",
        defaultMessage: "Tilinumero sisältäen clearing-numeron"
    },
    bic: {
        id: "BIC",
        defaultMessage: "BIC"
    },
    defaultErrorMessage: {
        id: "Tapahtui virhe. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tapahtui virhe. Ole hyvä ja yritä uudelleen."
    },
    actionSaving: {
        id: "Tallennetaan tietoja...",
        defaultMessage: "Tallennetaan tietoja..."
    },
    iban: {
        id: "IBAN",
        defaultMessage: "IBAN"
    },
    bban: {
        id: "BBAN (Tilinumero)",
        defaultMessage: "BBAN (Tilinumero)"
    },
    bankgiro: {
        id: "BankGiro",
        defaultMessage: "BankGiro"
    },
    plusgirot: {
        id: "PlusGirot",
        defaultMessage: "PlusGirot"
    },
    bicCodeInvalid: {
        id: "Virheellinen BIC",
        defaultMessage: "Virheellinen BIC"
    },
    invalidIbanFormat: {
        id: "Tilinumero ei ole IBAN-muodossa.",
        defaultMessage: "Tilinumero ei ole IBAN-muodossa."
    },
    invalidBankgiroFormat: {
        id: "Virheellinen Bankgiro-tilinumero.",
        defaultMessage: "Virheellinen Bankgiro-tilinumero."
    },
    invalidPlusgirotFormat: {
        id: "Virheellinen Plusgirot-tilinumero.",
        defaultMessage: "Virheellinen Plusgirot-tilinumero."
    },
    invalidBbanFormat: {
        id: "Virheellinen Bban-tilinumero.",
        defaultMessage: "Virheellinen Bban-tilinumero."
    },
    bicFromIbanFail: {
        id: "BIC-koodia ei voi saada ibanista.",
        defaultMessage: "BIC-koodia ei voi saada ibanista."
    },
    overpaidRefundInProgress: {
        id: "Maksutietojen vastaanottaminen käynnissä, ole hyvä ja odota.",
        defaultMessage: "Maksutietojen vastaanottaminen käynnissä, ole hyvä ja odota."
    },
    overpaidInformation1: {
        id: "Olemme vastaanottaneet ilmoittamasi tilinumeron",
        defaultMessage: "Olemme vastaanottaneet ilmoittamasi tilinumeron"
    },
    overpaidInformation2: {
        id: "nimellä",
        defaultMessage: "nimellä"
    },
    searchingForJob: {
        id: "Etsitään laskua...",
        defaultMessage: "Etsitään laskua..."
    },
    openingInvoice: {
        id: "Avataan laskua",
        defaultMessage: "Avataan laskua"
    },
    searchResults: {
        id: "Hakutulokset",
        defaultMessage: "Hakutulokset"
    },
    noSearchResults: {
        id: "Listauksesta ei löytynyt yhtään laskua annetulla hakuparametrillä (laskuttajan nimi, laskun numero, laskun viite tai asianumero).",
        defaultMessage: "Listauksesta ei löytynyt yhtään laskua annetulla hakuparametrillä (laskuttajan nimi, laskun numero, laskun viite tai asianumero)."
    }
});
export { messages };

const Dashboard = (props) => {

    const [showRetrieveModal, setShowRetrieveModal] = useState(false);
    const [retrieveInvoiceError, setRetrieveInvoiceError] = useState({
        message: '',
        type: 'light'
    });
    const [dashboardMessage, setDashboardMessage] = useState({
        message: '',
        type: 'light'
    });
    const [returnPaymentError, setReturnPaymentError] = useState({
        message: '',
        type: 'light'
    });
    const [govidJobs, setGovidJobs] = useState(false);
    const [currentTab, setCurrentTab] = useState("invoices");
    const [searchText, setSearchText] = useState("");
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [callback, setCallback] = useState(false);
    const [state, setState] = useState({
        jobid: 0,
        code: "",
        billnum: "",
        reference: "",
        postcode: "",
        jobidForAccountNumber: 0,
        codeForAccountNumber: "",
    });
    const [returnPaymentStep, setReturnPaymentStep] = useState(-1);
    const [overpaidInformation, setOverpaidInformation] = useState({
        show: false,
        account: '',
        amount: '',
        name: '',
        fee: ''
    });
    const [govidJobsResult, setGovidJobsResult] = useState(false);

    // Govid search with debounced timeout
    const debounced = useDebouncedCallback((value) => {
        // When input has over 3 letters, init search
        if (value.length > 3) {
            setCurrentTab("search");
            getOpenInvoicesByGovid('search', value);
        }

        // When input is empty restore tabs
        if (value.length === 0) {
            setCurrentTab("invoices");
            getOpenInvoicesByGovid('invoices');
        }
    }, props.timeout ?? 1000);

    let allowServing = false;

	// check if serving is allowed in the environment
	if (typeof process.env.REACT_APP_SHOW_SERVING !== 'undefined' && process.env.REACT_APP_SHOW_SERVING.toString() === '1') {
        allowServing = true;
    }

    // --- RETURN PAYMENT WIZARD FUNCTIONS ---
    /**
     * Function for closing the Return payment wizard
     * (Set the current step to -1)
     */
    const returnPaymentClose = () => {
        resetRetrieveInvoiceError();
        resetReturnPaymentError();
        setReturnPaymentStep(-1);
    };

    /**
     * Function for moving to next step in Return payment wizard
     */
    const returnPaymentMoveToNextStep = () => {
        resetOverpaidInformation();
        resetDashboardMessage();
        resetReturnPaymentError();
        setReturnPaymentStep(returnPaymentStep + 1);
    };

    /**
     * Return payment wizard step 1
     */
    const returnPaymentStepOne = () => {

        // Reset errors and other notifications
        resetRetrieveInvoiceError();
        resetDashboardMessage();
        resetOverpaidInformation();

        // Get the form
        const form = document.getElementById('returnpayment-dpid-form');
        if (typeof form !== 'undefined' && form !== null) {

            // Validate form data
            if (validate(form)) {
                // Get the case number and Online ID and form the dpid
                const caseNumber = document.getElementById('returnpayment-case-number')?.value ?? '';
                const onlineId = document.getElementById('returnpayment-online-id')?.value ?? '';
                if (caseNumber !== '' && onlineId !== '') {

                    // Fetch the job data and move to next step
                    const query = caseNumber + '-' + onlineId;
                    handleSubmit(query);
                } else {
                    setRetrieveInvoiceError({
                        message: lng(messages.unknownError),
                        type: 'danger'
                    });
                }
            }
        } else {
            setRetrieveInvoiceError({
                message: lng(messages.unknownError),
                type: 'danger'
            });
        }
    };

    /**
     * Return payment wizard step 3
     */
    const returnPaymentStepThree = () => {

        // Reset errors and other notifications
        resetRetrieveInvoiceError();
        resetDashboardMessage();
        resetOverpaidInformation();

        // Get the form
        const form = document.getElementById('returnpayment-accountdata-form');
        if (typeof form !== 'undefined' && form !== null) {

            // Validate form data
            if (validate(form)) {
                // Get the data for request
                const dpid = document.getElementById('returnpayment-dpid')?.value ?? '';
                const iban = document.getElementById('returnpayment-account-number')?.value ?? '';
                const name = document.getElementById('returnpayment-account-holder-name')?.value ?? '';
                const type = document.getElementById('returnpayment-account-type')?.value ?? '';
                const bic = document.getElementById('returnpayment-account-bic')?.value ?? '';

                if (dpid !== '' && iban !== '' && name !== '' && type !== '') {
                    let overpaidData = {
                        id: dpid,
                        iban: iban,
                        name: name,
                        type: type,
                        user: {
                            name: '',
                            personid: ''
                        }
                    };

                    if (bic !== '') {
                        overpaidData['bic'] = bic;
                    }

                    submitOverpaid(overpaidData);
                } else {
                    // Error required data was not found
                    setReturnPaymentError({
                        message: lng(messages.unknownError),
                        type: 'danger'
                    });
                }
            }
        } else {
            // Error: Form was not found
            setReturnPaymentError({
                message: lng(messages.unknownError),
                type: 'danger'
            });
        }
    };

    // Translations function
    const lng = props.intl.formatMessage;


    // Get open invoices by user govid
    async function getOpenInvoicesByGovid(eventType = 'invoices', searchTerm = '') {
        setCurrentTab(eventType);
        setGovidJobs(false);

        // Get header information that might contain authorization details
        const headers = userRequestHeaders();

        let _govidJobs = false;
        let queryParams = '';

        switch (eventType) {
            case "invoices":
                queryParams = '?include_paid=0&exclude_open=0&order=paydate&dir=DESC';
                break;
            case "archive":
                queryParams = '?include_paid=1&exclude_open=1&order=paydate&dir=DESC';
                break;
            case "search":
                queryParams = '?include_paid=1&exclude_open=0&order=paydate&dir=DESC&search=' + searchTerm;
                break;
        }

        await axios({
            method: 'GET',
            url: process.env.REACT_APP_API_URL + '/online/govidsearch' + queryParams,
            headers: headers
        })
        .then(function (res) {

            // Could be empty body with 204 no content
            if (typeof res.data !== 'undefined' && typeof res.data.govidjob !== 'undefined' && res.data.govidjob !== null) {

                if (allowServing) {
                    // if serving is allowed in the enviroment, check for servings before setting govidjob
                    setGovidJobsResult(res.data.govidjob);
                } else {
                    setGovidJobs(res.data.govidjob);
                }

                _govidJobs = res.data.govidjob;

                // save govid jobs to session storage, too
                jobSetGovidJob(_govidJobs);
            }
        })
        .catch(function (error) {
            if (error.response?.status === 401) {
                const alert = {message: lng(messages.authError), type: 'danger'};
                props.history.push({
                    pathname: "/logout",
                    alert
                });
            } else {
                setGovidJobs(error);
                _govidJobs = error;
                return Promise.reject(error);
            }
        })
        .then(function () {
            // Add empty array if no jobs found
            if (_govidJobs === false) {
                setGovidJobs([]);
            }
        });
    }

    useEffect(() => {

        // if uuid exists, first identificate user by requesting the token
        if (props.match.params.uuid) {

            // loading animation on
            toggleLoading(true, lng(messages.openingInvoice) + "...");

            userLogin(props.match.params.uuid)
            .then(res => {

                // get dpid from session storage if user identified with an undirect link
                let dpid = sessionStorage.getItem('dpid');
                // get subscriptionType if user identified with an undirect link to check if user is subscribing to web invoice
                let subscriptionType = sessionStorage.getItem('subscriptionType');

                // user is subscribing to web invoice when subscriptionType is set to web-invoice
                if (dpid !== null && subscriptionType !== null && subscriptionType === 'web-invoice') {
                    // redirect the user to web invoice subscription page
                    props.history.push("/subscriptions");
                }
                else if (dpid !== null) {
                    // redirect the user back to page without uuid parameter
                    props.history.push("/jobsearch");
                    
                    dpid = dpid.split('-');
                    setState({ ...state, jobid: dpid[0], code: dpid[1] });

                    setIsAuthenticating(false);
                    // setCallck value to trigger UE
                    setCallback('dpid');
                    
                    getOpenInvoicesByGovid().catch(error => {
                        setGovidJobs(error);
                    });
                }
                else {
                    // redirect the user back to page without uuid parameter
                    props.history.push("/jobsearch");

                    // fetch jobs by govid (hetu)
                    getOpenInvoicesByGovid().catch(error => {
                        setGovidJobs(error);
                    });

                    toggleLoading(false);

                    // User has identificated, allow access to / to display the page
                    setIsAuthenticating(false);
                }


                // Update user logged status
                if (props.updateUserLogged) {
                    props.updateUserLogged(true);
                }
            }, (error) => {
                // token request failed, redirecting back to indentification
                props.history.push("/");
                toggleLoading(false);
            });
        } else if (userLoggedin() === false) {
            // user is not authenticated, redirect back to indentification (no toast)
            props.history.push("/");
        } else {
            // Allow to display the page
            setIsAuthenticating(false);

            // Fetch the open invoices by govid
            getOpenInvoicesByGovid().catch(error => {
                setGovidJobs(error);
            });
        }

    }, []);

    /**
     * Triggered in environments where serving is allowed.
     * Check if job has a serving, and if a signature is needed or not.
     */
    useEffect(() => {
        let _govidJobResults = govidJobsResult;

        const numberOfJobs = govidJobsResult !== false ? _govidJobResults.length : 0;

        if (numberOfJobs > 0) {
            // keep track how many jobs have been checked
            let checkedJobs = 0;

            for (let govidjob of _govidJobResults) {
                // check for serving
                checkForServing(govidjob).catch((error) => {
                    // 404 = No serving for the job
                    if (error.response.status !== 404) {
                        setGovidJobs(error);
                    }
                }).then((response) => {
                    checkedJobs += checkServingResponse(govidjob, response);

                    // set govidJobs when all jobs have been checked
                    if (checkedJobs === numberOfJobs) {
                        setGovidJobs(_govidJobResults);
                    }
                }).catch(error => {
                    setGovidJobs(error);
                });
            }
        } else {
            // no jobs, set govidjobs
            setGovidJobs(_govidJobResults);
        }

    }, [govidJobsResult]);

    /**
     * Trigger when callback variable is set, e.g. when a query needs to be formed jobid and code
     * (page is rendered, or invoice details are opened from the list).
     */
    useEffect(() => {

        if (callback == 'dpid') {
            toggleLoading(false);
            sessionStorage.removeItem('dpid');
            handleSubmit(state.jobid + '-' + state.code);
        }
        else if (callback == 'link') {
            // create query from jobid and code and search it
            const query = state.jobid + '-' + state.code;
            handleSubmit(query);
        }

    }, [callback]);

    /**
     * Check with dpid if requirements for serving are met.
     * Is the job eligible for serving and if serving is signed or not.
     */
    async function checkForServing(govidjob) {
        if (allowServing) {
            const headers = userRequestHeaders();

            return await axios({
                method: 'GET',
                url: process.env.REACT_APP_API_URL + '/online/serving/' + govidjob.dpid,
                headers: headers,
            })
            .then(function (res) {
                // can return 204 NO CONTENT when serving is not eligible (e.g. wrong debt collection chain, country etc.)
                // or 200 OK when eligible for serving, and already signed
                return Promise.resolve(res);
            })
            .catch(function (error) {
                // can return 404 when serving is eligible, but no match found (serving is not signed)
                // or 400 in bad requests
                return Promise.reject(error);
            });
        }
    }

    /**
     * Check if serving needs a signature or not
     *
     * @param {*} govidJob
     * @param {*} response
     * @returns
     */
    function checkServingResponse(govidJob, response) {
		const servingType = response?.data?.data?.serving_type;
		const servedAt = response?.data?.data?.served_at;

		if (servingType === 'letter') {
			govidJob.needsignature = typeof(servedAt) === 'undefined' || String(servedAt).trim() == '';
		}

		return 1;
	}

    /**
     * Link to job using govid search
     *
     * @param event
     * @param dpid
     */
    function linkSubmit(event, dpid) {

        event.preventDefault();

        if (!isEmpty(dpid)) {
            const dpid_array = dpid.split('-');
            setState({
                ...state,
                jobid: dpid_array[0],
                code: dpid_array[1]
            });
            // set value to callback to trigger useEffect
            setCallback('link');
        }
    }

    /**
     * Submit the search form, and redirect to job (also used for govid search)
     *
     * @param query
     */
    function handleSubmit(query) {

        // Reset errors and other notifications
        resetRetrieveInvoiceError();
        resetDashboardMessage();
        resetOverpaidInformation();

        toggleLoading(true, lng(messages.searchingForJob));

        // API call GET /online/jobsearch/{dpid}, jobservice fetches the job details and saves it to storage for later use
        jobFetchJob(query)
        .then(function () {
            toggleLoading(false);
            // Check the job status code
            const job = jobGetJob();

            // Check if job is overpayment or credit note
            if (job.statuscode === 302 || job.statuscode === 104) {

                // If job status is overpayment or credit note, ensure the Retrieve invoice modal is closed
                setShowRetrieveModal(false);

                // Check if bank account number has already been notified
                if (job.modoverpaid !== null && job.modoverpaid) {

                    // If IBAN has not been notified, open wizard invoice data step
                    setReturnPaymentStep(1);
                } else {

                    // If IBAN has already been notified, show the overpaid information
                    setOverpaidInformationFromJob(job);
                    // Ensure the wizard is closed
                    returnPaymentClose();
                }
            } else {

                // If job status is not overpayment or credit note, redirect to job's page
                props.history.push('/job');
            }

        })
        .catch(function (error) {
            toggleLoading(false);
            // response exists
            if (typeof error?.response !== 'undefined') {
                if (error.response.status === 401) {
                    // authentication failed, redirect back to authentication
                    const alert = { message: lng(messages.authError), type: 'danger' };
                    props.history.push({
                        pathname: "/logout",
                        alert
                    });
                } else if (error.response.status === 403) {
                    // Forbidden messages
                    let message = lng(messages.forbiddenError);

                        if (typeof error.response.data.Message !== 'undefined') {
                            if (error.response.data.Message === 'Online access log - user access denied') {
                                message = lng(messages.searchLocked);
                            } else if (error.response.data.Message === 'Online jobsearch - company person is not allowed to sign') {
                                message = lng(messages.notAllowedToSign);
                            }
                        }

                    setRetrieveInvoiceError({
                        message: message,
                        type: 'danger'
                    });
                } else {
                    // job not found
                    setRetrieveInvoiceError({
                        message: lng(messages.jobNotFound),
                        type: 'danger'
                    });
                }
            } else {
                // request failed completely
                setRetrieveInvoiceError({
                    message: lng(messages.pleaseTryAgain),
                    type: 'danger'
                });
            }
        });
    }

    /**
     * Get overpaid information for job with notified account number
     * and set it to be displayed to user
     * @param job
     */
    function setOverpaidInformationFromJob(job) {
        // Get locale and currency for formatting
        const userLocale = userGetLocale();
        let currency = get(job, 'currency', false);
        currency = currency && currency !== '' ? currency : process.env.REACT_APP_DEFAULT_CURRENCY;

        setOverpaidInformation({
            show: true,
            account: job.overpaid_iban,
            amount: formatMoney(job.modoverpaidamount, userLocale, currency),
            name: job.overpaid_name,
            fee: formatMoney(job.modoverpaidfee, userLocale, currency)
        })
    }

    /**
     * Handle errors in overpayment submit
     * @param error
     */
    function handleOverpaidError(error) {
        // Get the error message from response
        const message = typeof error.response?.data.Message !== 'undefined' ? error.response.data.Message.trim() : '';

        const invalidAccountNumberMessages = [
            'Bank account number is not iban',
            'Invalid IBAN (Format)',
            'Invalid Bankgiro',
            'Invalid Plusgirot',
            'Invalid PlusGirot',
            'Invalid Bban'
        ];
        
        // Highlight the invalid field, if needed
        if (invalidAccountNumberMessages.includes(message)) {
            // Highlight Account number field
            const element = document.getElementById('returnpayment-account-number');
            if (!element.classList.contains('is-invalid')) {
                element.classList.add('is-invalid');
            }
        } else if (message === 'Invalid bic') {
            // Highlight BIC field
            const element = document.getElementById('returnpayment-account-bic');
            if (!element.classList.contains('is-invalid')) {
                element.classList.add('is-invalid');
            }
        }

        let displayError = '';

        // Set the error message according to the response
        switch(message) {
            case 'Bank account number is not iban':
            case 'Invalid IBAN (Format)':
                displayError = lng(messages.invalidIbanFormat);
                break;

            case 'Invalid Bankgiro':
                displayError = lng(messages.invalidBankgiroFormat);
                break;

            case 'Invalid Plusgirot':
            case 'Invalid PlusGirot':
                displayError = lng(messages.invalidPlusgirotFormat);
                break;

            case 'Invalid Bban':
                displayError = lng(messages.invalidBbanFormat);
                break;

            case 'Unable to get BIC from iban':
                displayError = lng(messages.bicFromIbanFail);
                break;

            case 'Invalid bic':
                displayError = lng(messages.bicCodeInvalid);
                break;
    
            case 'Operation in progress':
                displayError = lng(messages.overpaidRefundInProgress);
                break;
                
            default:
                displayError = lng(messages.defaultErrorMessage);
                break;
        }

        setReturnPaymentError({
            message: displayError,
            type: 'danger'
        });
    }

    /**
     * Sends a bank account number for returning an overpayment
     * @param overpaidData
     */
    function submitOverpaid(overpaidData) {

        // Reset errors and other notifications
        resetRetrieveInvoiceError();
        resetDashboardMessage();
        resetOverpaidInformation();

        // Shows the loading animation "Saving..."
        toggleLoading(true, lng(messages.actionSaving));

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/online/bankaccount',
            headers: userRequestHeaders(),
            data: JSON.stringify(overpaidData)
        })
        .then(function (res) {

            // Payment return process was successful
            jobFetchJob(overpaidData.id)
            .then(function (response) {

                // Get job data for displaying success message
                const job = jobGetJob();
                // Close the wizard
                returnPaymentClose();
                // Turn off loading
                toggleLoading(false);

                if (job !== null) {
                    // Display success message
                    setOverpaidInformationFromJob(jobGetJob());
                } else {
                    // Error: Job was not found
                    // Payment return process was successful, but job data can't be displayed to user
                    setDashboardMessage({
                        message: lng(messages.overpaidInformation1) + '.',
                        type: 'success'
                    });
                }

            })
            .catch(function () {
                // Error: Job fetch failed
                // Payment return process was successful, but job data can't be displayed to user
                toggleLoading(false);
                setDashboardMessage({
                    message: lng(messages.overpaidInformation1) + '.',
                    type: 'success'
                });
            });
        })
        .catch(function (error) {
            toggleLoading(false);
            if (error.response?.status === 401) {
                const alert = {message: lng(messages.authError), type: 'danger'};
                props.history.push({
                    pathname: "/logout",
                    alert
                });
            } else {
                // Error: Payment return process failed, stay in wizard
                handleOverpaidError(error);
            }
        });
    }

    /**
     * Reset overpaid information
     */
    function resetOverpaidInformation() {
        setOverpaidInformation({
            show: false,
            account: '',
            amount: '',
            name: '',
            fee: ''
        });
    }

    /**
     * Reset general dashboard message
     */
    function resetDashboardMessage() {
        setDashboardMessage({
            message: '',
            type: 'light'
        });
    }

    /**
     * Reset return payment process errors
     */
    function resetReturnPaymentError() {
        setReturnPaymentError({
            message: '',
            type: 'light'
        });
    }

    /**
     * Reset invoice retrieving errors
     */
    function resetRetrieveInvoiceError() {
        setRetrieveInvoiceError({
            message: '',
            type: 'light'
        });
    }

    /**
     * toggle retrieve invoice modal visibility and clear its errors
     */
    function retrieveModalToggle() {
        resetRetrieveInvoiceError();
        resetDashboardMessage();
        resetOverpaidInformation();
        setShowRetrieveModal(!showRetrieveModal);
    }

    /**
     * Reset input field to empty and restore tabs
     */
    function resetSearch() {
        setSearchText("");
        setCurrentTab("invoices");
        getOpenInvoicesByGovid('invoices');
    }

    /**
     * Handle search input events
     * @param event
     */
    function handleSearch(event) {
        setSearchText(event.target.value);
        debounced(event.target.value);
    }
    
    /**
     * Get currency from job object
     * If job doesn't have currency, use default currency
     * @param job
     */
    function getCurrencyFromJob (job) {
        // Get currency from job data
        let currency = get(job, 'currency', false);
        
        // If currency was not found, use the default currency
        currency = currency && currency !== '' ? currency : process.env.REACT_APP_DEFAULT_CURRENCY;
        
        return currency;
    }

    return (
        <>
            {/* if is not authenticated, do not show page. If is authenticated, show page */}
            {!isAuthenticating && (
                <div className='content flex-fill'>
                    <Helmet id='job-helmet'>
                        <title>{lng(messages.searchTitle)} | MyRopo</title>
                    </Helmet>
                    <Container fluid id='dashboard' className='p-0 d-flex h-100'>
                        <Container className="p-4 pb-0">
                            <Row className="mb-3 g-5 pb-2 pt-0 pt-xl-3">
                                <Col className='welcome-message'>
                                    <h1>{ lng(messages.welcome) }</h1>
                                </Col>
                            </Row>
                            {/* General invoice messages */}
                            { typeof dashboardMessage.message !== 'undefined' && dashboardMessage.message != '' && (
                                <AlertDialog
                                    message={dashboardMessage.message}
                                    type={dashboardMessage.type}
                                />
                            )}
                            {/* Overpaid information */}
                            {overpaidInformation.show && (
                                <AlertDialog
                                    message={ <div>{ lng(messages.overpaidInformation1) } <b>{ overpaidInformation.account }</b> { lng(messages.overpaidInformation2) } <b>{ overpaidInformation.name }</b>. { lng(messages.returnAmount) }: <b>{ overpaidInformation.amount }</b>. { lng(messages.refundProcessingFee) }: <b>{ overpaidInformation.fee }</b>.</div> }
                                    type='success'
                                />
                            )}
                            <Row className="g-5">
                                <Notification
                                    type={'secondary'}
                                    title={lng(messages.retrieveInvoiceTitle)}
                                    text={lng(messages.retrieveInvoiceDescription)}
                                    onClickFunction={retrieveModalToggle}
                                />
                                <Notification
                                    title={lng(messages.returnPaymentTitle)}
                                    text={lng(messages.returnPaymentDescription)}
                                    onClickFunction={returnPaymentMoveToNextStep}
                                />
                            </Row>
                            <Tab.Container id="govidsearch" defaultActiveKey="invoices" activeKey={currentTab} onSelect={(key) => setCurrentTab(key)}>
                                <Row className="pt-4">
                                    <Col xl={6} md={12} className="order-2 order-xl-1 mt-4 mt-xl-0 online-tabs">
                                        {currentTab === "search" ? (
                                            <Nav
                                                fill
                                                defaultActiveKey="search"
                                                as="ul"
                                                className="online-tabs"
                                            >
                                                <Nav.Item as="li">
                                                    <Nav.Link eventKey="search">{ lng(messages.searchResults) }</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        ) : (
                                            <Nav
                                                fill
                                                defaultActiveKey="invoices"
                                                as="ul"
                                                className="online-tabs"
                                                onSelect={(selectedKey) => getOpenInvoicesByGovid(selectedKey)}
                                            >
                                                <Nav.Item as="li">
                                                    <Nav.Link eventKey="invoices">{ lng(messages.tabInvoicesTitle) }</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    <Nav.Link eventKey="archive">{ lng(messages.tabArchiveTitle) }</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        )}
                                    </Col>

                                    <Col xl={6} md={12} className="order-1 order-xl-2">
                                        <InputGroup className="has-search">
                                            <InputGroup.Text className="form-control-search">
                                                <FontAwesomeIcon className="text-body-tertiary" icon={far.faMagnifyingGlass} />
                                            </InputGroup.Text>
                                            <Form.Control
                                                placeholder={ lng(messages.searchFieldPlaceholder) }
                                                aria-label={ lng(messages.searchFieldPlaceholder) }
                                                aria-describedby={ lng(messages.searchFieldPlaceholder) }
                                                onChange={handleSearch}
                                                value={searchText}
                                            />
                                            {searchText.length > 0 ? (
                                                <Button aria-label="Clear search" variant="outline-secondary" id="clear-search" onClick={() => resetSearch()}>
                                                    <FontAwesomeIcon icon={far.faCircleXmark} />
                                                </Button>
                                            ) : ('')}
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Tab.Content>
                                            <Tab.Pane id="invoices" eventKey="invoices" className="show">
                                                <Row className="g-5 mt-0 bg-light">
                                                    <Col>
                                                        {govidJobs !== false && currentTab === "invoices" ? (
                                                            govidJobs.length > 0 ? (
                                                                govidJobs.map((row, i) => {
                                                                    // Get job currency for invoice row
                                                                    const currency = getCurrencyFromJob(row);

                                                                    // Print open invoices
                                                                    return (
                                                                        <button id={"govidjob-" + i} key={row.jobid} className="btn invoice-card" onClick={(e) => linkSubmit(e, row.dpid)}>
                                                                            <InvoiceCard
                                                                                key={row.jobid}
                                                                                creditor={row.payee.name}
                                                                                openTotal={row.openamount}
                                                                                paidTotal={row.paidamount}
                                                                                currency={currency}
                                                                                statusCode={row.job_main_statuscode}
                                                                                jobStatus={row.jobstatus}
                                                                                dueDate={row.paydate}
                                                                                intl={props.intl}
                                                                                needsignature={row.needsignature}
                                                                            />
                                                                        </button>
                                                                    );
                                                                })
                                                            ) : (govidJobs instanceof Error ? (
                                                                // Error - search failed
                                                                <AlertDialog
                                                                    message={lng(messages.govidSearchError)}
                                                                    type={'danger'}
                                                                />
                                                            ) : (userGetCustomerType() === CUSTOMERTYPE.COMPANY ? (
                                                                // No open invoices - Company
                                                                <AlertDialog
                                                                    message={lng(messages.noOpenCompany)}
                                                                    type={'light'}
                                                                />
                                                            ) : (
                                                                // No open invoices - Person
                                                                <AlertDialog
                                                                    message={lng(messages.noOpen)}
                                                                    type={'light'}
                                                                />)
                                                            ))
                                                        ) : (
                                                            <InvoiceCardSkeleton count={5} />
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane id="archive" eventKey="archive">
                                                <Row className="g-5 mt-0 bg-light">
                                                    <Col>
                                                        {govidJobs !== false && currentTab === "archive" ? (
                                                            govidJobs.length > 0 ? (
                                                                govidJobs.map((row, i) => {
                                                                    // Get job currency for invoice row
                                                                    const currency = getCurrencyFromJob(row);
                                                                    // Print paid invoices
                                                                    return (
                                                                        <button id={"govidjob-" + i} key={row.jobid} className="btn invoice-card" onClick={(e) => linkSubmit(e, row.dpid)}>
                                                                            <InvoiceCard
                                                                                key={row.jobid}
                                                                                creditor={row.payee.name}
                                                                                openTotal={row.openamount}
                                                                                paidTotal={row.paidamount}
                                                                                currency={currency}
                                                                                statusCode={row.job_main_statuscode}
                                                                                jobStatus={row.jobstatus}
                                                                                dueDate={row.paydate}
                                                                                intl={props.intl}
                                                                            />
                                                                        </button>
                                                                    );
                                                                })
                                                            ) : (govidJobs instanceof Error ? (
                                                                // Error - search failed
                                                                <AlertDialog
                                                                    message={lng(messages.govidSearchError)}
                                                                    type={'danger'}
                                                                />
                                                            ) : (userGetCustomerType() === CUSTOMERTYPE.COMPANY ? (
                                                                    // No paid invoices - Company
                                                                    <AlertDialog
                                                                        message={lng(messages.noOpenCompany)}
                                                                        type={'light'}
                                                                    />
                                                                ) : (
                                                                    // No paid invoices - Person
                                                                    <AlertDialog
                                                                        message={ lng(messages.noArchivedInvoices) }
                                                                        type={'light'}
                                                                    />)
                                                            ))
                                                        ) : (
                                                            <InvoiceCardSkeleton count={5} />
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane id="search" eventKey="search">
                                                <Row className="g-5 mt-0 bg-light">
                                                    <Col>
                                                        {govidJobs !== false && currentTab === "search" ? (
                                                            govidJobs.length > 0 ? (
                                                                govidJobs.map((row, i) => {
                                                                    // Get job currency for invoice row
                                                                    const currency = getCurrencyFromJob(row);
                                                                    // Print paid invoices
                                                                    return (
                                                                        <button id={"govidjob-" + i} key={row.jobid} className="btn invoice-card" onClick={(e) => linkSubmit(e, row.dpid)}>
                                                                            <InvoiceCard
                                                                                key={row.jobid}
                                                                                creditor={row.payee.name}
                                                                                openTotal={row.openamount}
                                                                                paidTotal={row.paidamount}
                                                                                currency={currency}
                                                                                statusCode={row.job_main_statuscode}
                                                                                jobStatus={row.jobstatus}
                                                                                dueDate={row.paydate}
                                                                                intl={props.intl}
                                                                                needsignature={row.needsignature}
                                                                            />
                                                                        </button>
                                                                    );
                                                                })
                                                            ) : (govidJobs instanceof Error ? (
                                                                // Error - search failed
                                                                <AlertDialog
                                                                    message={lng(messages.govidSearchError)}
                                                                    type={'danger'}
                                                                />
                                                            ) : (userGetCustomerType() === CUSTOMERTYPE.COMPANY ? (
                                                                    // No paid invoices - Company
                                                                    <AlertDialog
                                                                        message={lng(messages.noOpenCompany)}
                                                                        type={'light'}
                                                                    />
                                                                ) : (
                                                                    // No search results
                                                                    <AlertDialog
                                                                        message={lng(messages.noSearchResults)}
                                                                        type={'light'}
                                                                    />
                                                                )
                                                            ))
                                                        ) : (
                                                            <InvoiceCardSkeleton count={5} />
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Container>
                        <RetrieveInvoiceModal
                            show={showRetrieveModal}
                            hide={retrieveModalToggle}
                            handleSubmit={handleSubmit}
                            error={retrieveInvoiceError}
                            {...props}
                        />
                        {/* Return payment wizard */}
                        <Wizard
                            currentStep={returnPaymentStep}
                            steps={
                                [
                                    {
                                        title: lng(messages.returnPaymentTitle),
                                        returnText: lng(messages.goBack),
                                        bodyContent: <ReturnPaymentStep1 intl={props.intl} error={retrieveInvoiceError} />,
                                        footerContent: <Button variant='primary' onClick={ returnPaymentStepOne }>{ lng(messages.nextStep) }</Button>,
                                        closeFunction: returnPaymentClose
                                    },
                                    {
                                        title: lng(messages.returnPaymentTitle),
                                        returnText: lng(messages.goBack),
                                        bodyContent: <ReturnPaymentStep2 intl={props.intl} />,
                                        footerContent: <Button variant='primary' onClick={ returnPaymentMoveToNextStep }>{ lng(messages.nextStep) }</Button>,
                                        closeFunction: returnPaymentClose
                                    },
                                    {
                                        title: lng(messages.returnPaymentTitle),
                                        returnText: lng(messages.goBack),
                                        bodyContent: <ReturnPaymentStep3 intl={props.intl} error={returnPaymentError} {...props} />,
                                        footerContent: <Button variant='primary' onClick={ returnPaymentStepThree }>{ lng(messages.send) }</Button>,
                                        closeFunction: returnPaymentClose
                                    }
                                ]
                            }
                        />
                    </Container>
                </div>
            )}
        </>
    );
};

Dashboard.propTypes = {
    'intl': PropTypes.object.isRequired,
    'history': PropTypes.any,
    'match': PropTypes.object,
    'timeout': PropTypes.number,
    'updateUserLogged': PropTypes.func
};

export default injectIntl(Dashboard);