import React from 'react';
import { NavLink } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import { userLoggedin } from "../../services/user/index";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown } from "@fortawesome/pro-regular-svg-icons";

// Strings for translation in this view
const messages = defineMessages({
    contentNotFound: {
        id: "Sisältöä ei löytynyt",
        defaultMessage: "Sisältöä ei löytynyt"
    },
    paragraph1: {
        id: "Hakemaasi sisältöä ei löytynyt",
        defaultMessage: "Hakemaasi sisältöä ei löytynyt"
    },
    returnToFront: {
        id: "Palaa takaisin etusivulle",
        defaultMessage: "Palaa takaisin etusivulle"
    },
    returnToSearch: {
        id: "Palaa takaisin hakuun",
        defaultMessage: "Palaa takaisin hakuun"
    }
});

export { messages };

const Error = (props) => {
    // translations
    const lng = props.intl.formatMessage;
    
    return (
        <div id="not-found" className="mx-auto my-auto text-center">
            <h1>
                {lng(messages.contentNotFound)}
            </h1>
            <FontAwesomeIcon icon={faFrown} size="6x" className="mb-3" />
            <p>
                {lng(messages.paragraph1)}.
            </p>
            { userLoggedin() ? (
                <NavLink to="/jobsearch">{lng(messages.returnToSearch)}.</NavLink>
            ) : (
                <NavLink to="/">{lng(messages.returnToFront)}.</NavLink>
            )}
        </div>
    );
};

Error.propTypes = {
    intl: PropTypes.object.isRequired
};

export default injectIntl(Error);