import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Modal, Button, Dropdown } from 'react-bootstrap';
import { defineMessages, injectIntl } from "react-intl";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@fortawesome/pro-regular-svg-icons";

// services
import { userLoggedin } from '../../services/user';
import { messagecenterGetMessages, messagecenterGetSingleMessage, messagecenterSetMessageUnread, messagecenterRemoveMessages, messagecenterFetchMessages } from '../../services/messagecenter';

// components
import MessageCard from './components/MessageCard';
import MessageCardSkeleton from './components/MessageCardSkeleton';
import AlertDialog from '../../components/AlertDialog';
import MessageContent from './components/MessageContent';

// translations
export const messages = defineMessages({
    messageCenter: {
        id: "Viestikeskus",
        defaultMessage: 'Viestikeskus'
    },
    title: {
        id: "Viestit",
        defaultMessage: 'Viestit'
    },
    noMessagesPrefix: {
        id: "Ei uusia viestejä. Haluatko lähettää meille viestin? Valitse etusivulta lasku, johon asiasi liittyy. Avaa laskun tiedot ja valitse laskusivulta",
        defaultMessage: 'Ei uusia viestejä. Haluatko lähettää meille viestin? Valitse etusivulta lasku, johon asiasi liittyy. Avaa laskun tiedot ja valitse laskusivulta'
    },
    noMessagesSuffix: {
        id: "Jos sinulla ei ole avoimia tai maksettuja laskuja, voit asioida kanssamme chatissa tai puhelimitse.",
        defaultMessage: 'Jos sinulla ei ole avoimia tai maksettuja laskuja, voit asioida kanssamme chatissa tai puhelimitse.'
    },
    noMessagesSuffixWithoutChat: {
        id: "Jos sinulla ei ole avoimia tai maksettuja laskuja, voit asioida kanssamme puhelimitse.",
        defaultMessage: 'Jos sinulla ei ole avoimia tai maksettuja laskuja, voit asioida kanssamme puhelimitse.'
    },
    authError: {
        id: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen."
    },
    authErrorShort: {
        id: "Tunnistautuminen epäonnistui",
        defaultMessage: "Tunnistautuminen epäonnistui"
    },
    authRequired: {
        id: "Sinun täytyy tunnistautua ennen kuin voit käyttää MyRopoa.",
        defaultMessage: "Sinun täytyy tunnistautua ennen kuin voit käyttää MyRopoa."
    },
    disabledInEnvironment: {
        id: "Viestikeskus ei ole käytössä tässä ympäristössä.",
        defaultMessage: "Viestikeskus ei ole käytössä tässä ympäristössä."
    },
    errorOccurredTryAgain: {
        id: "Tapahtui virhe. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tapahtui virhe. Ole hyvä ja yritä uudelleen."
    },
    underMaintenance: {
        id: "Pahoittelemme, viestikeskus on tilapäisesti poissa käytöstä huollon vuoksi. Yritäthän myöhemmin uudelleen.",
        defaultMessage: "Pahoittelemme, viestikeskus on tilapäisesti poissa käytöstä huollon vuoksi. Yritäthän myöhemmin uudelleen."
    },
    goBack: {
        id: "Palaa takaisin",
        defaultMessage: "Palaa takaisin"
    },
    createNewMessage: {
        id: "Aloita uusi viestiketju",
        defaultMessage: "Aloita uusi viestiketju"
    },
    sortDropdownToggle: {
        id: "Järjestä",
        defaultMessage: "Järjestä"
    },
    sortNewestFirst: {
        id: "Uusin ensin",
        defaultMessage: "Uusin ensin"
    },
    sortUnread: {
        id: "Lukematon",
        defaultMessage: "Lukematon"
    },
    sortByStatus: {
        id: "Viestin tila",
        defaultMessage: "Viestin tila"
    },
    sortByCreditorName: {
        id: "Laskuttajan nimi",
        defaultMessage: "Laskuttajan nimi"
    }
});


const MessageCenter = (props) => {
    const lng = props.intl.formatMessage;
    const location = props.location; 
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [allMessages, setAllMessages] = useState([]);
    const [singleMessage, setSingleMessage] = useState(allMessages[0] ?? {});
    const [showInModal, setShowInModal] = useState(false);
    const [loadingTickets, setLoadingTickets] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0); // first message as the active card by default
    const [sortOption, setSortOption] = useState('newest'); // state for sort option
    const skeletonCount = 5;
    const messageRefs = useRef([]); 
    const hasHandledMessageCard = useRef(false);
    const fromJob = location.state?.fromJob;

    const targetUrl = fromJob ? `/job` : '/jobsearch';

    // check message center rights
    let messageCenter = false;

    if (typeof process.env.REACT_APP_MESSAGECENTER !== 'undefined' && process.env.REACT_APP_MESSAGECENTER === '1') {
        messageCenter = true;
    }


    useEffect(() => {

        // do not allow access to message center page not allowed in the environment or user is not logged in
        if (!userLoggedin()) {
            const alert = { message: lng(messages.authRequired), type: 'primary' };
            props.history.push({
                pathname: "/",
                alert
            });

        } else if (!messageCenter) {
            // redirect to jobsearch, if message center is not enabled in the environment
            const alert = { message: lng(messages.disabledInEnvironment), type: 'primary' };
            props.history.push({
                pathname: "/jobsearch",
                alert
            });

        } else {
            // message center is enabled and user is logged in, get all user's messages
            setIsAuthenticated(true);
            if (props.maintenance) {
                showAlerts({
                    message: lng(messages.underMaintenance),
                    type: 'warning'
                });
            } else {
                getAllUserMessages();
            }
        }

    }, [props.maintenance, sortOption]);

    useEffect(() => {
        // Get the messageId from the query parameters
        const queryParams = new URLSearchParams(location.search);
        const messageId = queryParams.get('messageId');

        if (messageId && !hasHandledMessageCard.current) {
            // Find the message with the given messageId
            const messageIndex = allMessages.findIndex(msg => msg.id === messageId);
            if (messageIndex !== -1) {
                setActiveIndex(messageIndex); // Set the active index based on messageId
                setSingleMessage(allMessages[messageIndex]);
                setShowInModal(true);

                if (allMessages[messageIndex].current_status.unread == 1) {
                    messagecenterSetMessageUnread(props.updateUnreadMessagesNotification, messageId).then(() => {
                        // Update the message's isUnread value
                        const updatedMessages = allMessages.map((message, index) => {
                            if (index === messageIndex) {
                                return { ...message, current_status: { ...message.current_status, unread: 0 } };
                            }
                            return message;
                        });
                        setAllMessages(updatedMessages);
                    });
                }

                // Scroll to the active message card
                if (messageRefs.current[messageIndex]) {
                    messageRefs.current[messageIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }

                hasHandledMessageCard.current = true; //trigger processing only once when the messageId is in the query parameters
            }
        }
    }, [location.search, allMessages]);

    useEffect(() => {
        if (location.state?.alert) {
            setAlertMessage(location.state.alert.message);
            setAlertType(location.state.alert.type);
            setShowAlert(true);
        }
    }, [location.state]);
    
    /**
     * Check if messages can be loaded from the session storage
     * or if they need to be fetched from the API.
     *
     * Show messages in the listing.
     */
    function getAllUserMessages() {
        clearAlerts();
        setLoadingTickets(true);

        const getMessages = async () => {

            try {
                // Fetch all stored messages
                const allStoredMessages = await messagecenterGetMessages();
                
                // Check if user has messages and set them
                checkLenghtAndSetMessages(allStoredMessages);
                
                // Set first message to be shown by default
                setSingleMessage(allStoredMessages[0] ?? {});
            } catch (error) {
                if (error?.response?.status === 401) {
                    // session expired, log user out and show error message
                    const alert = { message: lng(messages.authError), type: 'danger' };
                    props.history.push({
                        pathname: "/logout",
                        alert
                    });
                } else {
                    // show error message
                    showAlerts({
                        message: lng(messages.errorOccurredTryAgain),
                        type: 'danger'
                    });
                }
            } finally {
                // Toggle skeleton loading off
                setLoadingTickets(false);

                try {
                    // Refresh session storage
                    const allStoredMessages = await messagecenterFetchMessages();
                    
                    if (allStoredMessages) {
                        // Check if user has messages and set them
                        checkLenghtAndSetMessages(allStoredMessages);
                        
                        // Set first message to be shown by default
                        setSingleMessage(allStoredMessages[0] ?? {});
                    }
                } catch (error) {
                    showAlerts({
                        message: lng(messages.errorOccurredTryAgain),
                        type: 'danger'
                    });
                }
                
            }
        };
        getMessages();
    }

    /**
     * Check passed message array and show them in the listing.
     * If messages are empty, show notification in alertDialog.
     *
     * @param {*} allMessages array of messages
     */
    function checkLenghtAndSetMessages(allMessages) {
        // if user has no messages, show notification in alertDialog
        if (allMessages.length === 0) {

            // does the environment allow the use of chat?
            if (typeof process.env.REACT_APP_SHOW_CHAT !== 'undefined' && process.env.REACT_APP_SHOW_CHAT.toString() === '1') {
                showAlerts({
                    message: lng(messages.noMessagesPrefix)+' "'+lng(messages.createNewMessage)+'" '+lng(messages.noMessagesSuffix),
                    type: 'primary'
                });
            } else {
                showAlerts({
                    message: lng(messages.noMessagesPrefix)+' "'+lng(messages.createNewMessage)+'" '+lng(messages.noMessagesSuffixWithoutChat),
                    type: 'primary'
                });
            }
        } else {
            // Sort messages based on the selected sort option
            sortMessages(allMessages, sortOption);
        }

        hasHandledMessageCard.current = false;

        // set messages
        setAllMessages(allMessages);
    }
    
    /**
     * Get the order number for the status
     * @param status
     * @returns {number}
     */
    function getStatusOrder(status) {
        let orderNumber;
        
        switch (status) {
            // Action Needed
            case 'Waiting for customer':
                orderNumber = 1;
                break;
            
            // Resolved
            case 'Canceled':
            case 'Closed':
            case 'Resolved':
                orderNumber = 3;
                break;
                
            // In Progress
            case 'Waiting for support':
            case 'Waiting for 3rd party':
            case 'In Progress':
            case 'Pending':
            case 'Escalate':
            case 'Escalated':
            default:
                orderNumber = 2;
                break;
        }
        
        return orderNumber;
    }
    
    /**
     * Get the latest change time for the message flow
     * @param message
     * @returns {Date}
     */
    function getLatestChangeTime(message) {
        // Get the latest change time from the message status by default
        let latestChangeTime = new Date(message.current_status.status_date);
        
        // If there are comments, check if any of them are newer than the status change
        if (message.comments?.length > 0) {
            for (const comment of message.comments) {
                const commentTime = new Date(comment.created_at);
                if (commentTime > latestChangeTime) {
                    latestChangeTime = commentTime;
                }
            }
        }
        
        return latestChangeTime;
    }
    
    /**
     * Sort messages based on the selected sort option
     *
     * @param {*} messages array of messages
     * @param {*} option selected sort option
     */
    function sortMessages(messages, option) {
        switch (option) {
            case 'newest':
                messages.sort((a, b) => getLatestChangeTime(b) - getLatestChangeTime(a));
                break;
            case 'unread':
                messages.sort((a, b) => b.current_status.unread - a.current_status.unread);
                break;
            case 'status':
                messages.sort((a, b) => getStatusOrder(a.current_status.status) - getStatusOrder(b.current_status.status));
                break;
            case 'creditor':
                messages.sort((a, b) => a.creditor.localeCompare(b.creditor));
                break;
            default:
                break;
        }
    }
    
    /**
     * Set alert message and type, and show alertDialog.
     *
     * @param {*} alert
     */
    function showAlerts(alert) {
        setAlertMessage(alert.message);
        setAlertType(alert.type);

        setShowAlert(true);
    }

    /**
     * Clear error messages and hide alertDialog.
     */
    function clearAlerts() {
        setAlertMessage('');
        setAlertType('');

        setShowAlert(false);
    }

    /**
     * Handle messagecard clicks.
     *
     * @param {*} chosen
     */
    function handleMessagecard(chosen) {
        clearAlerts();
        setActiveIndex(chosen);
        // Extract the message ID from the chosen message using the index
        const messageId = allMessages[chosen].id;
    
        if (allMessages[chosen].current_status.unread === 1) {
            messagecenterSetMessageUnread(props.updateUnreadMessagesNotification, messageId).then(() => {
                // Update the message's isUnread value
                const updatedMessages = allMessages.map((message, index) => {
                    if (index === chosen) {
                        return { ...message, current_status: { ...message.current_status, unread: 0 } };
                    }
                    return message;
                });
                setAllMessages(updatedMessages);
            });
        }    
        setSingleMessage(messagecenterGetSingleMessage(allMessages[chosen].id) ?? {});
        setShowInModal(true);      
    }

    /**
     * Handle modal hide in mobile views.
     */
    function handleHideModal() {
        clearAlerts();
        if (fromJob) {
            props.history.push('/job');
        } else {
            setShowInModal(false);
        }
    }

    const handleNavigation = () => {
        props.history.push(targetUrl);
    };

    return (
        <>
            {isAuthenticated && (
                <div className="content flex-fill">
                    <Helmet id='job-helmet'>
                        <title>{lng(messages.messageCenter)} | MyRopo</title>
                    </Helmet>
                    <Container id='message-center' className='content flex-fill p-0 p-sm-4 pt-xl-4'>
                        <Row className='pb-3 pt-3 d-none d-sm-none d-md-block'>
                            <Col xs={11}>
                                <button className='btn text-start p-0' onClick={handleNavigation}>
                                    <h5>
                                        <FontAwesomeIcon icon={far.faArrowLeft} /> &nbsp;
                                        {lng(messages.goBack)}
                                    </h5>
                                </button>
                            </Col>
                        </Row>
                        <Row className='mb-3 mt-3 mt-sm-0'>
                            <Col xs={12} lg={4} >
                                <Row className='pe-3'>
                                    <Col xs={8} sm={7} md={8} className='welcome-message pe-0'>
                                        <h2 className='text-break ps-4 mb-0 ps-sm-0 ps-xl-4'>{lng(messages.title)}</h2>
                                    </Col>
                                    {!props.maintenance && allMessages.length > 0 && (
                                        <Col xs={4} sm={5} md={4} className='d-flex justify-content-end ps-0 pe-4 pe-sm-0'>
                                            <Dropdown>
                                                <Dropdown.Toggle className="dropdown-toggle-white">
                                                    {lng(messages.sortDropdownToggle)}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        className={'dropdown-check-item' + (sortOption === 'newest' ? ' active' : '')}
                                                        onClick={() => { setSortOption('newest'); }}
                                                    >
                                                        {lng(messages.sortNewestFirst)}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        className={'dropdown-check-item' + (sortOption === 'unread' ? ' active' : '')}
                                                        onClick={() => { setSortOption('unread'); }}
                                                    >
                                                        {lng(messages.sortUnread)}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        className={'dropdown-check-item' + (sortOption === 'status' ? ' active' : '')}
                                                        onClick={() => { setSortOption('status'); }}>
                                                        {lng(messages.sortByStatus)}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        className={'dropdown-check-item' + (sortOption === 'creditor' ? ' active' : '')}
                                                        onClick={() => { setSortOption('creditor'); }}
                                                    >
                                                        {lng(messages.sortByCreditorName)}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                        <div className='ps-4 pe-4 ps-sm-0 pe-sm-0'>
                            {showAlert && (
                                <AlertDialog
                                    type={alertType}
                                    message={alertMessage}
                                />
                            )}
                        </div>
                        <Row className='g-3'>
                            {loadingTickets ? (
                                <>
                                    <Col xs={12} lg={4}>
                                        <MessageCardSkeleton count={skeletonCount} />
                                    </Col>
                                    <Col xs={12} lg={8} className="text-center">
                                        <MessageCardSkeleton count={1} />
                                    </Col>
                                </>
                            ) : (
                                <>
                                    {!props.maintenance && allMessages.length > 0 && (
                                        <>
                                            <Col xs={12} lg={4} className='message-container'>
                                                <div className='scrollable-content'>
                                                    {allMessages.map((message, index) => {
                                                        let nextIndex = index < allMessages.length - 1 ? index + 1 : '';

                                                        return (
                                                            <div key={'message-' + message.id} ref={el => messageRefs.current[index] = el}>
                                                                <button id={"message-" + message.id} className="btn messagecard" onClick={() => handleMessagecard(index)} >
                                                                    <MessageCard
                                                                        cardIndex={index}
                                                                        id={message.id}
                                                                        status={message.current_status.status}
                                                                        statusCode={message.jobstatus}
                                                                        dueDate={message.due_date}
                                                                        summary={message.summary}
                                                                        invoiceNumber={message.jobid}
                                                                        creditor={message.creditor}
                                                                        activeIndex={activeIndex}
                                                                        isUnread={message.current_status.unread}
                                                                        showAlerts={showAlerts}
                                                                        {...props}
                                                                    />
                                                                </button>

                                                                {/* do not show spacers before/after the chosen card*/}
                                                                <hr className={'mb-3 ' + (activeIndex === index || activeIndex === nextIndex ? 'hide' : '') + " d-none d-sm-block"} />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Col>
                                            <Col xs={12} lg={8} className="message-container d-none d-lg-block">
                                                <MessageContent
                                                    id={singleMessage.id ?? ''}
                                                    isSupport={singleMessage.is_support ?? false}
                                                    status={singleMessage.current_status?.status ?? ''}
                                                    summary={singleMessage.summary ?? ''}
                                                    description={singleMessage.description ?? ''}
                                                    date={singleMessage.current_status?.status_date ?? ''}
                                                    comments={singleMessage.comments ?? []}
                                                    attachments={singleMessage.attachments ?? []}
                                                    showAlerts={showAlerts}
                                                    handleMessagecard={handleMessagecard}
                                                    reason={singleMessage.reason ?? ''}
                                                    formId='message-center-form-desktop'
                                                />
                                            </Col>

                                            {/* Use modal to show message content in mobile */}
                                            <div className='message-mobile'>
                                                <Modal className='d-block d-lg-none pb-0' show={showInModal} onHide={handleHideModal} dialogClassName='mobile-message-content' backdropClassName='mobile-message-content' contentClassName='mobile-message-content' backdrop={false}>
                                                    <Modal.Header className='fixed-element-top ps-4 mobile-message-content'>
                                                        <Button className="modal-back" onClick={handleHideModal}>
                                                            <FontAwesomeIcon icon={far.faArrowLeft} /> {lng(messages.goBack)}
                                                        </Button>
                                                        {showAlert && (
                                                            <div className='pt-4'>
                                                                <AlertDialog
                                                                    type={alertType}
                                                                    message={alertMessage}
                                                                />
                                                            </div>
                                                        )}
                                                    </Modal.Header>
                                                    <Modal.Body className='p-0'>
                                                        <MessageContent
                                                            id={singleMessage.id ?? ''}
                                                            isSupport={singleMessage.is_support ?? false}
                                                            status={singleMessage.current_status?.status ?? ''}
                                                            summary={singleMessage.summary ?? ''}
                                                            description={singleMessage.description ?? ''}
                                                            date={singleMessage.current_status?.status_date ?? ''}
                                                            comments={singleMessage.comments ?? []}
                                                            attachments={singleMessage.attachments ?? []}
                                                            showAlerts={showAlerts}
                                                            handleMessagecard={handleMessagecard}
                                                            reason={singleMessage.reason ?? ''}
                                                            formId='message-center-form-mobile'
                                                        />
                                                    </Modal.Body>
                                                </Modal>
                                            </div>
                                        </>
                                    )}
                                </>
                            )
                            }
                        </Row>
                    </Container>
                </div >
            )}
        </>
    );
};


MessageCenter.propTypes = {
    'intl': PropTypes.object.isRequired,
    'history': PropTypes.object,
    'location': PropTypes.object,
    'updateUnreadMessagesNotification': PropTypes.func,
    'maintenance': PropTypes.bool.isRequired,
};

export default injectIntl(MessageCenter);