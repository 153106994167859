import isEmpty from 'lodash.isempty';

export const role = {
    authenticated: 'authenticated',
    anonymous: 'anonymous'
};

const rolemap = [
    { approle: role.anonymous, externalRole: 'link' },
    { approle: role.authenticated, externalRole: 'tupas' }
];

export function getRole(role) {
    const item = rolemap.find(e => e.externalRole === role);
    return item.approle;
}

export function roleSolver(externalRoles) {
    const roles = [];

    // Return empty roles array if External roles are empty or is not array
    if (isEmpty(externalRoles) || !Array.isArray(externalRoles)) {
        return roles;
    }

    externalRoles.forEach((item) => {

        let approle = getRole(item);
               
        if (!isEmpty(approle)) {
            roles.push(approle);
        } 
    });
    
    return roles;
}