import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { mainStatusText } from "../../views/Job/messages";
import moment from "moment";

const StatusBadge = (props) => {
    const [badgeType, setBadgeType] = useState('');
    const [statusText, setStatusText] = useState('');
    const lng = props.lng;
    const dateNow = moment(new Date()).format('YYYY-MM-DD');
    
    /*
    * Form type and text for badge
    * */
    function formBadge(props) {
        
        if (props.statusCode !== undefined && props.statusCode !== null) {
            switch (props.statusCode) {
                case 0:
                    // If duedate is due show status 'late'
                    if (props.dueDate !== undefined && props.dueDate < dateNow) {
                        setBadgeType('status-late');
                        setStatusText(lng(mainStatusText.late));
                    } else {
                        setBadgeType('status-invoice');
                        setStatusText(lng(mainStatusText.invoice));
                    }
                    break;
                case 1:
                    setBadgeType('status-reminder');
                    setStatusText(lng(mainStatusText.reminder));
                    break;
                case 2:
                    setBadgeType('status-collection');
                    setStatusText(lng(mainStatusText.collection));
                    break;
                case 101:
                    setBadgeType('status-paid');
                    setStatusText(lng(mainStatusText.paid));
                    break;
            }
        }
    }
    
    useEffect( () => {
        formBadge(props);
    });
    
    return (
        <div className={'badge ' + badgeType}>
            {statusText}
        </div>
    );
};

StatusBadge.propTypes = {
    lng: PropTypes.any,
    statusCode: PropTypes.number,
    dueDate: PropTypes.string,
};

export default StatusBadge;