import React, { useEffect, useState } from "react";
import { injectIntl, defineMessages } from "react-intl";
import LiveChat from "../LiveChat";
import PropTypes from 'prop-types';
import isEmpty from "lodash.isempty";
import { getCookieConsentValue } from "react-cookie-consent";
import CookieConsent from "../CookieConsent";

export const messages = defineMessages({
    toggleChat: {
        id: "Avaa Chat",
        defaultMessage: "Avaa Chat"
    }
});

/**
 * Add disabled class to chatbuttons.
 * 
 * @param sidemenuChatButton
 * @param supportMenuChatButton
 */
export function disableChatButtons(sidemenuChatButton, supportMenuChatButton) {

    if (!isEmpty(supportMenuChatButton)) {
        supportMenuChatButton.classList.add('listbutton-disabled');
    }
    if (!isEmpty(sidemenuChatButton)) {
        sidemenuChatButton.classList.add('listbutton-disabled-sidemenu');
    }
}

/**
 * Remove disabled class from chatbuttons.
 * 
 * @param sidemenuChatButton
 * @param supportMenuChatButton
 */
export function showChatButtons(sidemenuChatButton, supportMenuChatButton) {

    if (!isEmpty(supportMenuChatButton)) {
        supportMenuChatButton.classList.remove('listbutton-disabled');
    }
    if (!isEmpty(sidemenuChatButton)) {
        sidemenuChatButton.classList.remove('listbutton-disabled-sidemenu');
    }
}


const ToggleChatButton = (props) => {
    const lng = props.intl.formatMessage;
    const liveChatLicenseId = process.env.REACT_APP_LIVECHAT_LICENSE_ID;
    const liveChatParameters = props.parameters;
    const liveChatVisitor = props.visitor;
    const [isChatOpen, setIsChatOpen] = useState(false);

    let isChatEnabled = false;

    // does the environment allow the use of chat?
    if (typeof process.env.REACT_APP_SHOW_CHAT !== 'undefined' && process.env.REACT_APP_SHOW_CHAT.toString() === '1') {
        isChatEnabled = true;
    }

    useEffect(() => {
        const supportMenuChatButton = document.querySelector('#toggle-chat-window');
        const sidemenuChatButton = document.querySelector('#toggle-chat-sidemenu');

        // disable chat buttons, if chat is open, or cookie consent has not been accepted
        if (document.getElementById('chat-widget-container') || getCookieConsentValue() === undefined || getCookieConsentValue() === false || isChatOpen) {
            disableChatButtons(sidemenuChatButton, supportMenuChatButton);
        } else {
            showChatButtons(sidemenuChatButton, supportMenuChatButton);
        }
    });

    /**
     * Open chat, if cookies have been accepted.
     */
    function cookiesUpdated() {
        if (getCookieConsentValue()) {
            setIsChatOpen(true);
        } else {
            setIsChatOpen(false);
        }
    }


    return (
        <>
            {/* Show chat button(s) and and open chat in environments that allow it */}
            {isChatEnabled && (
                <>
                    {props.location === 'sidemenu' ? (
                        <CookieConsent
                            openedFrom={'sidemenu'}
                            buttonId={'toggle-chat-sidemenu'}
                            buttonTitle={lng(messages.toggleChat)}
                            buttonStyling={'btn actionlist-button btn-tertiary-sidemenu'}
                            buttonText={lng(messages.toggleChat)}
                            popoverStyling={'sidemenu-popover'}
                            cookiesUpdated={cookiesUpdated}
                            {...props}
                        />
                    ) : (
                        <CookieConsent
                            openedFrom={'invoice'}
                            buttonId={'toggle-chat-window'}
                            buttonTitle={lng(messages.toggleChat)}
                            buttonStyling={'btn actionlist-button btn-tertiary'}
                            buttonText={lng(messages.toggleChat)}
                            cookiesUpdated={cookiesUpdated}
                            {...props}
                        />
                    )}

                    {isChatOpen && (
                        <LiveChat id="livechat" license={liveChatLicenseId} visitor={liveChatVisitor} params={liveChatParameters} />
                    )}
                </>
            )}
        </>
    )
};

ToggleChatButton.propTypes = {
    'intl': PropTypes.object.isRequired,
    'parameters': PropTypes.any,
    'visitor': PropTypes.any,
    'location': PropTypes.string,
};

export default injectIntl(ToggleChatButton);