import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, FormSelect, FormGroup, FormControl, Placeholder, FormCheck } from "react-bootstrap";
import { defineMessages } from "react-intl";
import PropTypes from 'prop-types';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@fortawesome/pro-regular-svg-icons";
import isEmpty from "lodash.isempty";
import { debtorStatusMessages } from '../../messages';

//services
import { userRequestHeaders, userGetLocale, userGetCustomerType, CUSTOMERTYPE, userGetName } from "../../../../services/user";
import { mapLocaleToBCP47, validate, isFormEmpty } from "../../../../services/helpers";

import AlertDialog from "../../../../components/AlertDialog";
import TextInputWithAttachments from "../../../../components/TextInputWithAttachments";

// strings for translations
const messages = defineMessages({
    backToInvoice: {
        id: "Palaa laskun tietoihin",
        defaultMessage: "Palaa laskun tietoihin"
    },
    title: {
        id: "Aloita uusi viestiketju",
        defaultMessage: "Aloita uusi viestiketju"
    },
    chooseTopicLabel: {
        id: "Valitse, mitä asiasi koskee.",
        defaultMessage: "Valitse, mitä asiasi koskee."
    },
    chooseTopic: {
        id: "Valitse aihe",
        defaultMessage: "Valitse aihe"
    },
    send: {
        id: "Lähetä",
        defaultMessage: "Lähetä"
    },
    authError: {
        id: 'Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.',
        defaultMessage: 'Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.'
    },
    notFound: {
        id: "Yhteydenottolomakkeita ei ole saatavilla juuri nyt. Yritä myöhemmin uudelleen.",
        defaultMessage: "Yhteydenottolomakkeita ei ole saatavilla juuri nyt. Yritä myöhemmin uudelleen."
    },
    errorOccurredTryAgain: {
        id: "Tapahtui virhe. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tapahtui virhe. Ole hyvä ja yritä uudelleen."
    },
    cancel: {
        id: "Peruuta",
        defaultMessage: "Peruuta"
    },
    emailOrSmsRequired: {
        id: "Sinun tulee ilmoittaa joko sähköpostiosoite tai puhelinnumero.",
        defaultMessage: "Sinun tulee ilmoittaa joko sähköpostiosoite tai puhelinnumero."
    },
    modalCLoseHeader: {
        id: "Poistu tallentamatta",
        defaultMessage: "Poistu tallentamatta"
    },
    modalCloseConfirmation: {
        id: "Viestissäsi on tallentamattomia muutoksia. Oletko varma, että haluat sulkea viestin ja hylätä luonnoksen?",
        defaultMessage: "Viestissäsi on tallentamattomia muutoksia. Oletko varma, että haluat sulkea viestin ja hylätä luonnoksen?"
    },
    modalCloseClose: {
        id: "Sulje ja hylkää luonnos",
        defaultMessage: "Sulje ja hylkää luonnos"
    },
    modalCloseKeepEdit: {
        id: "Jatka kirjoittamista",
        defaultMessage: "Jatka kirjoittamista"
    },
    invoiceNumber: {
        id: "Asianumero",
        defaultMessage: "Asianumero"
    },
    status: {
        id: "Laskun tila",
        defaultMessage: "Laskun tila"
    }
});

export { messages };

const MessageCenterModal = (props) => {
    const lng = props.intl.formatMessage;
    const formRef = useRef(null);
    const [forms, setForms] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        message: '',
        type: ''
    });
    const [formFields, setFormFields] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [attachments, setAttachments] = useState([]);
    const [loadingFormContent, setLoadingFormContent] = useState(false);
    const [confirmClose, setConfirmClose] = useState(false);
    const [attachmentsEnabled, setAttachmentsEnabled] = useState(false);
    const [userNameFromUserData, setUserNameFromUserData] = useState(false);
    let exludedElementIds = ["message-center-form-option"]; // changes in the dropdown value do not need to be checked when closing the form
    const count = 5; // number of placeholder skeletons
    const backendRequirements = ['request_type_id', 'summary', 'description', 'notification_email', 'notification_sms', 'job_id', 'dropdown_value']; // backend requires these keys to work
    
    // Attachment configurations
    const acceptedFileTypes = '.jpg,.jpeg,.png,.svg,.tif,.tiff,.gif,.bmp,.docx,.doc,.odt,.xlsx,.xls,.ods,.pptx,.ppt,.odp,.pdf,.txt,.csv';
    const acceptedFileSize = 30;
    const byteConversionFactor = 1024 * 1024;
    const sizeUnit = 'MB';
    const acceptedFileAmount = 10;
    
    useEffect(() => {
        // get available message center forms
        if (forms.length === 0) {
            getForms();
        }

    }, []);

    useEffect(() => {
        if (props.alert.show && props.alert.message !== "" && props.alert.type !== "") {
            showAlerts(props.alert);
        } else {
            clearAlerts();
        }

    }, [props.alert]);

    useEffect(() => {
        if (props.clearMessageCenterModal) {
            resetValuesAndClose();
        }

    }, [props.clearMessageCenterModal]);

    /**
     * Get available message center form templates
     */
    function getForms() {
        const headers = userRequestHeaders();
        let formOptions = [];

        // clear any error messages
        clearAlerts();

        // get available message center forms
        axios({
            method: 'GET',
            url: process.env.REACT_APP_API_URL + '/online/messagecenter/forms?language=' + mapLocaleToBCP47(userGetLocale()),
            headers: headers
        }).then(function (response) {
            formOptions = response.data.form;
            setForms(formOptions);
        }).catch(function (error) {
            if (error.response?.status === 401) {
                // session expired, log user out
                const alert = { message: lng(messages.authError), type: 'danger' };
                props.history.push({
                    pathname: "/logout",
                    alert
                });
            } else if (error.response?.status === 404) {
                showAlerts({
                    message: lng(messages.notFound),
                    type: 'warning'
                });
            } else {
                showAlerts({
                    message: lng(messages.errorOccurredTryAgain),
                    type: 'danger'
                });
            }
        });
    }

    

    /**
     * Get contents/fields of a single form template.
     */
    function getFormContents(formId) {
        // clear any error messages
        clearAlerts();

        if (formId !== '') {
            // add job and form id for later use 
            setInputValues({ 'job_id': props.jobid, 'request_type_id': formId });
            // show skeleton placeholders while getting content
            setLoadingFormContent(true);

            const headers = userRequestHeaders();

            // get contents/fields of a message center form template
            axios({
                method: 'GET',
                url: process.env.REACT_APP_API_URL + '/online/messagecenter/form?formid=' + formId + '&language=' + mapLocaleToBCP47(userGetLocale()),
                headers: headers
            }).then(function (response) {
                // check if response data needs to be modified and set form fields
                setFormFields(modifyResponseData(response.data.fields));

                // toggle skeletons off
                setLoadingFormContent(false);

                // Find the form object that matches the given formId
                const form = forms.find(form => form.form_id === formId);

                // Extract helptext from the found form
                const formDetails = form ? form.help_text : '';

                if (formDetails !== '') {
                    showAlerts({
                        message: formDetails,
                        type: 'primary'
                    });
                }

            }).catch(function (error) {
                // toggle skeletons off
                setLoadingFormContent(false);

                if (error?.response?.status === 401) {
                    // session expired, log user out
                    const alert = { message: lng(messages.authError), type: 'danger' };
                    props.history.push({
                        pathname: "/logout",
                        alert
                    });
                } else if (error?.response?.status === 404) {
                    // forms not found
                    showAlerts({
                        message: lng(messages.notFound),
                        type: 'warning'
                    });
                } else {
                    showAlerts({
                        message: lng(messages.errorOccurredTryAgain),
                        type: 'danger'
                    });
                }
            });
        } else {
            setFormFields([]);
            setAttachmentsEnabled(false);
        }
    }

    /**
     * Set alert message and type, and show alertDialog.
     *
     * @param {*} alert
     */
    function showAlerts(alert) {
        setAlert({
            message: alert.message,
            type: alert.type
        });

        setShowAlert(true);
    }

    /**
     * Clear error messages and hide alertDialog.
     */
    function clearAlerts() {
        setAlert({
            message: '',
            type: ''
        });

        setShowAlert(false);
    }

    /**
     * Check if user name is available for person
     * @returns {boolean}
     */
    function canGetUserNameForPerson() {
        const userName = userGetName();
        const customerType = userGetCustomerType();
        
        return (customerType === CUSTOMERTYPE.PERSON && typeof userName !== 'undefined' && userName !== '');
    }
    
    /**
     * Check for modification needs in data, e.g. change customfields field_id's to meet backend requirements.
     *
     * @param {*} data
     */
    function modifyResponseData(data) {
        let modifiedData = data;

        // loop data to check if any changes to fields should be done
        modifiedData.forEach((item, index, object) => {
            if (item.field_id === 'attachment') {
                // Remove attachment field and enable it as part of the description component
                object.splice(index, 1);
                setAttachmentsEnabled(true);
            } else if (item.field_id === 'end_customer_name' && canGetUserNameForPerson()) {
                // Remove Name field for person, if name is available
                object.splice(index, 1);
                setUserNameFromUserData(true);
            }
        });

        return modifiedData;
    }


    /**
     * Handle changes in input and remove errors.
     *
     * @param {*} e
     */
    function handleInputs(e) {
        // remove validation errors
        if (e.target.checkValidity()) {
            e.target.classList.remove("is-invalid");
        }

        setInputValues({ 
            ...inputValues, 
            [e.target.id]: e.target.value 
        });
    }
    
    /**
     * Handle attachments and set them to state.
     * @param attachments
     */
    function handleAttachments(attachments) {
        setAttachments(attachments);
    }

    /**
     * Check the form before closing to see if it is empty or not.
     *
     * Display a confirmation, if the form is not empty (message will be discarded).
     * Close the form without additional confirmations, if the form is empty.
     */
    function handleHide() {

        if (!isFormEmpty(formRef.current, exludedElementIds)) {
            // show confirmation to user
            setConfirmClose(true);
        } else {
            // clear form values and close the modal
            resetValuesAndClose();
        }
    }

    /**
     * Hide confirmation and allow the user to keep writing, or discard message and
     * hide modal.
     *
     * @param {*} bool Hide modal
     */
    function handleCloseConfirmation(bool) {
        // hide modal if user has confirmed it
        if (bool) {
            // clear dropdown values and close the modal
            resetValuesAndClose();
        } else {
            // hide confirmation to keep writing
            setConfirmClose(false);
        }
    }

    /**
     * Map data for submit purposes.
     * 
     * If the form contains fields that do not meet the backend requirements, 
     * add the content of those fields to 'description'.
     * 
     * If form has a input with options, add that option value to dropdown_value,
     * otherwise it must be kept null.
     * 
     *  
     * @param {*} data 
     */
    function mapDataForSubmit(data) {
        let requestData = {
            request_type_id: data.request_type_id,
            summary: data.summary,
            description: data.description,
            notification_email: data.notification_email,
            notification_sms: data.notification_sms,
            job_id: data.job_id,
            dropdown_value: data.dropdown_value,
            custom_fields: {}
        };

        // go through each field
        for (const [key, value] of Object.entries(data)) {

            if (!backendRequirements.includes(key)) {

                // if there are fields that don't meet the backend requirement, add them to custom fields
                if (key !== 'object_ids') {
                    requestData.custom_fields[key] = value;
                } else {
                    const label = document.querySelector("label[for=" + key +"]").innerText ?? key;
                    // if there are fields that don't meet the backend requirement, add user input to description 
                    requestData.description += " \n" + label + ": " + value;
                }
            }
        }
        
        let objectIds = [];
        
        // Add object_ids for attachments
        for (const attachment of attachments) {
            if (typeof attachment.objectid !== 'undefined' && attachment.objectid !== '') {
                objectIds.push(attachment.objectid);
            }
        }
        requestData.object_ids = objectIds;
        
        // Add user name to custom fields from user data, if needed
        if (userNameFromUserData) {
            requestData.custom_fields['end_customer_name'] = userGetName();
        }

        // return request data that is ready for submit
        return requestData;
    }

    /**
     * Handle submitting the form. Check that form passes validation 
     * and then prepare the data for submit.
     *
     * @param {*} e
     */
    function handleSubmit(e) {
        e.preventDefault();
        clearAlerts();

        // Custom validation for 'tel' and 'email' fields
        let isValid = validate(e.currentTarget);

        let isTelOrEmailFilled = false;

        formFields.forEach((i) => {

            if (i.type === 'tel' || i.type === 'email') {
                if (inputValues[i.field_id]) {
                    isTelOrEmailFilled = true;
                } 
            }

            if (i.field_id === 'dropdown_value') {
                if (inputValues[i.field_id]) {
                    document.getElementById("dropdown_value").classList.remove("is-invalid");
                } else {
                    document.getElementById("dropdown_value").classList.add("is-invalid");
                }
            }
        });

        if (!isTelOrEmailFilled) {
            isValid = false;
            showAlerts({
                message: lng(messages.emailOrSmsRequired),
                type: 'danger'
            });

            document.getElementById("notification_email").classList.add("is-invalid");
            document.getElementById("notification_sms").classList.add("is-invalid");
        }

        if (isValid) {
            // prepare data for submit
            const data = mapDataForSubmit(inputValues);
            props.handleSubmit(data);
        }
    }

    /**
     * Reset form values and close the modal
     */
    function resetValuesAndClose() {
        // clear alerts, dropdown and input values
        clearAlerts();
        setFormFields([]);
        setInputValues({});
        setAttachmentsEnabled(false);

        // ensure close confirmation if hidden and close the modal
        setConfirmClose(false);
        props.hide();
    }

    // Function to update comment submit value
    const updateCommentSubmitValue = (value) => { return true; };

    return (
        <Modal id="message-center-modal" show={props.show} dialogClassName="message-center-form" onHide={handleHide} aria-labelledby="modal-title-message-center-forms" size={'lg'} backdrop="static">
            <div className="form-modal-parent">
                <div className={confirmClose ? "offcanvas-modal offcanvas offcanvas-start show " : ""}>
                    {confirmClose && (
                        <div>
                            <h3>{lng(messages.modalCLoseHeader)}</h3>
                            <div className="mt-4">
                                <h5>{lng(messages.modalCloseConfirmation)}</h5>
                            </div>
                            <div className="d-grid d-sm-block mt-3">
                                <Button id="close-confirmation-close" onClick={() => handleCloseConfirmation(true)} >{lng(messages.modalCloseClose)}</Button>
                                <Button id="close-confirmation-cancel" onClick={() => handleCloseConfirmation(false)} variant="tertiary">{lng(messages.modalCloseKeepEdit)}</Button>
                            </div>
                        </div>
                    )}
                </div>

                <Modal.Header closeButton>
                    <Button className="modal-back" onClick={handleHide}>
                        <FontAwesomeIcon icon={far.faArrowLeft} /> {lng(messages.backToInvoice)}
                    </Button>
                    <Modal.Title as="h4" id="modal-title-message-center-forms">
                        {lng(messages.title)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="form-modal-sub-title">
                        <p>{lng(messages.invoiceNumber)}: {props.jobid}, {typeof debtorStatusMessages['statuscode' + props.statuscode] !== "undefined" && debtorStatusMessages['statuscode' + props.statuscode] !== "" ? lng(messages.status) +': '+ lng(debtorStatusMessages['statuscode' + props.statuscode]) : ''} </p>
                    </div>

                    {showAlert && (
                        <AlertDialog
                            message={alert.message}
                            type={alert.type}
                        />
                    )}

                    <Form id="message-center-form" onSubmit={handleSubmit} ref={formRef} noValidate>
                        {/* List all available forms */}
                        {
                            forms.length > 0 && (
                                <FormGroup className="form-floating">
                                    <FormSelect id="message-center-form-option" role="select" className="form-control mb-3" onChange={(e) => getFormContents(e.target.value)} placeholder={lng(messages.chooseTopicLabel)}  >
                                        <option id={lng(messages.chooseTopic)} key={lng(messages.chooseTopic)} value="" >{lng(messages.chooseTopic)}</option>
                                        {forms.map((option) => {
                                            return (
                                                <option id={option.form_id} key={option.form_id} value={option.form_id}>{option.form_name}</option>
                                            )
                                        })}
                                    </FormSelect>
                                    <Form.Label htmlFor='message-center-form-option'>{lng(messages.chooseTopicLabel)}</Form.Label>
                                    <Form.Control.Feedback type="invalid" />
                                </FormGroup>
                            )
                        }
                        {loadingFormContent ? (
                            <div>
                                {/* show skeleton placeholders while loading */}
                                {Array(count).fill(null).map((value, index) => (
                                    <Placeholder as={FormGroup} animation="glow" className="mb-3" style={{ opacity: 1 / (index + 1) }} key={"skeleton-input-" + index}>
                                        <Placeholder as={FormControl} ></Placeholder>
                                    </Placeholder>
                                )
                                )}
                            </div>
                        ) : (
                            <>
                                {/* list contents of the chosen form template */}
                                {formFields.length > 0 && (
                                    <>
                                        {formFields.map((i) => {

                                            if (i.field_id.toLowerCase() === 'notification_sms') {
                                                i.type='tel';
                                            } else if (i.field_id.toLowerCase() === 'notification_email') {
                                                i.type='email';
                                            } 

                                            // only show fields that are not marked HIDDEN, and have a name
                                            if (i.description.toUpperCase() !== 'HIDDEN' && !isEmpty(i.name)) {
                                                
                                                if (i.type === 'any') {
                                                    // radio inputs, only show when options are also available
                                                    if (i.options.length > 0) {
                                                        return (
                                                            <FormGroup id={ i.field_id } key={ i.field_id } className="mb-3">
                                                                <Form.Label>{ i.name }</Form.Label>
                                                                { i.options.map((option) => {
                                                                    return (
                                                                        <FormCheck
                                                                            type='radio'
                                                                            id={ i.field_id }
                                                                            name={ 'option-' + option.object_key }
                                                                            key={ option.object_key }
                                                                            value={ option.object_key }
                                                                            label={ option.label }
                                                                            onChange={ (e) => handleInputs(e) }
                                                                            checked={ inputValues[i.field_id] === option.object_key ? inputValues[i.field_id] : false }
                                                                        />
                                                                    );
                                                                }) }
                                                            </FormGroup>
                                                        );
                                                    }
                                                } else if (i.field_id.toLowerCase() !== 'description') {
                                                    // regular input fields
                                                    return (
                                                        <FormGroup key={i.field_id}>
                                                            <Form.Floating className="mb-3">
                                                                <Form.Control
                                                                    id={i.field_id}
                                                                    type={i.type}
                                                                    required={parseInt(i.required) === 1}
                                                                    placeholder={i.description}
                                                                    onChange={(e) => handleInputs(e)}
                                                                    pattern={i.type === 'tel' ? '^\\+\\d{1,14}$' : undefined}

                                                                />
                                                                <Form.Label htmlFor={i.field_id}>{i.name}</Form.Label>
                                                                <Form.Control.Feedback type="invalid" />
                                                            </Form.Floating>
                                                        </FormGroup>
                                                    );
                                                } else {
                                                    return (
                                                        <FormGroup key={i.field_id}>
                                                            <Form.Floating>
                                                                <TextInputWithAttachments
                                                                    intl={props.intl}
                                                                    fieldId={i.field_id}
                                                                    fieldName={i.description}
                                                                    required={true}
                                                                    acceptedFileAmount={acceptedFileAmount}
                                                                    attachmentsEnabled={attachmentsEnabled}
                                                                    acceptedFileTypes={acceptedFileTypes}
                                                                    acceptedFileSize={acceptedFileSize}
                                                                    byteConversionFactor={byteConversionFactor}
                                                                    sizeUnit={sizeUnit}
                                                                    handleInputChange={(e) => handleInputs(e)}
                                                                    handleAttachments={(attachments) => handleAttachments(attachments)}
                                                                    history={props.history}
                                                                    updateCommentSubmitValue={updateCommentSubmitValue}
                                                                />
                                                            </Form.Floating>
                                                        </FormGroup>
                                                    );
                                                }
                                            }
                                        })}
                                    </>
                                )}
                            </>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="message-center-modal-form-send" form="message-center-form" variant="primary" type="submit" disabled={formFields.length <= 0}>
                        {lng(messages.send)}
                    </Button>
                    <Button id="message-center-modal-form-cancel" onClick={() => handleHide()} variant="tertiary">{lng(messages.cancel)}</Button>
                </Modal.Footer>
            </div>
        </Modal >
    )
};

MessageCenterModal.propTypes = {
    'intl': PropTypes.object.isRequired,
    'show': PropTypes.bool,
    'hide': PropTypes.func,
    'handleSubmit': PropTypes.func,
    'jobid': PropTypes.number,
    'statuscode': PropTypes.number,
    'alert': PropTypes.object,
    'history': PropTypes.object,
    'clearMessageCenterModal': PropTypes.bool
};

export default MessageCenterModal;