import React, { useEffect, useState } from "react";
import { Button, FormGroup, Modal, FormSelect, Row, Col } from "react-bootstrap";
import { defineMessages } from "react-intl";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Services
import { userRequestHeaders, userLogout } from "../../services/user/index";
import { getIsoDateTime, parseLinkVariable } from "../../services/helpers/index";
import AlertDialog from "../AlertDialog";
import { far } from "@fortawesome/pro-regular-svg-icons";
import PropTypes from "prop-types";

const messages = defineMessages({
    modalTitle: {
        id: "Kuinka todennäköisesti suosittelisit MyRopon käyttöä?",
        defaultMessage: "Kuinka todennäköisesti suosittelisit MyRopon käyttöä?"
    },
    textareaPlaceholder: {
        id: "Otamme mielellämme vastaan avointa palautetta ja kehitysehdotuksia",
        defaultMessage: "Otamme mielellämme vastaan avointa palautetta ja kehitysehdotuksia"
    },
    successLogout: {
        id: "Olet kirjautunut ulos",
        defaultMessage: "Olet kirjautunut ulos"
    },
    actionSend: {
        id: "Kirjaudu ulos",
        defaultMessage: "Kirjaudu ulos"
    },
    actionCancel: {
        id: "Peruuta",
        defaultMessage: "Peruuta"
    },
    feedbackexcepttion: {
        id: "Palauteen lähettäminen ei onnistunut",
        defaultMessage: "Palauteen lähettäminen ei onnistunut"
    },
    successfeedback: {
        id: "Kiitos palautteesta",
        defaultMessage: "Kiitos palautteesta"
    },
    feedbackInfoParagraph1: {
        id: "Valitettavasti emme voi vastata tämän palautelomakkeen kautta jätettyihin yhteydenottopyyntöihin.",
        defaultMessage: "Valitettavasti emme voi vastata tämän palautelomakkeen kautta jätettyihin yhteydenottopyyntöihin."
    },
    feedbackInfoParagraph2: {
        id: "Otathan tarvittaessa yhteyttä suoraan asiakaspalveluumme.",
        defaultMessage: "Otathan tarvittaessa yhteyttä suoraan asiakaspalveluumme."
    },
    customerSurveyParagraph1: {
        id: "Osallistu Ropon asiakastutkimukseen.",
        defaultMessage: "Osallistu Ropon asiakastutkimukseen."
    },
    customerSurveyParagraph2: {
        id: "Arvomme vastaajien kesken lahjakortteja.",
        defaultMessage: "Arvomme vastaajien kesken lahjakortteja."
    },
    logoutMessage: {
        id: "Haluatko varmasti kirjautua ulos?",
        defaultMessage: "Haluatko varmasti kirjautua ulos?"
    },
    errorChooseSubject: {
        id: "Ole hyvä ja valitse aihe.",
        defaultMessage: "Ole hyvä ja valitse aihe."
    },
    errorFillTextbox: {
        id: "Ole hyvä ja täytä tekstikenttä.",
        defaultMessage: "Ole hyvä ja täytä tekstikenttä."
    },
    actionSendAndLogout: {
        id: "Lähetä ja kirjaudu ulos",
        defaultMessage: "Lähetä ja kirjaudu ulos"
    },
    scaleExplanation: {
        id: "(0 = erittäin epätodennäköisesti – 10 = erittäin todennäköisesti)",
        defaultMessage: "(0 = erittäin epätodennäköisesti – 10 = erittäin todennäköisesti)"
    },
    dropdownHeader: {
        id: "Kehitysehdotukset ja palaute",
        defaultMessage: "Kehitysehdotukset ja palaute"
    },
    chooseSubject: {
        id: "Valitse aihe",
        defaultMessage: "Valitse aihe"
    },
    developmentProposal: {
        id: "Kehitysehdotus",
        defaultMessage: "Kehitysehdotus"
    },
    payment: {
        id: "Maksaminen",
        defaultMessage: "Maksaminen"
    },
    paymentPlan: {
        id: "Maksuaika",
        defaultMessage: "Maksuaika"
    },
    refund: {
        id: "Maksun palautus",
        defaultMessage: "Maksun palautus"
    },
    useOfService: {
        id: "Palvelun käyttö",
        defaultMessage: "Palvelun käyttö"
    },
    RopoCapitalInGeneral: {
        id: "Ropo yleisesti",
        defaultMessage: "Ropo yleisesti"
    },
    other: {
        id: "Muu",
        defaultMessage: "Muu"
    },
    goBack: {
        id: "Palaa takaisin",
        defaultMessage: "Palaa takaisin"
    }
});

export { messages };


const FeedbackDialog = (props) => {
    const [messageInput, setMessageInput] = useState('');

    const lng = props.intl.formatMessage;
    const locale = localStorage.getItem('locale');
	const customerServiceLink = getCustomerServiceLinkByLocale(locale);

    const [NPSScore, setNPSScore] = useState(null);
    const [feedbackTopic, setFeedbackTopic] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [scoreFilled, setScoreFilled] = useState(false);
    const [topicFilled, setTopicFilled] = useState(false);
    const [textAreaFilled, setTextAreafilled] = useState(false);
    const [changeButtonText, setChangeButtonText] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState('');
    const [showAlertType, setShowAlertType] = useState(undefined);

    //  NPS survey's numeric scale 0-10
    const surveyScoreScale = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    let showCustomerSurvey = false;
    let alert = "";

    // Customer survey link
    if (process.env.REACT_APP_CUSTOMER_SURVEY_URL && process.env.REACT_APP_CUSTOMER_SURVEY_URL !== '') {
        showCustomerSurvey = true;
    }

    let feedBackToggle = false;

    // Is customer feedback on or off
    if (typeof process.env.REACT_APP_FEEDBACK_TOGGLE !== 'undefined' && process.env.REACT_APP_FEEDBACK_TOGGLE.toString() === '1') {
        feedBackToggle = true;
    }

    useEffect(() => {
        setDropdownOptions([
            { value: '', topic: lng(messages.chooseSubject) },
            { value: 'Development proposal', topic: lng(messages.developmentProposal) },
            { value: 'Payment', topic: lng(messages.payment) },
            { value: 'Payment plan', topic: lng(messages.paymentPlan) },
            { value: 'Refund', topic: lng(messages.refund) },
            { value: 'Use of service', topic: lng(messages.useOfService) },
            { value: 'Ropo Capital in general', topic: lng(messages.RopoCapitalInGeneral) },
            { value: 'Other', topic: lng(messages.other) },
        ]);
    }, []);

    useEffect(() => {
        
        if (props.show && assumeMobile()) {
            document.getElementById('root').classList.add('blur-effect');
        }
        else if (!props.show && assumeMobile()) {
            document.getElementById('root').classList.remove('blur-effect');
        }
    },[props.show]);

    useEffect(() =>{
        // toggle logout button text if there are changes in the input values
        if (scoreFilled || topicFilled || textAreaFilled){
            setChangeButtonText(true);
        }
        else {
            setChangeButtonText(false);
        }

    }, [scoreFilled, topicFilled, textAreaFilled]);


    /**
     * Return the customer service link for given locale if it exists in the environment variable
     * @param locale
     */
	function getCustomerServiceLinkByLocale(locale) {
		return parseLinkVariable(locale, process.env.REACT_APP_LINK_CUSTOMER_SERVICE);
	}

    function assumeMobile() {
        return window.matchMedia("(max-width: 960px)").matches;
    }

    // handle changes in the NPS score
    function handleUpdateScore(event) {
        // all numeric scale buttons
        const NPSScoreButtonsList = document.querySelectorAll('.feedback-btn-circle');
        // toggle logout button text
        setScoreFilled(true);

        // check wheter or not the button should have class 'selected-score'
        if (event.target.value === NPSScore) {
            // if same number is clicked, set NPS score back to original value and remove class
            setNPSScore(null);
            event.target.classList.remove('selected-score');
            // toggle logout button text
            setScoreFilled(false);
        }
        else {
            // go through buttons and remove selected class
            NPSScoreButtonsList.forEach(item => {
                if (item.classList.contains('selected-score')) {
                    item.classList.remove('selected-score');
                }
            });

            // add class to selected score
            event.target.classList.add('selected-score');
            // set new score
            setNPSScore(event.target.value);
        }
    }

    // handle changes in the dropdown
    function handleSelectChange(event) {

        // toggle logout button text
        if (event.target.value === '') {
            setTopicFilled(false);
            // also remove errors from textarea when topic is set back to original value
            setShowAlert(false);
        }
        else {
            setTopicFilled(true);
        }

        // remove errors when changes in the dropdown value
        setShowAlert(false);
        setFeedbackTopic(event.target.value);
    }

    // handle changes in the open feedback textarea
    function handleInputChange(event) {

        // toggle logout button text
        if (event.target.value.length === 0) {
            setTextAreafilled(false);
            // also remove errors from dropdown when textarea is empty
            setShowAlert(false);
        }
        else {
            setTextAreafilled(true);
        }

        // remove errors from textarea when changes in the input
        setShowAlert(false);
        setMessageInput(event.target.value);
    }

    function hideDialog() {
        props.handleConfirmDialog();
        setFeedbackTopic('');
        setMessageInput('');
        setChangeButtonText(false);
        if (assumeMobile()) {
            document.getElementById('root').classList.remove('blur-effect');
        }
    }

    /**
     * Function to send feedback json to backend.
     * @returns null if validation fails
     */

    function sendFeedback() {
        // feedback is not sent if it is not given (no score and/or open feedback)
        if (NPSScore !== null || (feedbackTopic !== "" && messageInput !== "")) {
            const headers = userRequestHeaders();
            // use null for topic and feedback, if they are empty
            let topic = feedbackTopic === "" ? null : feedbackTopic;
            let feedback = messageInput === "" ? null : messageInput;
            alert = {message: lng(messages.successfeedback), type: 'primary'};
        
            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/online/feedback',
                headers: headers,
                data: JSON.stringify({
                    "created_at": getIsoDateTime(),
                    "data": [
                        {
                            "value": NPSScore,
                            "topic": topic,
                            "feedback": feedback
                        }
                    ]
                }),
            })
                .catch(function (error) {
                    props.history.push("/");
                });
        }
    };
    
    /**
     * Handle alertDialog. Function takes message and type as parameters
     * and show the alertdialog above all the details related to the invoice.
     *
     * @param {*} message
     * @param {*} type
     */
    function handleAlertDialog(message, type) {
        setShowAlertMessage(message);
        setShowAlertType(type);
        
        //show alertDialog
        setShowAlert(true);
    };
    
    // handle submitting the form
    function handleSubmitFeedback(event){
        event.preventDefault();
        // remove all errors
        setShowAlert(false);
        
        // check if mandatory combinations (topic + open feedback) are missing
        if (feedbackTopic !== '' && messageInput.length === 0) {
            // if feedback topic has been chosen but no open feedback is left, show error
            handleAlertDialog(lng(messages.errorFillTextbox), "danger");
        }
        else if (feedbackTopic === '' && messageInput.length > 0) {
            // if open feedback is left but no topic is chosen, show error
            handleAlertDialog(lng(messages.errorChooseSubject), "danger");
        }
        else {
            confirmLogout();
        }
    }

    function confirmLogout() {
        sendFeedback();
        hideDialog();
        userLogout();

        props.history.push({
            pathname: "/",
            alert
        });
        
        // Close and hide chat when user logs out
        if (window.LC_API) {
            window.LC_API.close_chat();
            window.LC_API.hide_chat_window();
        }
    }
    
    return (
        <>
            { feedBackToggle ? (
                <Modal data-testid="feedback-dialog-modal" name="feedback-dialog" show={ props.show || props.canShow } onHide={ hideDialog } dialogClassName={ "dialog-feedback" } style={ {zIndex: 10001 } }>
                    <Modal.Header closeButton>
                        <Button className="modal-back" onClick={ hideDialog }>
                            <FontAwesomeIcon icon={ far.faArrowLeft } /> { lng(messages.goBack) }
                        </Button>
                        <Modal.Title>
                            { lng(messages.modalTitle) }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form" id="exit-survey-form" onSubmit={ handleSubmitFeedback }>
                            <div className="text-center">
                                <label htmlFor="exit-survey-nps-scale">{ lng(messages.scaleExplanation) }</label>
                                <div id="exit-survey-nps-scale" className="form-group nps-button-group mb-3">
                                    { surveyScoreScale.map((option) => {
                                        return (
                                            <button type="button" className="feedback-btn-circle" onClick={ (e) => handleUpdateScore(e) } value={ option } key={ option }>{ option }</button>
                                        )
                                    }) }
                                </div>
                                <label className="h4" htmlFor="exit-survey-feedback-topic">{ lng(messages.dropdownHeader) }</label>
                            </div>
                            { showAlert &&
                                <AlertDialog
                                    message={ showAlertMessage }
                                    type={ showAlertType }
                                />
                            }
                            <FormGroup controlId="exit-survey-feedback-topic" className="form-floating">
                                <FormSelect id="exit-survey-feedback-topic" role="select" className="form-control mb-3" value={ feedbackTopic } onChange={ (e) => handleSelectChange(e) }>
                                    { dropdownOptions.map((option) => {
                                        return (
                                            <option value={ option.value } key={ option.value }>{ option.topic }</option>
                                        )
                                    }) }
                                </FormSelect>
                                <label>{ lng(messages.chooseSubject) }</label>
                            </FormGroup>
                            <span className="invalid-feedback" id="invalid-dropdown-topic" />
                            <FormGroup className="form-floating">
                                <textarea id="exit-survey-feedback-textarea" name={ 'messageInput' } className="form-control mb-3 pt-5 pt-md-4" rows="7" value={ messageInput } placeholder={ lng(messages.textareaPlaceholder) }
                                          onChange={ (event) => handleInputChange(event) } />
                                <span className="invalid-feedback" id="invalid-open-feedback" />
                                <label className="long-floating-label">{ lng(messages.textareaPlaceholder) }</label>
                            </FormGroup>
                        </form>
                        <AlertDialog message={ <div> { lng(messages.feedbackInfoParagraph1) } <a title={ customerServiceLink } rel="noopener noreferrer" href={ customerServiceLink } target="_blank">
                            { lng(messages.feedbackInfoParagraph2) } </a></div> } type="primary"></AlertDialog>
                        { showCustomerSurvey ? (
                            <FormGroup id="error" className="form-group customer-survey" onClick={ () => window.open(process.env.REACT_APP_CUSTOMER_SURVEY_URL, "_blank") }>
                                <Row>
                                    <Col xs={ 11 }>
                                        <h3> { lng(messages.customerSurveyParagraph1) } </h3>
                                        <p>{ lng(messages.customerSurveyParagraph2) }</p>
                                    </Col>
                                    <Col xs={ 1 }>
                                        <div className="survey-pointer"><FontAwesomeIcon icon={ far.faHandPointer } /></div>
                                    </Col>
                                </Row>
                            </FormGroup>
                        ) : <></> }
                        <Button variant="primary" type="submit" form="exit-survey-form">
                            { changeButtonText ? lng(messages.actionSendAndLogout) : lng(messages.actionSend) }
                        </Button>
                    </Modal.Body>
                </Modal>
            ) : ('') }
        </>
    )
};

FeedbackDialog.propTypes = {
    'history': PropTypes.any,
    'show': PropTypes.bool,
    'canShow': PropTypes.bool
};

export default FeedbackDialog;

