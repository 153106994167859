import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { defineMessages } from "react-intl";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { validate } from "../../../../services/helpers";
import AlertDialog from "../../../../components/AlertDialog";

// This view's strings for translation
const messages = defineMessages({
    backToInvoice: {
        id: "Palaa laskun tietoihin",
        defaultMessage: "Palaa laskun tietoihin"
    },
    title: {
        id: "Paloturvallisuuden varmistaminen",
        defaultMessage: "Paloturvallisuuden varmistaminen"
    },
    paragraph1: {
        id: "Vahvistan, ettei käyttöpaikallani ole ennen energianjakelun jälleenkytkentää päällä olevia sähkölaitteita, jotka voivat aiheuttaa tulipalon vaaran. Tällaisia laitteita ovat esim. liesi, kiuas, uuni ja voimakkaat remonttivalaisimet. Ilmoitathan asiakaspalveluumme, mikäli mittarilaitteelle ei ole esteetöntä pääsyä.",
        defaultMessage: "Vahvistan, ettei käyttöpaikallani ole ennen energianjakelun jälleenkytkentää päällä olevia sähkölaitteita, jotka voivat aiheuttaa tulipalon vaaran. Tällaisia laitteita ovat esim. liesi, kiuas, uuni ja voimakkaat remonttivalaisimet. Ilmoitathan asiakaspalveluumme, mikäli mittarilaitteelle ei ole esteetöntä pääsyä."
    },
    phonetext: {
        id: "Syötäthän puhelinnumerosi tähän, jotta sähköyhtiösi voi tarvittaessa olla sinuun yhteydessä jälleenkytkentään liittyen.",
        defaultMessage: "Syötäthän puhelinnumerosi tähän, jotta sähköyhtiösi voi tarvittaessa olla sinuun yhteydessä jälleenkytkentään liittyen."
    },
    phone: {
        id: "Puhelinnumero",
        defaultMessage: "Puhelinnumero"
    },
    actionSave: {
        id: "Tallenna",
        defaultMessage: "Tallenna"
    }
});

export { messages };


const FiresafetyModal = (props) => {
    const lng = props.intl.formatMessage;
    const [telephoneNumber, setTelephoneNumber] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        message: '',
        type: ''
    });

    useEffect(() => {
        if (props.alert.show) {
            setAlert({
                message: props.alert.message,
                type: props.alert.type
            });
            setShowAlert(true);
        } else {
            clearAlerts();
        }

    }, [props.alert]);

    /**
     * Handle changes in input field and remove errors.
     *
     * @param e
     */
    function handleInputUpdate(e) {
        // remove validation errors
        if (e.target.checkValidity()) {
            e.target.classList.remove("is-invalid");
        }

        // updates telephone number
        setTelephoneNumber(e.target.value);
    }

    /**
     * Hide alertDialog and clear errors
     */
    function clearAlerts() {
        setShowAlert(false);
        setAlert({
            message: '',
            type: ''
        });
    }

    /**
     * Validate form data, and call handleSubmit with telephone number provided by the user.
     *
     * @param e
     */
    function handleSubmit(e) {
        e.preventDefault();

        if (validate(e.currentTarget)) {
            // clear alerts when data is valid
            clearAlerts();
            // submit firesafety information
            props.handleSubmit(telephoneNumber);
        }
    }

    /**
     *  Clear errors and hide the dialog
     */
    function handleHide() {
        clearAlerts();
        props.hide();
    }

    return (
        <Modal show={props.show} onHide={handleHide}>
            <Modal.Header closeButton>
                <Button className="modal-back" onClick={handleHide}>
                    <FontAwesomeIcon icon={far.faArrowLeft} /> {lng(messages.backToInvoice)}
                </Button>
                <Modal.Title>{lng(messages.title)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p>{lng(messages.paragraph1)}</p>
                        <p>{lng(messages.phonetext)}</p>
                        {showAlert && (
                            <AlertDialog
                                message={alert.message}
                                type={alert.type}
                            />
                        )}
                        <Form id="firesafety-form" noValidate aria-label={lng(messages.title)} onSubmit={handleSubmit}>
                            <Form.Floating className="mb-3">
                                <Form.Control id="phone" type="text" placeholder={lng(messages.phone)} onChange={(e) => handleInputUpdate(e)} required />
                                <Form.Label htmlFor="phone">{lng(messages.phone)}</Form.Label>
                                <Form.Control.Feedback type="invalid" />
                            </Form.Floating>
                            <Button form="firesafety-form" variant="primary" type="submit">
                                {lng(messages.actionSave)}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

FiresafetyModal.propTypes = {
    'intl': PropTypes.object.isRequired,
    'show': PropTypes.bool,
    'hide': PropTypes.func,
    'handleSubmit': PropTypes.func,
    'alert': PropTypes.object
};

export default FiresafetyModal;