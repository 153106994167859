import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, FormGroup, Button, Card } from 'react-bootstrap';
import { injectIntl, defineMessages } from "react-intl";
import axios from 'axios';
import PropTypes from 'prop-types';

// Services
import { userRequestHeaders, userLogout } from '../../../services/user';
import { toggleLoading } from '../../../services/helpers';
import { secondsToMinutes } from 'date-fns';
import AlertDialog from "../../../components/AlertDialog";


// strings for translation in this view
const messages = defineMessages({
    phoneNumber: {
        id: "Puhelinnumero",
        defaultMessage: "Puhelinnumero"
    },
    titleSavingFailed: {
        id: 'Tallennus epäonnistui',
        defaultMessage: 'Tallennus epäonnistui'
    },
    somethingWentWrong: {
        id: "Jotain meni vikaan, ole hyvä ja yritä uudelleen.",
        defaultMessage: "Jotain meni vikaan, ole hyvä ja yritä uudelleen."
    },
    error: {
        id: "Tapahtui virhe",
        defaultMessage: "Tapahtui virhe"
    },
    confirmOrder: {
        id: "Vahvista tilaus",
        defaultMessage: "Vahvista tilaus"
    },
    confirmInfoSummary: {
        id: "Olet tilaamassa Weblaskun seuraavaan puhelinnumeroon.",
        defaultMessage: "Olet tilaamassa Weblaskun seuraavaan puhelinnumeroon."
    },
    deliveryMethod: {
        id: "Toimitustapa",
        defaultMessage: "Toimitustapa"
    },
    editPhoneNumber: {
        id: "Muokkaa puhelinnumeroa",
        defaultMessage: "Muokkaa puhelinnumeroa"
    },
    confirmInfo: {
        id: "Vahvista tilaus syöttämällä saamasi vahvistuskoodi. Viestin lähettäjä on ropocapital.",
        defaultMessage: "Vahvista tilaus syöttämällä saamasi vahvistuskoodi. Viestin lähettäjä on ropocapital."
    },
    confirmationCode: {
        id: "Vahvistuskoodi",
        defaultMessage: "Vahvistuskoodi"
    },
    confirm: {
        id: "Vahvista",
        defaultMessage: "Vahvista"
    },
    resendConfirmationCode: {
        id: "Lähetä vahvistuskoodi uudelleen",
        defaultMessage: "Lähetä vahvistuskoodi uudelleen"
    },
    confirmationCodeSentAgain: {
        id: "Olemme lähettäneet vahvistuskoodin sinulle uudelleen",
        defaultMessage: "Olemme lähettäneet vahvistuskoodin sinulle uudelleen"
    },
    pleaseCheckCode: {
        id: "Ole hyvä ja tarkista vahvistuskoodi",
        defaultMessage: "Ole hyvä ja tarkista vahvistuskoodi"
    },
    sms: {
        id: "SMS",
        defaultMessage: "SMS"
    },
    verifying: {
        id: "Vahvistetaan",
        defaultMessage: "Vahvistetaan"
    },
    authError: {
        id: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen."
    }
});
export { messages };


const CodeVerification = (props) => {
    const formRef = useRef();
    // translations
    const lng = props.intl.formatMessage;
    const [show, setShow] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
    const [counter, setCounter] = useState(30);
    const [timerValue, setTimerValue] = useState("")
    const [deliveryMethod, setDeliveryMethod] = useState("");
    const [deliveryMethodValue, setDeliveryMethodValue] = useState("");
    const [dpid, setDpid] = useState("");
    const [verificationUuid, setVerificationUuid] = useState("");

    const originalTimeOut = 30000; // buttons disabled for 30s initally
    const originalCounter = 30; // inital counter value
    const [timeOut, setTimeOut] = useState(originalTimeOut);
    const [numberOfClicks, setNumberOfClicks] = useState(0);

    // Alert dialog
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    useEffect(() => {
        if (props.deliveryDetails) {
            setDeliveryMethod(props.deliveryDetails.deliveryMethod);
            setDeliveryMethodValue(props.deliveryDetails.deliveryMethodValue);
            setVerificationUuid(props.deliveryDetails.verificationUuid);
        }

    }, [props.deliveryDetails]);


    useEffect(() => {
        if (props.showCodeVerification) {
            setShow(true);
            setDpid(props.job.dpid);
        }
        else {
            setShow(false);
        }

    }, [props.showCodeVerification]);


    // handle editing phone number
    function editPhoneNumber() {
        // clear input field and change the step
        setVerificationCode("");
        setShowAlertDialog(false);
        props.changeStep();
    }


    // handle verification code input
    function handleVerificationCode(e) {
        // find input for verification code
        const codeInput = e.target.parentNode.querySelector("#verification-code-input");

        if (e.target.value) {
            // if a value exists in the input field
            e.target.classList.add("not-empty");
            setDisabled(false);
        }
        else {
            // empty input field
            e.target.classList.remove("not-empty");
            setDisabled(true);
            e.target.classList.remove("error");
            // clear errors and info messages
            codeInput.classList.remove('is-invalid');
            setShowAlertDialog(false);
        }
        setVerificationCode(e.target.value);
    }


    // format text of remaining time on button timer
    function formatTimerText(value) {
        let minutes = secondsToMinutes(value);
        let seconds = value - minutes * 60;

        if (value === 0) {
             // clear timer from button
            setTimerValue("");
        }
        else if (value > 59) {
            // show seconds and minutes in the counter
            if (seconds > 9) {
                setTimerValue("(0" + minutes + ":" + seconds + ")");
            }
            else {
                setTimerValue("(0" + minutes + ":0" + seconds + ")"); // add 0 in front of the seconds
            }
        }
        else {
            // only show seconds in the counter
            if (seconds > 9) {
                setTimerValue("(00:" + seconds + ")");
            }
            else {
                setTimerValue("(00:0" + seconds + ")"); // add 0 in front of the seconds
            }
        }
    }

    // handle resending the verification code
    function handleResend(e) {
        // make the request
        resendVerificationCode();

        // set inital values for counter and time out
        let currentCounter = counter;
        let newCounterValue = counter;
        let timeoutValue = timeOut; // start from 30s, max. 150s

        // if time is less than 2m 30s, add 15 seconds to counter, otherwise keep same values
        if (newCounterValue < 150) {
            currentCounter = originalCounter + numberOfClicks * 15; // add 15s
            newCounterValue = originalCounter + numberOfClicks * 15; // add 15s
            timeoutValue = originalTimeOut + numberOfClicks * 15000; // 15s timeout in milliseconds
        }

        // disable button and set timer text
        setResendButtonDisabled(true);
        formatTimerText(currentCounter);

        // show message to user that code has been resent
        setShowAlertDialog(true);
        setAlertMessage(lng(messages.confirmationCodeSentAgain));
        setAlertType('success');

        // show remaining seconds until button is enabled again
        const checkTimer = window.setInterval(function () {
            currentCounter = currentCounter - 1;
            // format time to be minutes and seconds, if the time value is more than 1 minute
            formatTimerText(currentCounter);

            if (currentCounter === 0) {
                clearInterval(checkTimer);
            }
        }, 1000);

        // diable button for the length the timeout
        const disabledTimeout = window.setTimeout(function () {
            setResendButtonDisabled(false);
            if (newCounterValue < 150) {
                // if under 2m 30s mark, add time to counter and time out
                setCounter(newCounterValue);
                setTimeOut(timeoutValue);
            }
            else {
                // disable button for max. 150s
                setCounter(150);
                setTimeOut(150000);
            }

            clearTimeout(disabledTimeout);
        }, timeoutValue); // min. 30s, max. 150s

        // keep track of clicks
        setNumberOfClicks(numberOfClicks + 1);
    }


    // get a new verification code
    function resendVerificationCode() {
        // send phone number again to get a new verification code
        const headers = userRequestHeaders();
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/online/subscribe',
            headers: headers,
            data: JSON.stringify({
                type: deliveryMethod,
                value: deliveryMethodValue,
                id: dpid
            })
        })
        .then(function (response) {
            // A successful request, hide loading animation
            toggleLoading(false);
        })
        .catch(function (error) {
            // loading animation off
            toggleLoading(false);

            // authentication failed
            if (error.response?.status === 401) {
                // log out from current session and redirect back to subscription login for authentication
                userLogout();
                // show error message to user
                const alert = { message: lng(messages.authError), type: 'danger' };
                props.history.replace({
                    pathname: '/subs/' + dpid,
                    alert
                });
            } else {
                // request failed, show general info in alertDialog
                setShowAlertDialog(true);
                setAlertMessage(lng(messages.somethingWentWrong));
                setAlertType('danger');
            }
        });
    }


    // submit verification code and subscribe
    function handleSubmit(e) {
        e.preventDefault();

        const codeInput = e.target.parentNode.querySelector("#verification-code-input");

        // clear errors and info messages
        codeInput.classList.remove('is-invalid');
        setShowAlertDialog(false);
        toggleLoading(true, lng(messages.verifying) + "...");

        // validate verification code
        const headers = userRequestHeaders();
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/online/validatesubscribe',
            headers: headers,
            data: JSON.stringify({
                type: deliveryMethod,
                value: deliveryMethodValue,
                id: dpid,
                verification_code: verificationCode,
                verification_uuid: verificationUuid
            })
        })
        .then(function (response) {
            // on success hide step 2 and show successful subscription summary
            props.showSuccess();
        })
        .catch(function (error) {
            toggleLoading(false);
            // authentication failed
            if (error.response?.status === 401) {
                // log out from current session and redirect back to subscription login for authentication
                userLogout();
                // show error message to user
                const alert = { message: lng(messages.authError), type: 'danger' };
                props.history.replace({
                    pathname: '/subs/' + dpid,
                    alert
                });
            } else if (typeof error.response !== 'undefined') {
                if (error.response.status === 400 && error.response.data.Message === "Invalid verification code") {
                    // verification code is not a match, show error in AlertDialog
                    setShowAlertDialog(true);
                    setAlertMessage(lng(messages.pleaseCheckCode));
                    setAlertType('danger');
                    codeInput.classList.add('is-invalid');

                } else {
                    // request failed, general info
                    setShowAlertDialog(true);
                    setAlertMessage(lng(messages.somethingWentWrong));
                    setAlertType('danger');
                }
            } else {
                // request failed, general info
                setShowAlertDialog(true);
                setAlertMessage(lng(messages.somethingWentWrong));
                setAlertType('danger');
            }
        });
    }


    return (
        <>
            {show && (
                <div id="subscription-step2">
                    <Row>
                        <Card className='card-borderless'>
                            <Card.Body>
                                <h2>{lng(messages.confirmOrder)}</h2>
                                <p>{lng(messages.confirmInfoSummary)}</p>
                                <ul id="delivery-details">
                                    <li><p>{lng(messages.deliveryMethod)}: {deliveryMethod === 'sms' ? lng(messages.sms) : deliveryMethod}</p></li>
                                    <li><p>{lng(messages.phoneNumber)}: {deliveryMethodValue}</p></li>
                                </ul>
                                <br />
                                <Button id="edit-phone-number-button" type="button" onClick={editPhoneNumber} className="btn btn-secondary edit">
                                    {lng(messages.editPhoneNumber)}
                                </Button>
                                <form id="subscription-form-step-2" onSubmit={handleSubmit} ref={formRef}>
                                    <FormGroup className="form-group" id="verification-code-input-info">
                                        <p>{lng(messages.confirmInfo)}</p>
                                    </FormGroup>
                                    { showAlertDialog && (
                                        <AlertDialog id="verification-alert" message={alertMessage} type={alertType} />
                                    )}
                                    <FormGroup id="verification-code" className='form-floating'>
                                        <input className="form-control" id="verification-code-input" type="text" placeholder={lng(messages.confirmationCode)} value={verificationCode} onChange={handleVerificationCode} />
                                        <label htmlFor='verification-code-input'>{lng(messages.confirmationCode)}</label>
                                    </FormGroup>
                                    <Row id="code-verification-buttons" className='code-verification-buttons-col'>
                                        <Col md={7} id="submit-verification-button-col" className='verification-button-col d-grid'>
                                            <Button id="submit-verification-button" className="verify" type="submit" disabled={disabled}>
                                                {lng(messages.confirm)}
                                            </Button>
                                        </Col>
                                        <Col md={5} id="resend-verification-button-col" className='verification-button-col d-grid'>
                                            <Button id="resend-verification-button" className="resend btn-secondary" type="button" onClick={() => handleResend(formRef)} disabled={resendButtonDisabled}>
                                                {lng(messages.resendConfirmationCode)} {timerValue}
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                            </Card.Body>
                        </Card>
                    </Row>
                </div>
            )}
        </>
    );
};


CodeVerification.propTypes = {
    'intl': PropTypes.object.isRequired,
    'deliveryDetails': PropTypes.object,
    'showCodeVerification': PropTypes.bool,
    'job': PropTypes.object,
    'showSuccess': PropTypes.func,
    'changeStep': PropTypes.func,
    'history': PropTypes.any,
};


export default injectIntl(CodeVerification);