import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { defineMessages } from "react-intl";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { validate, listInvalidFields } from "../../../../services/helpers";
import AlertDialog from "../../../../components/AlertDialog";

const messages = defineMessages({
    backToInvoice: {
        id: "Palaa laskun tietoihin",
        defaultMessage: "Palaa laskun tietoihin"
    },
    title: {
        id: "Valtuutus",
        defaultMessage: "Valtuutus"
    },
    paragraph1: {
        id: "Tässä voit valtuuttaa toisen henkilön hoitamaan laskuusi liittyviä asioita. Antamasi valtuutus näkyy laskusi tiedoilla asiakaspalvelussamme, jonka jälkeen valtuutettu henkilö voi asioida puolestasi. Huomioithan, että valtuutus on laskukohtainen. Jos haluat tehdä jatkuvan valtuutuksen, ota yhteyttä asiakaspalveluumme MyRopon chatin kautta.",
        defaultMessage: "Tässä voit valtuuttaa toisen henkilön hoitamaan laskuusi liittyviä asioita. Antamasi valtuutus näkyy laskusi tiedoilla asiakaspalvelussamme, jonka jälkeen valtuutettu henkilö voi asioida puolestasi. Huomioithan, että valtuutus on laskukohtainen. Jos haluat tehdä jatkuvan valtuutuksen, ota yhteyttä asiakaspalveluumme MyRopon chatin kautta."
    },
    name: {
        id: "Valtuutetun nimi",
        defaultMessage: "Valtuutetun nimi"
    },
    email: {
        id: "Valtuutetun sähköposti",
        defaultMessage: "Valtuutetun sähköposti"
    },
    phone: {
        id: "Valtuutetun puhelin",
        defaultMessage: "Valtuutetun puhelin"
    },
    actionSave: {
        id: "Tallenna",
        defaultMessage: "Tallenna"
    },
    incorrectEmail: {
        id: "Virheellinen sähköpostiosoite",
        defaultMessage: "Virheellinen sähköpostiosoite"
    }
});

export { messages };


const MandateModal = (props) => {
    const lng = props.intl.formatMessage;
    const [authorizedPersonInfo, setAuthorizedPersonInfo] = useState({
        name: '',
        phone: '',
        email: ''
    });
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        message: '',
        type: ''
    });

    useEffect(() => {
        if (props.alert.show) {
            setAlert({
                message: props.alert.message,
                type: props.alert.type
            });
            setShowAlert(true);
        } else {
            clearAlerts();
        }

    }, [props.alert]);

    /**
     * Handle changes in input field and remove errors. 
     * 
     * @param e 
     */
    function handleInputUpdate(e) {
        // remove validation errors
        if (e.target.checkValidity()) {
            e.target.classList.remove("is-invalid");
        }

        // update authorized person's information
        setAuthorizedPersonInfo({
            ...authorizedPersonInfo,
            [e.target.id]: e.target.value
        });
    }

    /**
     * Hide alertDialog and clear errors
     */
    function clearAlerts() {
        setShowAlert(false);
        setAlert({
            message: '',
            type: ''
        });
    }

    /**
     * Validate form data, and call handleSubmit with authorisation information provided by the user.
     * 
     * @param e 
     */
    function handleSubmit(e) {
        e.preventDefault();

        if (validate(e.currentTarget)) {
            // clear alerts when data is valid
            clearAlerts();
            // submit authorized person's information
            props.handleSubmit(authorizedPersonInfo);
        } else {
            clearAlerts();

            // list all input fields that have failed form validation
            const errors = listInvalidFields(e.currentTarget);

            // only show alert for invalid email in alertDialog
            if (errors.includes('email') && authorizedPersonInfo.email !== '') {
                setAlert({
                    message: lng(messages.incorrectEmail),
                    type: 'danger'
                });
                setShowAlert(true);
            }
        }
    }

    /**
     *  Clear errors and hide the dialog
     */
    function handleHide() {
        clearAlerts();
        props.hide();
    }

    return (
        <Modal show={props.show} onHide={handleHide}>
            <Modal.Header closeButton>
                <Button className="modal-back" onClick={handleHide}>
                    <FontAwesomeIcon icon={far.faArrowLeft} /> {lng(messages.backToInvoice)}
                </Button>
                <Modal.Title>{lng(messages.title)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p>{lng(messages.paragraph1)}</p>
                        {showAlert && (
                            <AlertDialog
                                message={alert.message}
                                type={alert.type}
                            />
                        )}
                        <Form id="mandate-form" noValidate aria-label={lng(messages.title)} onSubmit={handleSubmit}>
                            <Form.Floating className="mb-3">
                                <Form.Control id="name" type="text" placeholder={lng(messages.name)} onChange={(e) => handleInputUpdate(e)} required />
                                <Form.Label htmlFor="name">{lng(messages.name)}</Form.Label>
                                <Form.Control.Feedback type="invalid" />
                            </Form.Floating>
                            <Form.Floating className="mb-3">
                                <Form.Control id="phone" type="tel" placeholder={lng(messages.phone)} onChange={(e) => handleInputUpdate(e)} required />
                                <Form.Label htmlFor="phone">{lng(messages.phone)}</Form.Label>
                                <Form.Control.Feedback type="invalid" />
                            </Form.Floating>
                            <Form.Floating className="mb-3">
                                <Form.Control id="email" type="email" placeholder={lng(messages.email)} onChange={(e) => handleInputUpdate(e)} required />
                                <Form.Label htmlFor="email">{lng(messages.email)}</Form.Label>
                                <Form.Control.Feedback type="invalid" />
                            </Form.Floating>
                            <Button form="mandate-form" variant="primary" type="submit">
                                {lng(messages.actionSave)}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

MandateModal.propTypes = {
    'intl': PropTypes.object.isRequired,
    'show': PropTypes.bool,
    'hide': PropTypes.func,
    'handleSubmit': PropTypes.func,
    'alert': PropTypes.object
};

export default MandateModal;