import axios from "axios";
import { userRequestHeaders } from "../user/index";

/**
 * This is the convenient place for all things that involves Jobs.
 * So the Job energy type enumerations are placed here to respect coherence.
 */
export const JOB_ENERGYTYPE = {
	ELECTRICITY: 'electricity',
	WATER: 'water',
	HEATING: 'heating',
	GAS: 'gas'
};

/**
 * Saves the given code to sessionStorage
 *
 * @param code
 */
export function jobSetCode(code) {
	sessionStorage.setItem('code', code);
}

/**
 * Returns the job's code from sessionStorage
 *
 * @returns {string}
 */
export function jobGetCode() {
	return sessionStorage.getItem('code');
}

/**
 * Removes the job's code from sessionStorage
 */
export function jobRemoveCode() {
	sessionStorage.removeItem('code');
}

/**
 * Saves the given job's information to sessionStorage
 */
export function jobSetJob(job) {
	sessionStorage.setItem('job', JSON.stringify(job));

	// Save dpid for later usage
	if (typeof job.dpid !== "undefined") {
		jobSetCode(job.dpid);
	}
}

export function jobSetJobKeyValue(key, value) {
	const job = jobGetJob(); 
	job[key] = value;
	jobSetJob(job);
	return job;
}

/**
 * Returns user's job from sessionStorage, if one is set.
 * By default return null 
 *
 * @returns NULL / Object (job)
 */
export function jobGetJob() {
	let job = null;

	if (sessionStorage.getItem('job') !== null) {
		job = JSON.parse(sessionStorage.getItem('job'));
	}

	return job;
}

/**
 * Remove job's information from sessionStorage
 */
export function jobRemoveJob() {
	sessionStorage.removeItem('job');
}

/**
 * Save govid jobs to sessionStorage
 * @param {*} govidJobs 
 */
export function jobSetGovidJob(govidJobs) {
	sessionStorage.setItem('govidjobs', JSON.stringify(govidJobs));
}

/**
 * Get saved govid jobs from sessionStorage
 * @returns govid jobs
 */
export function jobGetGovidJob() {
	return JSON.parse(sessionStorage.getItem('govidjobs'));
}

/**
 * Fetch /rest/online/jobsearch/{dpid}
 *
 * Or invoicesearch with queryparameters
 *
 * /rest/online/jobsearch/dpid?querytype=invoice&reference=foo&billnum=bar&postcode=123
 *
 * Saves response to storage.
 * Dpid is also saved to storage.
 * 
 * If parameter is missing or dpid is not in storage, returns 400
 * On error returns Promise.reject(), with possible errormessage.
 *
 * @param query -
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export function jobFetchJob(query) {
	let _query = "";

	// Add the query parameter if provided
	// Otherwise we won't expose the dpid for users who enter with a direct link
	if (typeof query !== "undefined" && query) {
		_query = "/" + query;
	}
	// Remove old code 
	jobRemoveCode();
	// Remove details of the previous job 
	jobRemoveJob();
	// fetch headers that can contain authorization information
	const headers = userRequestHeaders();

	return axios({
		method: "GET",
		url: process.env.REACT_APP_API_URL + "/online/jobsearch" + _query,
		headers: headers
	})
	.then(function (res) {
		if (typeof res.data === 'undefined') {
			return Promise.reject();
		} else {
			// Save job's information to sessionStorage
			jobSetJob(res.data.job[0]);
			// Return Promise on success
			return Promise.resolve(res.data.job[0]);
		}
	})
	.catch(function (error) {
		return Promise.reject(error);
	});
}
