import React from 'react';
import { createRoot } from 'react-dom/client';

// Sentry
import * as Sentry from "@sentry/react";

// CSS
import './css/bootstrap.5.3.2.min.css';
import './css/style.css';
import './css/datepicker.css';

// JS
import Main from './main.js';

// Ikonipaketti
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faHome,
    faSpinner,
    faFileInvoice,
    faCheckCircle,
    faTimesCircle,
    faChevronDown,
    faEnvelope,
    faPowerOff,
    faSearch,
    faHandHoldingUsd,
    faEllipsisV,
    faEuroSign,
    faPencilAlt,
    faCalendarCheck,
    faCalendarAlt,
    faCalendar,
    faDownload,
    faBars,
    faInfoCircle,
    faFrown,
    faFileSignature,
    faExclamationCircle,
    faArrowLeft,
    faClockRotateLeft,
    faChevronRight
} from '@fortawesome/pro-regular-svg-icons';

library.add(faHome, faFileInvoice, faSpinner, faCheckCircle, faTimesCircle, faChevronDown, faEnvelope, faPowerOff, faSearch, faHandHoldingUsd, faEllipsisV, faEuroSign, faPencilAlt, faCalendarCheck, faCalendarAlt, faCalendar, faDownload, faBars, faInfoCircle, faFrown, faFileSignature, faExclamationCircle, faArrowLeft, faClockRotateLeft, faChevronRight);

if (typeof process.env.REACT_APP_SENTRY_DNS !== 'undefined') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        integrations: [],
        environment: typeof process.env.REACT_APP_ENVIRONMENT_TAG !== 'undefined' ? process.env.REACT_APP_ENVIRONMENT_TAG : 'development',
        tracesSampleRate: 1.0,
        allowUrls: [process.env.REACT_APP_URL],
        release: typeof process.env.REACT_APP_RELEASE_NUMBER !== 'undefined' ? process.env.REACT_APP_RELEASE_NUMBER : '0'
    });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Main />);