import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
     * Convert URLs in a string to HTML links.
     *
     * @param {string} text
     * @returns {string}
     */
function convertUrlsToLinks(text) {
    if (typeof text !== 'string') {
        text = String(text);
    }
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    return text.replace(urlPattern, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
}

const AlertDialog = (props) => {

    const [message, setMessage] = useState('');
    const [type, setType] = useState('light');
    const [showAsIs, setShowAsIs] = useState(false);

    useEffect(() => {
        
        if (typeof props.message === 'string') {
            setMessage(convertUrlsToLinks(props.message));
        } else if (typeof props.message === 'object') {
            // objects can be shown as they are passed
            setShowAsIs(true);
            setMessage(props.message);
        }

        if (typeof props.type !== 'undefined') {
            setType(props.type);
        }
    }, [props.message, props.type]);
    
    return (
        <div className={"alert " + type} role="alert" dangerouslySetInnerHTML={!showAsIs ? { __html: message } : null}>
            {showAsIs ? message : null}
        </div>
    );
};

AlertDialog.propTypes = {
    'type': PropTypes.string,
    'message': PropTypes.any,
};

export default AlertDialog;