import React, { useState } from "react";
import { defineMessages } from "react-intl";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { userGetCustomerType, userGetCompany, userGetBusinessId, userGetName, CUSTOMERTYPE } from "../../services/user";
import LocaleSelector from "./../LocaleSelector";
import FeedbackDialog from "../FeedbackDialog";

export const messages = defineMessages({
    actionClose: {
        id: "Sulje",
        defaultMessage: "Sulje"
    },
    language: {
        id: "Kieli",
        defaultMessage: "Kieli"
    },
    logout: {
        id: "Kirjaudu ulos",
        defaultMessage: "Kirjaudu ulos"
    },
    login: {
        id: "Kirjaudu MyRopo-palveluun",
        defaultMessage: "Kirjaudu MyRopo-palveluun"
    }
});

const ProfileMenu = (props) => {
    // translations
    const lng = props.intl.formatMessage;
    const [showLanguageSubMenu, setShowLanguageSubMenu] = useState(false);
    const [toggleFeedbackDialog, setToggleFeedbackDialog] = useState(false);

    let feedBackToggle = false;

    // Is customer feedback on or off
    if (typeof process.env.REACT_APP_FEEDBACK_TOGGLE !== 'undefined' && process.env.REACT_APP_FEEDBACK_TOGGLE.toString() === '1') {
        feedBackToggle = true;
    }

    /**
     * Toggle visibility of language selection submenu.
     */
    function toggleShowLanguageMenu() {
        setShowLanguageSubMenu(!showLanguageSubMenu);
    }

    /**
     * Close active menus when profilemenu is closed.
     */
    function hideProfileMenu() {
        setShowLanguageSubMenu(false);
        props.hide();
    }

    /**
     * Redirect user to login page.
     */
    function handleLogin() {
        props.hide();
        props.history.push('/user/logout');
    }

    /**
     * Log out user and close and hide chat
     */
    function confirmLogout() {
        props.history.push("/logout");
        // Close and hide chat when user logs out
        if (window.LC_API) {
            window.LC_API.close_chat();
            window.LC_API.hide_chat_window();
        }
    }

    /**
     * Open feedback dialog when logging out.
     * If feedback dialog is not enabled call confirmLogout function and log out
     */
    function handleLogOut() {
        if (feedBackToggle) {
            toggleFeedbackDialog ? setToggleFeedbackDialog(false) : setToggleFeedbackDialog(true);
        } else {
            confirmLogout();
        }
    }

    return (
        <>
            {props.show && (
                <div className="profilemenu-background">
                    <Container>
                        <Row className="pe-2">
                            <Col xs={12} className="profilemenu-header mt-2">
                                <span className="option-desc pe-3">
                                    {props.userAuthorized && (
                                        <p className="fw-bold m-0">
                                            {userGetCustomerType() === CUSTOMERTYPE.COMPANY ? (
                                                userGetCompany()
                                            ) : (
                                                userGetName()
                                            )}
                                        </p>
                                    )}
                                </span>
                                <button aria-label={lng(messages.actionClose)} className="profile" onClick={() => hideProfileMenu()} title={userGetCustomerType() === CUSTOMERTYPE.COMPANY ? userGetBusinessId() : ''}>
                                    <span className="option-icon"><FontAwesomeIcon icon={far.faChevronUp} /></span>
                                </button>
                            </Col>
                        </Row>
                        <Row className="profilemenu-contents pe-2">
                            <Col xs={12}>
                                <button aria-label={lng(messages.language)} className="profilemenu-option" onClick={() => toggleShowLanguageMenu()} title={lng(messages.language)}>
                                    <span className="option-desc">{lng(messages.language)}</span>
                                    <span className="option-icon">{showLanguageSubMenu ? <FontAwesomeIcon icon={far.faChevronUp} /> : <FontAwesomeIcon icon={far.faGlobe} />}</span>
                                </button>
                            </Col>
                            {showLanguageSubMenu ? (
                                <LocaleSelector
                                    show={showLanguageSubMenu}
                                    hide={toggleShowLanguageMenu}
                                    colClassnames={"language submenu"}
                                    optionClassnames={"profilemenu-contents submenu"}
                                    {...props} />
                            ) : (
                                <Col xs={12}>
                                    {props.userAuthorized ? (
                                        <button aria-label={lng(messages.logout)} className="profilemenu-option" onClick={() => handleLogOut()} title={lng(messages.logout)}>
                                            <span className="option-desc">{lng(messages.logout)}</span>
                                            <span className="option-icon"><FontAwesomeIcon icon={far.faRightFromBracket} /></span>
                                        </button>
                                    ) : (
                                        <button aria-label={lng(messages.login)} className="profilemenu-option" onClick={() => handleLogin()} title={lng(messages.login)}>
                                            <span className="option-desc">{lng(messages.login)}</span>
                                            <span className="option-icon"><FontAwesomeIcon icon={far.faRightToBracket} /></span>
                                        </button>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </Container>
                    {toggleFeedbackDialog ? (
                        <FeedbackDialog title={lng(messages.logout)} show={toggleFeedbackDialog} handleConfirmDialog={handleLogOut} {...props} />
                    ) : ('')
                    }
                </div>
            )}
        </>
    );
};

ProfileMenu.propTypes = {
    'intl': PropTypes.object.isRequired,
    'show': PropTypes.bool,
    'hide': PropTypes.func,
    'userAuthorized': PropTypes.bool,
    'history': PropTypes.any
};

export default ProfileMenu;